import React, { PureComponent } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    HELPDESK_LINK,
    HEALTH_POLLING_RETRY,
    SYSTEM_MESSAGES_POLLING_INTERVAL,
    DEBUG,
    CURRENT_VERSION,
    VERSION_POLLING_INTERVAL,
    VERSION_POLLING_RETRY,
    RELOAD_TIMER_IN_S,
    CUSTOM_HELPDESK,
} from '../../config';
import { getSystemHealth, getVersion } from '../../api/backendApi';

import Help from '../Icons/Help';

import './SystemFooter.scss';
import { addLogDispatch } from '../../redux/actions/logs';
import {
    replaceText,
    getHelpdeskLanguage,
    getFooterLogo,
} from '../../helper/helper';

/**
 * SystemFooter
 * shows the current system health provided from admin ui
 * shows the current system version and handles reloading on version update
 * provides a helpdesk link
 */

class SystemFooter extends PureComponent {
    _healthInterval = null;
    _versionInterval = null;
    _timerInterval = null;
    _healthTimeout = 0;
    _versionTimeout = 0;

    constructor(props) {
        super(props);
        this.state = {
            health: {
                status: 'green',
                text: '',
            },
            version: CURRENT_VERSION,
            reloadTimer: RELOAD_TIMER_IN_S,
            reloadWaiting: false,
        };
    }

    componentDidMount() {
        this.getHealth();
        this.getVersion();
        this.startPolling();
    }

    startPolling = () => {
        if (DEBUG) addLogDispatch(['polling health api started']);
        this.healthPolling();
        this.versionPolling();
    };

    versionPolling = () => {
        this._versionInterval = setInterval(() => {
            this.getVersion();
        }, VERSION_POLLING_INTERVAL);
    };

    getVersion = async () => {
        const version = await getVersion().catch(() => {
            clearInterval(this._versionInterval);
            this._versionTimeout = setTimeout(
                this.versionPolling,
                VERSION_POLLING_RETRY
            );
        });

        if (version && version !== 'unknown') {
            const newVersions = version.split('.').map(v => parseInt(v));
            const oldVersions = this.state.version
                .split('.')
                .map(v => parseInt(v));

            if (version !== this.state.version) {
                if (
                    newVersions[0] > oldVersions[0] ||
                    newVersions[1] > oldVersions[1] ||
                    newVersions[2] > oldVersions[2]
                ) {
                    this.startReloadTimer();
                }
            }
        }
    };

    startReloadTimer = () => {
        this.setState({ reloadWaiting: true });
        this._timerInterval = setInterval(this.reloadChange, 1000);
    };

    reloadChange = () => {
        this.setState({ reloadTimer: this.state.reloadTimer - 1 });
        if (this.state.reloadTimer <= 0) {
            this.reload();
        }
    };

    stopTimer = () => {
        clearInterval(this._timerInterval);
    };

    reload = () => {
        window.location.pathname = '/new';
    };

    healthPolling = () => {
        this._healthInterval = setInterval(() => {
            this.getHealth();
        }, SYSTEM_MESSAGES_POLLING_INTERVAL);
    };

    getHealth = async () => {
        const health = await getSystemHealth().catch(() => {
            this.setState({
                health: {
                    status: 'RED',
                    text: 'Backend nicht verfügbar.',
                },
            });
            clearInterval(this._healthInterval);
            this._healthTimeout = setTimeout(
                this.healthPolling,
                HEALTH_POLLING_RETRY
            );
        });
        if (health) this.setState({ health });
    };

    componentWillUnmount() {
        clearInterval(this._healthInterval);
        clearInterval(this._versionInterval);
        clearInterval(this._timerInterval);
        clearTimeout(this._healthTimeout);
        clearTimeout(this._versionTimeout);
    }

    render() {
        const classes = clsx('systemFooter', {
            'systemFooter--reload': this.state.reloadWaiting,
        });
        return (
            <footer className={classes}>
                <div>
                    <img
                        className="footer__logo"
                        src={getFooterLogo()}
                        alt=""
                    />
                    <div
                        className="systemFooter__state"
                        title={this.state.health.text}>
                        <span>
                            {replaceText(this.props.texts, 'system.health')}:{' '}
                        </span>
                        <span
                            className={`systemFooter__status systemFooter__status--${this.state.health.status.toLowerCase()}`}></span>
                    </div>
                    <div className="systemFooter__version">
                        {replaceText(this.props.texts, 'system.version')}{' '}
                        <strong>{this.state.version}</strong>
                    </div>
                    <div className="systemFooter__help">
                        <a
                            href={
                                CUSTOM_HELPDESK !== undefined
                                    ? CUSTOM_HELPDESK
                                    : HELPDESK_LINK +
                                    getHelpdeskLanguage(this.props.language)
                            }
                            target="_blank"
                            rel="noopener noreferrer">
                            <Help />
                            {replaceText(this.props.texts, 'system.help')}
                        </a>
                    </div>
                </div>

                <div className="systemFooter__reload alert alert--hint">
                    <strong>
                        {replaceText(this.props.texts, 'system.newVersion')}
                    </strong>
                    <span>
                        {this.props.texts &&
                            replaceText(
                                this.props.texts,
                                'system.restartSystem'
                            ).replace('###', this.state.reloadTimer)}
                    </span>
                    <button
                        className="btn btn--secondary"
                        onClick={this.stopTimer}>
                        {replaceText(this.props.texts, 'system.stopTimer')}
                    </button>
                    <button className="btn btn--primary" onClick={this.reload}>
                        {replaceText(this.props.texts, 'system.restart')}
                    </button>
                </div>
            </footer>
        );
    }
}

// PropTypes for this Component
SystemFooter.propTypes = {
    texts: PropTypes.any,
    language: PropTypes.string,
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        texts: state.texts.texts,
        language: state.texts.language,
    };
};

// Connect Props and Dispatch to Component
export default connect(mapStateToProps)(SystemFooter);
