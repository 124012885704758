import store from '../store/index';
import { ADD_LOG } from './actionTypes';

/**
 * All logs actions
 *
 * Actions always return the type
 * Dispatch always dispatches the action
 */

const addLog = log => {
    return { type: ADD_LOG, log };
};

export const addLogDispatch = logMessage => {
    console.log(...logMessage);

    const log = {
        timestamp: new Date().getTime(),
        logMessage,
    };
    store.dispatch(addLog(log));
};
