import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CHAT_LANGUAGES } from '../../config';
import { connect, useSelector } from 'react-redux';
import { replaceText } from '../../helper/helper';
import Germany from '../Icons/Germany';
import Italy from '../Icons/Italy';
import France from '../Icons/France';
import UK from '../Icons/UK';
import { Tick } from '../Icons/Tick';
import './ChatLanguages.scss';

/**
 * ChatLanguages
 * Languages in the dropdown
 */
function ChatLanguages({ defaultCallerLanguage, isConnected, clickElement }) {
    const texts = useSelector(state => state.texts.texts);
    const language = useSelector(state => state.texts.language);

    const languages = {};

    const [translationLanguage, setTranslationLanguage] = useState('');
    const [sessionIsInitialised, setSessionIsInitialised] = useState();

    useEffect(() => {
        if (isConnected && !sessionIsInitialised) {
            setTranslationLanguage(defaultCallerLanguage);
            setSessionIsInitialised(true);
        }
    }, [defaultCallerLanguage, isConnected, sessionIsInitialised]);

    const getFlag = lang => {
        switch (lang) {
            case 'de':
                return (
                    <React.Fragment>
                        <Germany />
                        <span className="languageSelectText">
                            {replaceText(texts, 'lang.de')}
                        </span>
                    </React.Fragment>
                );
            case 'it':
                return (
                    <React.Fragment>
                        <Italy />
                        <span className="languageSelectText">
                            {replaceText(texts, 'lang.it')}
                        </span>
                    </React.Fragment>
                );
            case 'fr':
                return (
                    <React.Fragment>
                        <France />
                        <span className="languageSelectText">
                            {replaceText(texts, 'lang.fr')}
                        </span>
                    </React.Fragment>
                );
            case 'en':
                return (
                    <React.Fragment>
                        <UK />
                        <span className="languageSelectText">
                            {replaceText(texts, 'lang.en')}
                        </span>
                    </React.Fragment>
                );
            default:
                return 'missing';
        }
    };

    CHAT_LANGUAGES[language].forEach(lang => {
        languages[lang] = getFlag(lang);
    });

    return (
        <div className="chatLanguages">
            {CHAT_LANGUAGES[language].map(language => (
                <div
                    className="contentWrapper"
                    key={language}
                    value={language}
                    onClick={() => {
                        clickElement(language, `lang.${language}`);
                        setTranslationLanguage(language);
                    }}>
                    {texts[`lang.${language}`]}
                    <span>{translationLanguage === language && <Tick />}</span>
                </div>
            ))}
        </div>
    );
}

const mapStateToProps = state => {
    return {
        defaultCallerLanguage: state.texts.callerLanguage,
        isConnected: state.connection.isConnected,
    };
};

ChatLanguages.propTypes = {
    clickElement: PropTypes.func,
    defaultCallerLanguage: PropTypes.string,
    isConnected: PropTypes.bool,
    sessionInitialised: PropTypes.bool,
};

export default connect(mapStateToProps)(ChatLanguages);
