import React from 'react';

export const Cancel = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" data-name="Plus Icon" viewBox="0 0 30 30">
            <circle cx="15" cy="15" r="15" fill="#d25425" data-name="Ellipse 291" />
            <g fill="none" stroke="#fafafa" strokeLinecap="round" strokeWidth="3" data-name="Group 4461">
                <path d="M20.8 9.343 9.484 20.657" data-name="Path 3000" />
                <path d="M20.8 20.657 9.484 9.343" data-name="Path 3001" />
            </g>
        </svg>
    );
};
