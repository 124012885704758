import {
    RESET_FOCUS_WINDOW, ACTIVATE_PREVIEW_DOCK, DEACTIVATE_PREVIEW_DOCK, ACTIVATE_FOCUS_CONTROLS, DEACTIVATE_FOCUS_CONTROLS, SET_FOCUS_CHANGED_BY_CALLER, UNSET_FOCUS_CHANGED_BY_CALLER, SET_FOCUS_WINDOW, SET_INCOMING_FOCUS_FEATURE, SET_MAXIMIZE_CHAT_WINDOW, SET_MINIMIZE_CHAT_WINDOW
} from '../actions/focus';

export const initialState = {
    currentFocusFeature: null,
    previewDockIsActive: false,
    focusControlsAreActive: false,
    focusChangedByCaller: false,
    incomingFocusFeature: null,
    chatWindowIsMaximized: false,
};

type FocusAction = {
    type: string,
    payload: string,
};

const focusReducer = (state = initialState, action: FocusAction) => {
    switch (action.type) {
        case SET_FOCUS_WINDOW:
            return {
                ...state,
                currentFocusFeature: action.payload,
            };
        case RESET_FOCUS_WINDOW:
            return {
                ...state,
                currentFocusFeature: null,
            };
        case SET_INCOMING_FOCUS_FEATURE:
            return {
                ...state,
                incomingFocusFeature: action.payload,
            };
        case ACTIVATE_PREVIEW_DOCK:
            return {
                ...state,
                previewDockIsActive: true,
            };
        case DEACTIVATE_PREVIEW_DOCK:
            return {
                ...state,
                previewDockIsActive: false,
            };
        case ACTIVATE_FOCUS_CONTROLS:
            return {
                ...state,
                focusControlsAreActive: true,
            };
        case DEACTIVATE_FOCUS_CONTROLS:
            return {
                ...state,
                focusControlsAreActive: false,
            };
        case SET_FOCUS_CHANGED_BY_CALLER:
            return {
                ...state,
                focusChangedByCaller: true,
            };
        case UNSET_FOCUS_CHANGED_BY_CALLER:
            return {
                ...state,
                focusChangedByCaller: false,
            };
        case SET_MAXIMIZE_CHAT_WINDOW:
            return {
                ...state,
                chatWindowIsMaximized: true,
            };
        case SET_MINIMIZE_CHAT_WINDOW:
            return {
                ...state,
                chatWindowIsMaximized: false,
            };
        default:
            return state;
    }
};

export default focusReducer;
