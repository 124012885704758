import {
    CHANGE_USAGE_DISCLAIMER_STATE_CALLER,
    CHANGE_USAGE_DISCLAIMER_STATE_CONFERENCE,
    CHANGE_USAGE_DISCLAIMER_STATE_DISPATCHER,
    SET_HD_PHOTO_DISCLAIMER_CLICKED,
    SET_HD_PHOTO_DISCLAIMER_NOT_CLICKED,
    SET_SNAPSHOT_DISCLAIMER_ACCEPTED,
    SET_SNAPSHOT_DISCLAIMER_NOT_ACCEPTED,
} from '../actions/disclaimers';

export type DisclaimerState = null | 'none' | 'waiting' | 'accepted' | 'declined';

export interface DisclaimerStateInterface {
    usageDisclaimerStateDispatcher?: DisclaimerState;
    usageDisclaimerStateCaller?: DisclaimerState;
    usageDisclaimerStateConference?: DisclaimerState;
    snapshotDisclaimerAccepted?: boolean;
    hdPhotoDisclaimerClicked?: boolean;
}

export const initialState: DisclaimerStateInterface = {
    usageDisclaimerStateDispatcher: null,
    usageDisclaimerStateCaller: null,
    usageDisclaimerStateConference: null,
    snapshotDisclaimerAccepted: null,
    hdPhotoDisclaimerClicked: null,
};

type DisclaimerAction = {
    type: string;
    payload: DisclaimerState;
};

const disclaimerReducer = (state = initialState, action: DisclaimerAction) => {
    if (action.type === CHANGE_USAGE_DISCLAIMER_STATE_CONFERENCE) {
        return { ...state, usageDisclaimerStateConference: action.payload };
    }

    if (action.type === CHANGE_USAGE_DISCLAIMER_STATE_DISPATCHER) {
        return { ...state, usageDisclaimerStateDispatcher: action.payload };
    }

    if (action.type === CHANGE_USAGE_DISCLAIMER_STATE_CALLER) {
        return { ...state, usageDisclaimerStateCaller: action.payload };
    }

    if (action.type === SET_SNAPSHOT_DISCLAIMER_ACCEPTED) {
        return { ...state, snapshotDisclaimerAccepted: true };
    }

    if (action.type === SET_SNAPSHOT_DISCLAIMER_NOT_ACCEPTED) {
        return { ...state, snapshotDisclaimerAccepted: false };
    }

    if (action.type === SET_HD_PHOTO_DISCLAIMER_CLICKED) {
        return { ...state, hdPhotoDisclaimerClicked: true };
    }

    if (action.type === SET_HD_PHOTO_DISCLAIMER_NOT_CLICKED) {
        return { ...state, hdPhotoDisclaimerClicked: false };
    }

    return state;
};

export default disclaimerReducer;
