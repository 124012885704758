import { C_INIT, C_LOST, C_WAIT } from '../../config';
import { CONNECTION_ENDED, CONNECTION_ESTABLISHED, CONNECTION_LOST, SET_CONNECTION_STABLE, SET_CONNECTION_UNSTABLE } from '../actions/actionTypes';

export const initialState = {
    isConnected: false,
    status: C_WAIT,
    connectionIsUnstable: false,
};

/**
 * connection reducer
 * see https://redux.js.org/
 * @param {object} state
 * @param {object} action
 */

const connectionReducer = (state = initialState, action) => {
    if (action.type === CONNECTION_LOST) {
        state = { ...state, isConnected: false, status: C_LOST };
    }

    if (action.type === CONNECTION_ESTABLISHED) {
        state = { ...state, isConnected: true, status: C_INIT };
    }

    if (action.type === CONNECTION_ENDED) {
        state = { ...state, isConnected: false, status: C_WAIT };
    }

    if (action.type === SET_CONNECTION_STABLE) {
        state = { ...state, connectionIsUnstable: false };
    }

    if (action.type === SET_CONNECTION_UNSTABLE) {
        state = { ...state, connectionIsUnstable: true };
    }

    return state;
};

export default connectionReducer;
