import React from 'react';

function Attachment() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" data-name="attachment" viewBox="0 0 64 64">
            <path d="m20.49 37.78 20.93-20.93c.59-.59.61-1.54.03-2.12s-1.53-.56-2.12.03L18.4 35.69c-2.57 2.57-2.63 6.7-.14 9.2l.3.3c2.5 2.5 6.62 2.44 9.2-.14l12.38-12.38 8.54-8.54.09-.09c4.55-4.55 4.67-11.84.26-16.26-4.41-4.41-11.7-4.3-16.26.26l-.09.09-8.74 8.74L9.73 31.08c-6.14 6.14-6.28 15.97-.33 21.93l1.03 1.03c5.95 5.95 15.79 5.81 21.93-.33l22.95-22.95c.59-.59.61-1.54.03-2.12s-1.53-.56-2.12.03L30.27 51.62c-4.95 4.95-12.88 5.07-17.68.26l-1.03-1.03c-4.8-4.8-4.68-12.73.26-17.68l14.21-14.21 8.74-8.74.09-.09c3.36-3.36 8.75-3.45 12.01-.19 3.26 3.26 3.17 8.65-.19 12.01l-.09.09-8.54 8.54-12.38 12.38c-1.39 1.39-3.61 1.42-4.95.07l-.3-.3c-1.34-1.34-1.31-3.57.07-4.95Z" />
            <path d="M21.09 58.89c-4.02 0-8-1.5-11.02-4.52l-1.03-1.03c-6.15-6.15-6-16.3.33-22.63L32.41 7.67c4.75-4.75 12.36-4.86 16.97-.26 4.61 4.61 4.49 12.22-.26 16.96l-.09.09L28.1 45.39c-2.77 2.77-7.21 2.83-9.9.14l-.3-.3c-2.69-2.69-2.63-7.13.14-9.9L38.97 14.4c.79-.79 2.06-.8 2.83-.03s.76 2.04-.03 2.83L20.84 38.13c-.58.58-.9 1.34-.92 2.14-.01.8.29 1.55.84 2.1l.3.3c.55.55 1.28.82 2.1.84.81-.01 1.57-.34 2.14-.91l21.02-21.02c1.54-1.54 2.4-3.56 2.43-5.7.03-2.14-.76-4.13-2.24-5.61s-3.39-2.24-5.47-2.24h-.13c-2.14.03-4.17.9-5.7 2.43L12.17 33.5c-4.75 4.75-4.87 12.37-.27 16.98l1.03 1.03c2.23 2.23 5.23 3.45 8.43 3.38 3.21-.05 6.25-1.34 8.55-3.64L52.86 28.3c.79-.79 2.06-.8 2.83-.03s.76 2.04-.03 2.83L32.71 54.05c-3.23 3.23-7.44 4.85-11.62 4.85ZM41.13 5.03c-2.88 0-5.78 1.12-8.01 3.34l-.09.09-22.95 22.96C7.2 34.3 5.59 38.09 5.53 42.11c-.06 4.01 1.44 7.75 4.22 10.53l1.03 1.03c5.76 5.76 15.28 5.61 21.22-.33l22.95-22.95c.4-.4.41-1.03.03-1.42-.38-.38-1.02-.37-1.42.03L30.61 51.95c-5.14 5.14-13.39 5.26-18.39.26l-1.03-1.03c-5-5-4.88-13.25.27-18.39L34.5 9.75c1.72-1.72 3.99-2.69 6.39-2.73h.15c2.35 0 4.54.9 6.18 2.54 1.67 1.67 2.57 3.92 2.54 6.33-.04 2.4-1.01 4.67-2.73 6.39L26.01 43.3c-1.58 1.58-4.12 1.61-5.66.07l-.3-.3a3.846 3.846 0 0 1-1.13-2.82c.02-1.07.44-2.08 1.21-2.84l20.93-20.93c.4-.4.41-1.03.03-1.42s-1.02-.37-1.42.03L18.74 36.02a6.14 6.14 0 0 0-1.82 4.28c-.02 1.6.57 3.1 1.69 4.21l.3.3c2.3 2.3 6.11 2.24 8.49-.14l21.02-21.02c4.36-4.36 4.47-11.33.26-15.55-2.06-2.06-4.79-3.09-7.54-3.09Z" />
        </svg>
    );
}

export default Attachment;
