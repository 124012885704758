import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';

import ConnectionOverlay from '../Globals/ConnectionOverlay';
import NoVideo from '../Icons/NoVideo';
import './VideoContainer.scss';
import './VideoContainerDispatcher.scss';
import { store as dispatcherStore } from '../../store/DispatcherStore';
import { POINTER_BLINKING_DURATION } from '../../config';
import { dispatchUnsetLiveVideoIsLoading, pauseSnapshotDispatch, unpauseSnapshotDispatch } from '../../redux/actions/application';
import { PaintCanvas } from '../Canvas/PaintCanvas';
import { PaintToolbar } from '../Canvas/PaintToolbar';
import ReactResizeDetector from 'react-resize-detector';
import SnapshotButtons from '../Dispatcher/SnapshotButtons';
import Loading from '../Icons/LoadingSpinner';

/**
 * VideoContainerDispatcher
 * Shows the video if activated. Stream data is transmitted via an established webRTC connection.
 * Contains the pointer functionality.
 *
 * @component Connection Overlay - shows waiting for connection until webRTC connection is established
 */
class VideoContainerDispatcher extends PureComponent {
    _pointerTimeout = null;

    constructor(props) {
        super(props);

        this.containerRef = React.createRef();
        this.state = {
            pointerChanged: false,
            width: null,
            height: null,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.pointerFeature && this.props.hasVideoStream) {
            this.toggleControls();
            this.addPointerListener();
        }

        if (this.props.videoIsActive) {
            if (!this.props.isPaintingAllowed && prevProps.isPaintingAllowed !== this.props.isPaintingAllowed) {
                const videoElement = this.containerRef.current.querySelector('video');
                if (videoElement) {
                    videoElement.style.maxWidth = null;
                    videoElement.style.minWidth = null;
                    videoElement.style.maxHeight = null;
                    videoElement.style.minHeight = null;
                }
            }
        }

        if (this.props.callerStream && this.props.callerStream !== prevProps.callerStream) {
            dispatchUnsetLiveVideoIsLoading();
        }
    }

    addPointerListener = () => {
        if (this.containerRef && this.containerRef.current) {
            const videoElement = this.containerRef.current.querySelector('video');
            if (videoElement) {
                if (this.props.pointerIsActive) {
                    videoElement.addEventListener('click', this.addPointer);
                } else {
                    videoElement.removeEventListener('click', this.addPointer);
                }
            }
        }
    };

    addPointer = e => {
        if (this.containerRef && this.containerRef.current) {
            const videoElement = this.containerRef.current.querySelector('.videoElement');
            const pointerElement = this.containerRef.current.querySelector('.pointerElement');
            if (videoElement) {
                const position = {
                    xPercent: (e.layerX / e.currentTarget.getBoundingClientRect().width) * 100,
                    yPercent: (e.layerY / e.currentTarget.getBoundingClientRect().height) * 100,
                };

                if (pointerElement) {
                    pointerElement.style.left = `${position.xPercent}%`;
                    pointerElement.style.top = `${position.yPercent}%`;

                    this.setState({ pointerChanged: true }, () => {
                        videoElement.pause();
                        pauseSnapshotDispatch();
                        clearTimeout(this._pointerTimeout);
                        this._pointerTimeout = window.setTimeout(() => {
                            videoElement.play();
                            unpauseSnapshotDispatch();
                            this.setState({ pointerChanged: false });
                        }, POINTER_BLINKING_DURATION);
                    });
                }

                dispatcherStore.sendPointerPosition(position);
            }
        }
    };

    toggleControls = () => {
        if (this.containerRef && this.containerRef.current) {
            const videoElement = this.containerRef.current.querySelector('video');
            if (videoElement) {
                videoElement.controls = !this.props.pointerIsActive;
            }
        }
    };

    changeDimensions = (width, height) => {
        const videoElement = this.containerRef.current.querySelector('video');
        if (!this.props.isPaintingAllowed) {
            // only update state if painting is not allowed
            this.setState({ width, height });
            // reset video with
            if (videoElement) {
                videoElement.style.maxWidth = null;
                videoElement.style.maxHeight = null;
            }
        } else {
            // set fixed video width
            if (videoElement) {
                videoElement.style.maxWidth = this.state.width + 'px';
                videoElement.style.minWidth = this.state.width + 'px';
                videoElement.style.maxHeight = this.state.height + 'px';
                videoElement.style.minHeight = this.state.height + 'px';
            }
        }
    };

    render() {
        const classes = clsx('videoContainer', {
            'videoContainer--active': this.props.videoIsActive,
            'videoContainer--pointer': this.props.pointerIsActive,
            'videoContainer--pointerVisible': this.state.pointerChanged,
        });
        return (
            <>
                <div className={classes}>
                    <ConnectionOverlay force={true} />
                    {this.props.videoIsActive ? (
                        ''
                    ) : (
                        <div className="videoContainer__placeholder">
                            <NoVideo />
                        </div>
                    )}
                    <ReactResizeDetector handleWidth handleHeight onResize={this.changeDimensions}>
                        {() => {
                            return (
                                <div className="videoContainer__inner-wrapper">
                                    <div ref={this.containerRef} id="videoContainer__inner"></div>
                                    {this.props.videoIsLoading && this.props.callerStream ? <Loading /> : ''}

                                    {this.props.drawFeature && this.props.drawIsActive && (
                                        <>
                                            <PaintCanvas currentUser="dispatcher" width={this.state.width} height={this.state.height} />
                                            <PaintToolbar />
                                        </>
                                    )}
                                </div>
                            );
                        }}
                    </ReactResizeDetector>
                </div>
                <SnapshotButtons />
            </>
        );
    }
}

// PropTypes for this Component
VideoContainerDispatcher.propTypes = {
    videoIsActive: PropTypes.bool,
    pointerIsActive: PropTypes.bool,
    hasVideoStream: PropTypes.bool,
    pointerFeature: PropTypes.bool,
    isConnected: PropTypes.bool,
    drawFeature: PropTypes.bool,
    drawIsActive: PropTypes.bool,
    isPaintingAllowed: PropTypes.bool,
    videoIsLoading: PropTypes.bool,
    callerStream: PropTypes.any,
};

const mapStateToProps = state => {
    return {
        videoIsActive: state.application.videoIsActive,
        pointerIsActive: state.application.pointerIsActive,
        hasVideoStream: state.application.hasVideoStream,
        pointerFeature: state.features.pointerFeature,
        isConnected: state.connection.isConnected,
        drawFeature: state.features.drawFeature,
        drawIsActive: state.application.drawIsActive,
        isPaintingAllowed: state.paint.isPaintingAllowed,
        videoIsLoading: state.application.videoIsLoading,
        callerStream: state.streams.callerStream,
    };
};

export default connect(mapStateToProps)(VideoContainerDispatcher);
