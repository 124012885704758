import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import clsx from 'clsx';

import './SMSGuide.scss';
import Loading from '../Icons/LoadingSpinner';
import sms from '../../resources/sms.gif';
import { CopyAddressButton } from './CopyAddressButton';
import { replaceText } from '../../helper/helper';

export const SMSGuideComponent = ({ isConnected, texts }) => {
    const [resendButtonIsHidden, setResendButtonIsHidden] = useState(true);
    const [willShow, setWillShow] = useState(true);

    useEffect(() => {
        if (isConnected) {
            setWillShow(false);
        }
        if (resendButtonIsHidden) {
            setTimeout(() => {
                setResendButtonIsHidden(false);
            }, 10000);
        }
    }, [isConnected, resendButtonIsHidden]);

    const close = e => {
        /* eslint-disable-next-line */
        if (!e.target.closest('.sms-guide__content')) setWillShow(false);
    };

    const classes = clsx('sms-guide', {
        'sms-guide--shown': !isConnected && willShow,
    });

    return (
        <div className={classes} onClick={close}>
            <div className="sms-guide__content">
                <div
                    className="sms-guide__close"
                    onClick={() => {
                        setWillShow(false);
                    }}>
                    {replaceText(texts, 'smsguide.close')}
                </div>
                <h3>
                    {replaceText(texts, 'smsguide.wait')} <Loading />
                </h3>
                <p>
                    {replaceText(texts, 'smsguide.hint1')}
                    <br />
                    {replaceText(texts, 'smsguide.hint2')}
                    <br />
                    {replaceText(texts, 'smsguide.hint3')}
                    {/* <br />
                    <span>{replaceText(texts, 'warning.data.notice')}</span> */}
                </p>
                <CopyAddressButton userType="caller" />
                <p>
                    <img src={sms} alt="SMS guide animation" />
                </p>
            </div>
        </div>
    );
};

// PropTypes for this Component
SMSGuideComponent.propTypes = {
    isConnected: PropTypes.bool,
    texts: PropTypes.object,
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        isConnected: state.connection.isConnected,
        texts: state.texts.texts,
    };
};

// Connect Props and Dispatch to Component
export const SMSGuide = connect(mapStateToProps)(SMSGuideComponent);
