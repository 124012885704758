import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';

import { replaceText } from '../../helper/helper';
import { C_LOST } from '../../config';

import LoadingSpinner from '../Icons/LoadingSpinner';

import './ConnectionOverlay.scss';

const ConnectionOverlay = props => {
    const classes = clsx('connectionOverlay', {
        'connectionOverlay--inactive': props.isConnected,
        'connectionOverlay--lostConnection': !props.force && props.connectionStatus === C_LOST,
        'connectionOverlay--forced': props.force && props.connectionStatus === C_LOST,
    });
    return (
        <div className={classes}>
            <div>
                <span>{replaceText(props.texts, 'connection.waiting')}</span> <LoadingSpinner />
            </div>
        </div>
    );
};

// PropTypes for this Component
ConnectionOverlay.propTypes = {
    texts: PropTypes.any,
    isConnected: PropTypes.bool,
    connectionStatus: PropTypes.string,
    force: PropTypes.bool,
};

const mapStateToProps = state => {
    return {
        isConnected: state.connection.isConnected,
        connectionStatus: state.connection.status,
        texts: state.texts.texts,
    };
};

export default connect(mapStateToProps)(ConnectionOverlay);
