import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { changeLanguageDispatcherDispatch } from '../../redux/actions/texts';

import Dropdown from '../Globals/Dropdown';
import './LanguageSelect.scss';
import { SUPPORTED_LANGUAGES } from '../../config';
import Italy from '../Icons/Italy';
import Germany from '../Icons/Germany';
import France from '../Icons/France';
import UK from '../Icons/UK';
import { setLanguageForDispatcher } from '../../api/backendApi';
import { replaceText } from '../../helper/helper';
import { Tick } from '../Icons/Tick';
class LanguageSelect extends PureComponent {
    handleSelect = async id => {
        await setLanguageForDispatcher(id);
        changeLanguageDispatcherDispatch(id);
    };

    getFlag = lang => {
        switch (lang) {
            case 'de':
                return (
                    <React.Fragment>
                        <Germany />
                        <span className="languageSelectText">
                            {replaceText(this.props.texts, 'lang.de')}
                        </span>
                    </React.Fragment>
                );
            case 'it':
                return (
                    <React.Fragment>
                        <Italy />
                        <span className="languageSelectText">
                            {replaceText(this.props.texts, 'lang.it')}
                        </span>
                    </React.Fragment>
                );
            case 'fr':
                return (
                    <React.Fragment>
                        <France />
                        <span className="languageSelectText">
                            {replaceText(this.props.texts, 'lang.fr')}
                        </span>
                    </React.Fragment>
                );
            case 'en':
                return (
                    <React.Fragment>
                        <UK />
                        <span className="languageSelectText">
                            {replaceText(this.props.texts, 'lang.en')}
                        </span>
                    </React.Fragment>
                );
            default:
                return 'missing';
        }
    };

    render() {
        const languages = {};

        SUPPORTED_LANGUAGES.forEach(lang => {
            languages[lang] = this.getFlag(lang);
        });
        return (
            <div className="languageSelect">
                <div>
                    <Dropdown
                        hasFlags={true}
                        selectChild={true}
                        minHeight={20}
                        clickHandler={this.handleSelect}
                        label={languages[this.props.language]}>
                        <LanguageOptions
                            languages={languages}
                            currentLanguage={this.props.language}
                        />
                    </Dropdown>
                </div>
            </div>
        );
    }
}

// PropTypes for this Component
LanguageSelect.propTypes = {
    language: PropTypes.string,
    texts: PropTypes.any,
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        language: state.texts.language,
        texts: state.texts.texts,
    };
};

// Connect Props and Dispatch to Component
export default connect(mapStateToProps)(LanguageSelect);

// Language Option Element
function LanguageOptions(props) {
    return (
        <div>
            {Object.keys(props.languages).map(language => (
                <div
                    className="languageSelect__option"
                    key={language}
                    onClick={() => {
                        props.clickElement(language);
                    }}>
                    <span>{props.languages[language]}</span>
                    <span>
                        {props.currentLanguage === language && <Tick />}
                    </span>
                </div>
            ))}
        </div>
    );
}

LanguageOptions.propTypes = {
    clickElement: PropTypes.func,
    languages: PropTypes.any,
    currentLanguage: PropTypes.any,
};
