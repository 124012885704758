import { SET_PHONENUMBERS } from '../actions/actionTypes';

export const initialState = [];

/**
 * addresses reducer
 * see https://redux.js.org/
 * @param {object} state
 * @param {object} action
 */

const phonenumbersReducer = (state = initialState, action) => {
    if (action.type === SET_PHONENUMBERS) {
        return [...action.phonenumbers];
    }

    return state;
};

export default phonenumbersReducer;
