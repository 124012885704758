import React, { useEffect, useState } from 'react';
import './InvitationPanel.scss';
import { replaceText } from '../../helper/helper';
import ModalOverlay from '../Globals/ModalOverlay';
import AudioPanel from './AudioPanel';
import { addNotificationAndShowDispatch } from '../../redux/actions/notifications';
import { dispatchCloseInvitationPanel, dispatchOpenInvitationPanel } from '../../redux/actions/application';
import { Invitation } from './Invitation';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

export const InvitationPanel = () => {
    const texts = useSelector((state: RootState) => state.texts.texts);
    const invitationPanelIsOpen = useSelector((state: RootState) => state.application.invitationPanelIsOpen);
    const audioStreamIsActive = useSelector((state: RootState) => state.application.audioStreamIsActive);
    const isConnected = useSelector((state: RootState) => state.connection.isConnected);
    const audioStreamFeature = useSelector((state: RootState) => state.features.audioStreamFeature);
    const inviteFeature = useSelector((state: RootState) => state.features.inviteFeature);
    const disclaimerFeatureCaller = useSelector((state: RootState) => state.features.disclaimerFeatureCaller);
    const usageDisclaimerStateCaller = useSelector((state: RootState) => state.disclaimers.usageDisclaimerStateCaller);

    const [buttonIsEnabled, setButtonIsEnabled] = useState(false);

    const promptToActivateAudioAndProceed = () => {
        if (!audioStreamIsActive && audioStreamFeature) {
            addNotificationAndShowDispatch('warning.invite.audioDependency', 'warning', true);
        } else {
            dispatchOpenInvitationPanel();
        }
    };

    useEffect(() => {
        const disableConditions = () => {
            if (disclaimerFeatureCaller) {
                if (usageDisclaimerStateCaller === 'accepted' || usageDisclaimerStateCaller === 'none') {
                    setButtonIsEnabled(isConnected);
                }
                return;
            }

            if (!isConnected) {
                setButtonIsEnabled(false);
            } else {
                setButtonIsEnabled(true);
            }
        };

        disableConditions();
    }, [disclaimerFeatureCaller, usageDisclaimerStateCaller, isConnected]);

    return (
        <div className="invitationPanel">
            <AudioPanel />
            {inviteFeature && (
                <>
                    <button className="btn btn--primary" onClick={promptToActivateAudioAndProceed} disabled={!buttonIsEnabled}>
                        {replaceText(texts, 'invitation.button')}
                    </button>

                    <ModalOverlay modalIsVisible={invitationPanelIsOpen} hasCloseButton closeHandler={dispatchCloseInvitationPanel}>
                        <Invitation />
                    </ModalOverlay>
                </>
            )}
        </div>
    );
};
