import React, { useEffect, useState } from 'react';
import { Image } from '../Icons/Image';
import { Document } from '../Icons/Document';
import { Presentation } from '../Icons/Presentation';
import { Table } from '../Icons/Table';
import { Video } from '../Icons/Video';
import { Cancel } from '../Icons/Cancel';
import { removePreviewFileDispatch } from '../../redux/actions/files';
import Download from '../Icons/Download';
import { SoundFile } from '../Icons/SoundFile';
import { MAX_FILE_NAME_LENGTH } from '../../config';
import Attachment from '../Icons/Attachment';
import { getFileTypeCategory } from '../../helper/helper';
import { FILE_TYPE } from '../../types';

interface ChatFileProps {
    file: any;
    preview?: boolean;
    download: boolean;
    clickHandler;
    showFileInfo?: boolean;
}

const ChatFile: React.FC<ChatFileProps> = ({ file, preview = false, download, clickHandler, showFileInfo = true }) => {
    const [fileType, setFileType] = useState('');

    const handleClick = download ? clickHandler : preview ? removePreviewFileDispatch : null;

    useEffect(() => {
        setFileType(getFileTypeCategory(file));
    }, [file]);

    return (
        <div className="chat-file-preview flex-column">
            <div className="flex-row">
                <div className="chat-file-icons" onClick={handleClick}>
                    {renderFileTypeIcon(fileType)}
                    {preview ? <Cancel /> : ''}
                    {download ? <Download /> : ''}
                </div>
                {showFileInfo && (
                    <div className="chat-file-info">
                        {/* truncate file name to fit within the bondary of the chat bubble */}
                        <span>{file.name.length > MAX_FILE_NAME_LENGTH ? file.name.substring(0, MAX_FILE_NAME_LENGTH) : file.name}</span>
                        <span>
                            {file.name.length > MAX_FILE_NAME_LENGTH ? file.name.substring(MAX_FILE_NAME_LENGTH, MAX_FILE_NAME_LENGTH * 2) : ''}
                            {file.name.length > MAX_FILE_NAME_LENGTH * 2 ? '...' : ''}
                        </span>
                        <span>{file.size}</span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ChatFile;

export const renderFileTypeIcon = (fileType: string) => {
    switch (fileType) {
        case FILE_TYPE.IMAGE:
            return <Image />;
        case FILE_TYPE.DOCUMENT:
            return <Document />;
        case FILE_TYPE.PRESENTATION:
            return <Presentation />;
        case FILE_TYPE.TABLE:
            return <Table />;
        case FILE_TYPE.VIDEO:
            return <Video />;
        case FILE_TYPE.AUDIO:
            return <SoundFile />;
        default:
            return <Attachment />;
    }
};
