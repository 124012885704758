import React from 'react';

function NoScreenshare() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
            <path d="m182.83 177.17-22.26-22.26C168.43 151.71 174 144 174 135V65c0-11.85-9.64-21.5-21.5-21.5H49.16L22.83 17.17a4.008 4.008 0 0 0-5.66 0 4.008 4.008 0 0 0 0 5.66l22.26 22.26C31.57 48.29 26 56 26 65v70c0 11.86 9.64 21.5 21.5 21.5h103.34l26.33 26.33c.78.78 1.8 1.17 2.83 1.17s2.05-.39 2.83-1.17a4.008 4.008 0 0 0 0-5.66ZM152.5 51.5c7.44 0 13.5 6.06 13.5 13.5v70c0 6.92-5.23 12.63-11.94 13.4l-35.84-35.84 11.12-10.37c1.07-1.01 1.65-2.38 1.65-3.83s-.59-2.82-1.66-3.83L107.95 74.6c-.42-.39-1.03-.5-1.57-.27-.53.23-.87.76-.87 1.33v11.68c-3.55.57-6.85 1.45-9.92 2.61L57.15 51.51h95.34Zm-105 97c-7.44 0-13.5-6.06-13.5-13.5V65c0-6.92 5.23-12.63 11.94-13.4l42.12 42.12c-9.69 6.18-16.08 16.3-19.03 30.33-.14.67.2 1.34.86 1.64.18.08.38.11.57.11.46 0 .91-.22 1.17-.59 7.62-10.3 17.58-15.26 32.02-15.91l1.87 1.87v9.9c0 .58.34 1.1.88 1.34.53.22 1.14.12 1.56-.28l4.41-4.11 30.47 30.47H47.5Z" />
        </svg>
    );
}

export default NoScreenshare;
