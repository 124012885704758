import {
    cssVariables as defaultVariables,
    logo as defaultLogo,
    companyLogo as defaultCompanyLogo,
    companyTitle as defaultCompanyTitle,
} from './emex.js';

export const cssVariables = defaultVariables;
export const logo = defaultLogo;
export const companyTitle = defaultCompanyTitle;
export const companyLogo = defaultCompanyLogo;
export const footerLogo = 'footerLogoLink';
