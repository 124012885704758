import React from 'react';

function NoVideo() {
    return (
        <svg id="noVideo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" fill="#e6e7e8">
            <path 
                id="noVideo-2" 
                data-name="noVideo" 
                d="m177.11,60.82c-1.35-.56-2.91-.25-3.94.78l-17.21,17.21v-6.87c0-8.62-7.01-15.64-15.62-15.64H61.41l-31.23-31.23c-1.41-1.41-3.7-1.41-5.12,0-1.41,1.41-1.41,3.7,0,5.12l26.11,26.11h-14.89c-4.17,0-8.1,1.63-11.05,4.58-2.95,2.95-4.57,6.88-4.58,11.06v56.19c0,8.59,6.99,15.58,15.58,15.58h102.35l31.23,31.23c.71.71,1.63,1.06,2.56,1.06s1.85-.35,2.56-1.06c1.41-1.41,1.41-3.7,0-5.12l-27.66-27.66c1.54-.76,2.98-1.77,4.22-3.04,2.87-2.94,4.46-6.84,4.46-10.99v-7.07l17.18,17.56c1.03,1.05,2.59,1.38,3.95.82,1.36-.56,2.25-1.88,2.25-3.35v-71.94c0-1.46-.88-2.78-2.23-3.34ZM36.23,136.47c-4.6,0-8.34-3.74-8.34-8.34v-56.19c0-2.25.87-4.36,2.46-5.95,1.58-1.59,3.69-2.46,5.93-2.46h22.13l72.94,72.94H36.23Zm135.87-9.25l-17.18-17.56c-1.03-1.05-2.59-1.38-3.95-.82s-2.25,1.88-2.25,3.35v15.94c0,2.24-.85,4.35-2.39,5.92-1.31,1.34-2.99,2.16-4.79,2.36L68.65,63.53h71.69c4.62,0,8.38,3.77,8.38,8.41v15.61c0,1.46.88,2.78,2.23,3.34,1.35.56,2.91.25,3.94-.78l17.21-17.21v54.32Z"
            />
        </svg>
    );
}

export default NoVideo;
