import React from 'react';

export const Copy = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="800" height="800" fill="none" viewBox="0 0 24 24">
            <rect width="10" height="14" x="6" y="6" fill="#fff" rx="1.5" />
            <path
                fill="#fff"
                d="M8.064 5.064A1.5 1.5 0 0 1 8.5 5h7A1.5 1.5 0 0 1 17 6.5v11a1.5 1.5 0 0 1-.064.436A1.5 1.5 0 0 0 18 16.5v-11A1.5 1.5 0 0 0 16.5 4h-7a1.5 1.5 0 0 0-1.436 1.064Z"
            />
        </svg>
    );
};
