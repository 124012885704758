import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import './Phonebook.scss';
import clsx from 'clsx';
import OutsideClickHandler from 'react-outside-click-handler';
import Contacs from '../Icons/Contacts';
import { replaceText } from '../../helper/helper';
import ArrowDown from '../Icons/ArrowDown';

export const PhonebookComponent = ({
    clickHandler,
    phonenumbers,
    texts,
    showName = false,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const selectClasses = clsx('phonebook__select', {
        'phonebook__select--open': isOpen,
    });

    const classes = clsx('phonebook', {
        'phonebook--name': showName,
    });

    const btnClasses = clsx('btn btn--primary', {
        'button--isCollapsed': isOpen,
    });

    const open = e => {
        e.preventDefault();
        setIsOpen(true);
    };

    const close = e => {
        setIsOpen(false);
        setSearchTerm('');
    };

    const updateSearchTerm = e => {
        setSearchTerm(e.target.value);
    };

    const filterEntries = entry => {
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        const lowerCaseName = entry.name.toLowerCase();
        const lowerCaseRole = entry.role.toLowerCase();
        const lowerCasePhone = entry.phone.toLowerCase();

        return (
            lowerCaseName.indexOf(lowerCaseSearchTerm) !== -1 ||
            lowerCaseRole.indexOf(lowerCaseSearchTerm) !== -1 ||
            lowerCasePhone.indexOf(lowerCaseSearchTerm) !== -1
        );
    };

    const sortEntriesByName = (a, b) => {
        return `${a['name']}`.localeCompare(b['name']);
    };

    const entryClickHandler = phone => {
        close();
        clickHandler(phone);
    };

    return (
        <div className={classes}>
            <button type="button" className={btnClasses} onClick={open}>
                <Contacs />
                <span>
                    {showName && replaceText(texts, 'phonebook.button')}
                </span>
                <ArrowDown />
            </button>
            <div className={selectClasses}>
                <OutsideClickHandler onOutsideClick={close}>
                    <div className="phonebook__search">
                        <input
                            onChange={updateSearchTerm}
                            type="text"
                            // placeholder={replaceText(texts, 'phonebook.search')}
                            value={searchTerm}
                        />
                    </div>
                    <div className="phonebook__entries">
                        {phonenumbers
                            .filter(filterEntries)
                            .sort(sortEntriesByName)
                            .map(phonenumber => (
                                <div
                                    className="phonebook__entry"
                                    onClick={() =>
                                        entryClickHandler(phonenumber.phone)
                                    }
                                    key={phonenumber.id}>
                                    <span className="phonebook__name">
                                        {phonenumber.name}
                                    </span>
                                    <span className="phonebook__role">
                                        {phonenumber.role}
                                    </span>
                                    <span className="phonebook__phone">
                                        {phonenumber.phone}
                                    </span>
                                </div>
                            ))}
                    </div>
                </OutsideClickHandler>
            </div>
        </div>
    );
};

// PropTypes for this Component
PhonebookComponent.propTypes = {
    clickHandler: PropTypes.func,
    phonenumbers: PropTypes.array,
    showName: PropTypes.bool,
    texts: PropTypes.object,
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        phonenumbers: state.phonenumbers,
        texts: state.texts.texts,
    };
};

// Connect Props and Dispatch to Component
export const Phonebook = connect(mapStateToProps)(PhonebookComponent);
