import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import {
    HEALTH_POLLING_INTERVAL,
    SYSTEM_MESSAGES_POLLING_RETRY,
    DEBUG,
} from '../../config';
import { getWelcomeMessages } from '../../api/backendApi';

import './SystemMessages.scss';
import { addLogDispatch } from '../../redux/actions/logs';

/**
 * System Messages
 * shows messages provided from the admin ui via a backend api
 */
class SystemMessages extends PureComponent {
    _messageInteval = 0;
    _messageTimeout = 0;

    constructor(props) {
        super(props);

        this.state = {
            messages: [
                {
                    order: 0,
                    text: '',
                    headline: '',
                    active: false,
                    messageType: '',
                },
                {
                    order: 1,
                    text: '',
                    headline: '',
                    active: false,
                    messageType: '',
                },
            ],
        };
    }

    componentDidMount() {
        this.getMessages();
        this.startPolling();
    }

    componentWillUnmount() {
        if (DEBUG) addLogDispatch(['polling message api ended']);
        clearInterval(this._messageInteval);
        clearTimeout(this._messageTimeout);
    }

    startPolling = () => {
        if (DEBUG) addLogDispatch(['polling message api started']);
        this.messagePolling();
    };

    messagePolling = () => {
        this._messageInteval = setInterval(() => {
            this.getMessages();
        }, HEALTH_POLLING_INTERVAL);
    };

    getMessages = async () => {
        const messages = await getWelcomeMessages().catch(() => {
            clearInterval(this._messageInteval);
            if (DEBUG) addLogDispatch(['polling message api ended']);
            this._messageTimeout = setTimeout(
                this.healthPolling,
                SYSTEM_MESSAGES_POLLING_RETRY
            );
        });
        if (messages) this.setState({ messages });
    };

    render() {
        return (
            <div className="systemMessages">
                <div className="systemMessages__messages">
                    {this.state.messages
                        .sort((a, b) => {
                            if (a.order > b.order) return 1;
                            if (b.order > a.order) return -1;
                            return 0;
                        })
                        .map(
                            message =>
                                message.active && (
                                    <div
                                        className={`systemMessages__message alert alert--${message.messageType.toLowerCase()}`}
                                        key={message.order}>
                                        <h2>{message.headline}</h2>
                                        <div>
                                            <Message message={message.text} />
                                        </div>
                                    </div>
                                )
                        )}
                </div>
            </div>
        );
    }
}

// PropTypes for this Component
SystemMessages.propTypes = {};

/**
 * Message
 * message rendering
 */

function Message(props) {
    if (props.message.indexOf('<') === -1) {
        return props.message.split('\n').map((text, i) => (
            <p
                key={i}
                dangerouslySetInnerHTML={{
                    __html: text,
                }}></p>
        ));
    }
    return (
        <div
            dangerouslySetInnerHTML={{
                __html: props.message,
            }}></div>
    );
}

Message.propTypes = {
    message: PropTypes.string,
};

export default SystemMessages;
