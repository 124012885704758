import React from 'react';
import PropTypes from 'prop-types';

import './ToggleSwitch.scss';
import clsx from 'clsx';
import { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';

/**
 * ToggleSwitch
 * Simple custom toggle switch. Can be turned on and off.
 */

const ToggleSwitch = ({ changeHandler, label, isDisabled, isActive, id, sub, title = '', longDelay }) => {
    const [toggleIsBusy, setToggleIsBusy] = useState(false);
    let toggleTimeoutRef = useRef();
    let delay = 2000;
    if (longDelay) {
        delay = 3500;
    }

    const toggle = () => {
        if (!toggleIsBusy && !isDisabled) {
            setToggleIsBusy(true);
            changeHandler();
            toggleTimeoutRef.current = setTimeout(() => {
                setToggleIsBusy(false);
            }, delay);
        }
    };

    const classes = clsx('toggleSwitch', {
        'toggleSwitch--active': isActive,
        'toggleSwitch--disabled': isDisabled,
        'toggleSwitch--sub': sub,
        'toggleSwitch--busy': toggleIsBusy,
    });

    useEffect(() => {
        clearTimeout(toggleTimeoutRef.current);
    }, []);

    return (
        <label className={classes} id={id} title={title}>
            <span>{label}</span>
            <input type="checkbox" onChange={toggle} checked={isActive} />
            <div className="toggleSwitch__background">
                <div className="toggleSwitch__line"></div>
                <div className="toggleSwitch__switch" id={'toggleSwitch__switch_' + id}></div>
            </div>
        </label>
    );
};

// PropTypes for this Component
ToggleSwitch.propTypes = {
    changeHandler: PropTypes.func,
    label: PropTypes.string,
    isDisabled: PropTypes.bool,
    id: PropTypes.string,
    sub: PropTypes.bool,
    isActive: PropTypes.bool,
    title: PropTypes.string,
    longDelay: PropTypes.bool,
};

export default ToggleSwitch;
