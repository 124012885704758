import store from "../store";

export const ADD_FILE_DISPATCHER = 'UPLOAD_FILE_DISPATCHER';
export const REMOVE_FILE_DISPATCHER = 'REMOVE_FILE_DSIPATCHER';
export const ADD_FILE_CALLER = 'UPLOAD_FILE_CALLER';
export const REMOVE_FILE_CALLER = 'REMOVE_FILE_CALLER';
export const ADD_PREVIEW_FILE = 'ADD_PREVIEW_FILE';
export const REMOVE_PREVIEW_FILE = 'REMOVE_PREVIEW_FILE'; 
export const ADD_FILE_UPLOAD_QUEUE = 'ADD_FILE_TO_UPLOAD_QUEUE';
export const REMOVE_FILE_UPLOAD_QUEUE = 'REMOVE_FILE_UPLOAD_QUEUE';
export const RESET_FILES = 'RESET_FILES';

const addFileDispatcher = (state: Object) => {
    return {
        type: ADD_FILE_DISPATCHER,
        payload: state,
    }
};

export const addFileDispatcherDispatch = (state: Object) => {
    store.dispatch(addFileDispatcher(state));
};

const removeFileDispatcher = () => {
    return {
        type: REMOVE_FILE_DISPATCHER
    };
};

export const removeFileDispatcherDispatch = () => {
    store.dispatch(removeFileDispatcher());
};

const addFileCaller = (state: Object) => {
    return {
        type: ADD_FILE_CALLER,
        payload: state,
    }
};

export const addFileCallerDispatch = (state: Object) => {
    store.dispatch(addFileCaller(state));
};

const removeFileCaller = () => {
    return {
        type: REMOVE_FILE_CALLER,
    };
};

export const removeFileCallerDispatch = () => {
    store.dispatch(removeFileCaller());
};

const addPreviewFile = (state: Object) => {
    return {
        type: ADD_PREVIEW_FILE,
        payload: state,
    };
};

export const addPreviewFileDispatch = (state: Object) => {
    store.dispatch(addPreviewFile(state));
};

const removePreviewFile = () => {
    return {
        type: REMOVE_PREVIEW_FILE,
    };
};

export const removePreviewFileDispatch = (state: Object) => {
    store.dispatch(removePreviewFile());
};

export const addFileUploadQueue = (state: Object) => {
    return {
        type: ADD_FILE_UPLOAD_QUEUE,
        payload: state,
    };
}

export const addFileUploadQueueDispatch = (state: Object) => {
    store.dispatch(addFileUploadQueue(state));
};

export const removeFileUploadQueue = (state: Object) => {
    return {
        type: REMOVE_FILE_UPLOAD_QUEUE,
        payload: state,
    };
}

export const removeFileUploadQueueDispatch = (state: Object) => {
    store.dispatch(removeFileUploadQueue(state));
}

export const resetFiles = () => {
    return {
        type: RESET_FILES,
    }
}

export const resetFilesDispatch = () => {
    store.dispatch(resetFiles());
}