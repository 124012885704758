import React from 'react';

export default function ActiveVideoWithBlur() {
    const theme = require('../../themes/' + process.env.REACT_APP_THEME);

    return (
        <svg id="BIDI" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120" fill={theme.cssVariables.primary}>
            <g id="plain">
                <ellipse cx="59.92" cy="52.61" rx="6.41" ry="6.48" />
                <path d="m88.18,32.73H31.82c-5.13,0-9.32,4.23-9.32,9.43v35.67c0,5.19,4.18,9.43,9.32,9.43h56.36c5.13,0,9.32-4.23,9.32-9.43v-35.67c0-5.19-4.18-9.43-9.32-9.43Zm-61.13,9.44h0c0-2.68,2.14-4.83,4.77-4.83h5.61l-10.39,10.51v-5.68Zm-.01,12.17h.01l16.81-17.01h7.49l-24.31,24.59v-7.59Zm65.91,23.5c0,2.66-2.14,4.82-4.77,4.82h-5.16l9.93-10.04s0,5.22,0,5.22Zm0-25.79h0s-16.62,16.81-16.62,16.81c.09.56.15,1.13.15,1.71v4.65l16.47-16.67v7.59l-16.35,16.55h-4.61v-11.71c0-3.81-3.05-6.9-6.82-6.9h-10.57c-3.77,0-6.82,3.09-6.82,6.9v11.71h-6.52l2.08-2.11v-6.51l-8.5,8.6h-3.02c-1.35,0-2.58-.58-3.45-1.5l22.34-22.6c-.32-.51-.58-1.07-.82-1.62-.09-.21-.17-.42-.26-.63-.04-.1-.05-.2-.09-.29-.25-.73-.4-1.5-.48-2.29l-22.01,22.27v-7.59l30.73-31.1h7.49l-4.25,4.3c.81.09,1.57.25,2.32.5.09.02.19.05.27.09.33.12.66.25.98.41.42.2.83.42,1.21.67l5.89-5.96h7.94l-10.06,10.18c.29.56.52,1.13.71,1.74.09.26.17.53.25.81.05.24.1.47.14.72.11.59.19,1.21.19,1.85v.12l15.22-15.39h2.11c1.47,0,2.78.69,3.66,1.76l-21.46,21.72c1.4.69,2.64,1.65,3.62,2.84l18.96-19.19v7.59Z" />
            </g>
        </svg>
    );
}
