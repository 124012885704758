import { createKpiLog } from '../../helper/helper';
import { DisclaimerState } from '../reducers/disclaimers';
import store from '../store';

export const SET_SNAPSHOT_DISCLAIMER_ACCEPTED = 'SET_SNAPSHOT_DISCLAIMER_ACCEPTED';
export const SET_SNAPSHOT_DISCLAIMER_NOT_ACCEPTED = 'SET_SNAPSHOT_DISCLAIMER_NOT_ACCEPTED';

export const SET_HD_PHOTO_DISCLAIMER_CLICKED = 'SET_HD_PHOTO_DISCLAIMER_CLICKED';
export const SET_HD_PHOTO_DISCLAIMER_NOT_CLICKED = 'SET_HD_PHOTO_DISCLAIMER_NOT_CLICKED';

export const CHANGE_USAGE_DISCLAIMER_STATE_DISPATCHER = 'CHANGE_USAGE_DISCLAIMER_STATE_DISPATCHER';
export const CHANGE_USAGE_DISCLAIMER_STATE_CALLER = 'CHANGE_USAGE_DISCLAIMER_STATE_CALLER';
export const CHANGE_USAGE_DISCLAIMER_STATE_CONFERENCE = 'CHANGE_USAGE_DISCLAIMER_STATE_CONFERENCE';

// Dispatcher disclaimers

// DATA DISCLAMIER

const changeUsageDisclaimerStateDispatcher = (state: DisclaimerState) => {
    return {
        type: CHANGE_USAGE_DISCLAIMER_STATE_DISPATCHER,
        payload: state,
    };
};

export const changeUsageDisclaimerStateDispatcherDispatch = (state: DisclaimerState) => {
    store.dispatch(changeUsageDisclaimerStateDispatcher(state));
};

const changeUsageDisclaimerStateCaller = (state: DisclaimerState) => {
    return {
        type: CHANGE_USAGE_DISCLAIMER_STATE_CALLER,
        payload: state,
    };
};

export const changeUsageDisclaimerStateCallerDispatch = (state: DisclaimerState) => {
    store.dispatch(changeUsageDisclaimerStateCaller(state));
};

const changeUsageDisclaimerStateConference = (state: DisclaimerState) => {
    return {
        type: CHANGE_USAGE_DISCLAIMER_STATE_CONFERENCE,
        payload: state,
    };
};

export const changeUsageDisclaimerStateConferenceDispatch = (state: DisclaimerState) => {
    store.dispatch(changeUsageDisclaimerStateConference(state));
};



// SNAPSHOT DISCLAIMER

const setSnapshotDisclaimerAccepted = () => {
    return {
        type: SET_SNAPSHOT_DISCLAIMER_ACCEPTED,
    };
};

export const setSnapshotDisclaimerAcceptedDispatch = () => {
    store.dispatch(setSnapshotDisclaimerAccepted());
};

const setSnapshotDisclaimerNotAccepted = () => {
    return {
        type: SET_SNAPSHOT_DISCLAIMER_NOT_ACCEPTED,
    };
};

export const setSnapshotDisclaimerNotAcceptedDispatch = () => {
    store.dispatch(setSnapshotDisclaimerNotAccepted());
};

// HD PHOTO DISCLAIMER

const setHdPhotoDisclaimerClicked = () => {
    return {
        type: SET_HD_PHOTO_DISCLAIMER_CLICKED,
    };
};

export const setHdPhotoDisclaimerClickedDispatch = () => {
    createKpiLog('infoHdPhotoDisclaimer', 'clicked');

    store.dispatch(setHdPhotoDisclaimerClicked());
};

const setHdPhotoDisclaimerNotClicked = () => {
    return {
        type: SET_HD_PHOTO_DISCLAIMER_NOT_CLICKED,
    };
};

export const setHdPhotoDisclaimerNotClickedDispatch = () => {
    store.dispatch(setHdPhotoDisclaimerNotClicked());
};
