import store from '../store/index';
import {
    SET_TEXTS,
    SET_LANGUAGE,
    SET_CHAT_LABELS,
    SET_CHAT_LANGUAGE,
    SET_CALLER_LANGUAGE,
    SET_CONFERENCE_LANGUAGE,
} from './actionTypes';
import { getLanguageFile, getChatLabels } from '../../api/backendApi';
import { dispatchAddSessionLanguage } from './session';

const setTexts = texts => {
    return {
        type: SET_TEXTS,
        texts,
    };
};

export const setTextsDispatch = texts => {
    store.dispatch(setTexts(texts));
};

const setLanguage = language => {
    return {
        type: SET_LANGUAGE,
        language,
    };
};

export const setLanguageDispatch = language => {
    store.dispatch(setLanguage(language));
};

export const changeLanguageDispatcherDispatch = async language => {
    setLanguageDispatch(language);
    dispatchAddSessionLanguage({ lang: language });

    const texts = await getLanguageFile(language, 'dispatcher');

    setTextsDispatch(texts);
};

export const changeLanguageCallerDispatch = async language => {
    setLanguageDispatch(language);

    const texts = await getLanguageFile(language, 'caller');

    setTextsDispatch(texts);
};

export const changeLanguageConferenceDispatch = async language => {
    setLanguageDispatch(language);

    const texts = await getLanguageFile(language, 'conference');

    setTextsDispatch(texts);
};

const setChatLabels = chatLabels => {
    return {
        type: SET_CHAT_LABELS,
        chatLabels,
    };
};

export const setChatLabelsDispatch = chatLabels => {
    store.dispatch(setChatLabels(chatLabels));
};

const setChatLanguage = chatLanguage => {
    return {
        type: SET_CHAT_LANGUAGE,
        chatLanguage,
    };
};

export const setChatLanguageDispatch = chatLanguage => {
    store.dispatch(setChatLanguage(chatLanguage));
};

export const changeChatLanguageDispatch = async chatLanguage => {
    setChatLanguageDispatch(chatLanguage);

    const chatLabels = await getChatLabels(chatLanguage);

    setChatLabelsDispatch(chatLabels);
};

const setCallerLanguage = callerLanguage => {
    return {
        type: SET_CALLER_LANGUAGE,
        callerLanguage,
    };
};

export const setCallerLanguageDispatch = callerLanguage => {
    store.dispatch(setCallerLanguage(callerLanguage));
};

const setConferenceLanguage = conferenceLanguage => {
    return {
        type: SET_CONFERENCE_LANGUAGE,
        conferenceLanguage,
    };
};

export const setConferenceLanguageDispatch = conferenceLanguage => {
    store.dispatch(setConferenceLanguage(conferenceLanguage));
};
