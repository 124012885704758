import React from 'react';

function HD() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120">
            <defs />
            <path d="M106.422 0H13.378C5.99 0 0 5.99 0 13.378v93.244C0 114.01 5.99 120 13.378 120h93.244c7.388 0 13.378-5.99 13.378-13.378V13.378C119.8 5.99 113.81 0 106.422 0zM48.32 79.867c-2.795 0-4.991-2.197-4.991-4.992v-8.386H29.95v8.386c-.2 2.795-2.396 4.792-5.191 4.792-2.596-.2-4.593-2.196-4.792-4.792v-29.95c-.2-2.795 1.996-5.191 4.792-5.191 2.795-.2 5.191 1.996 5.191 4.792v11.98h13.378V44.925c-.2-2.795 1.996-5.191 4.792-5.191 2.795-.2 5.191 1.996 5.191 4.792v30.349c0 2.795-2.396 4.992-4.992 4.992zm21.564-39.934h23.361c3.594 0 6.59 2.995 6.59 6.59v26.555c0 3.594-2.996 6.589-6.59 6.589h-23.36c-1.798 0-3.195-1.398-3.195-3.195V43.328c-.2-1.797 1.397-3.395 3.194-3.395zm6.789 29.95H89.85V49.917H76.472l.2 19.966z" />
        </svg>
    );
}

export default HD;
