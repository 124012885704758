import React from 'react';

export const StatusConnected = () => {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path
                    d="M10 0a10 10 0 1 0 10 10A10 10 0 0 0 10 0ZM7 14l-3-3a1 1 0 0 1 1-2l3 3 7-7a1 1 0 0 1 1 2l-7 7a1 1 0 0 1-2 0Z"
                    data-name="Path 3028"
                />
            </svg>
        </div>
    );
};
