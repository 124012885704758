import React, { Component } from 'react';
import { oauthStore } from '../store/OAuthStore';
import { getTitle, getLogo } from '../helper/helper';

/**
 * Check authentification from given url params
 */

class OAuthLogin extends Component {
    constructor(props) {
        super(props);
        oauthStore.addLoginCallback(cb => {
            if (cb.token) {
                this.props.history.push('/new'); // eslint-disable-line
            } else {
                // TODO errorhandling
            }
        });
        oauthStore.authenticate();
    }

    render() {
        return (
            <div>
                <div className="navbar">
                    <div className="logo">
                        <img src={getLogo()} alt="logo" />
                        <span
                            dangerouslySetInnerHTML={{
                                __html: getTitle(),
                            }}></span>
                    </div>
                </div>
                <div className="container">
                    Verifiziere Anmeldung und leite zur Applikation.
                </div>
            </div>
        );
    }
}

export default OAuthLogin;
