import React from 'react';

function Send() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="13.95" viewBox="0 0 16 13.95">
            <path
                d="M1.151 13.882 15.5 7.731a.823.823 0 0 0 0-1.513L1.151.068A.816.816 0 0 0 .009.816L0 4.607a.818.818 0 0 0 .716.814l11.618 1.558L.716 8.522A.831.831 0 0 0 0 9.343l.009 3.791a.816.816 0 0 0 1.142.748"
                data-name="Path 3088"
            />
        </svg>
    );
}

export default Send;
