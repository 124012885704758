import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PAINT_COLORS, PAINT_SHAPES } from '../../redux/reducers/paint';
import { dispatchChangeColor, dispatchChangeShape, dispatchDeletePaint, dispatchUndoLastPaint } from '../../redux/actions/paint';
import { Pencil } from '../Icons/Pencil';
import { Trashcan } from '../Icons/Trashcan';
import { Undo } from '../Icons/Undo';

import './PaintToolbar.scss';
import Screenshot from '../Icons/Screenshot';
import { overlayTwoImages } from '../../api/backendApi';
import { addSessionImageDispatch } from '../../redux/actions/session';
import { PHOTO_LIMIT } from '../../config';
import reduxStore from '../../redux/store';
import { store } from '../../store/DispatcherStore';
import { createKpiLog } from '../../helper/helper';

export const PaintToolbarComponent = ({ isPaintingAllowed, color, shape, photoPermission, snapshotFeature, backgroundImage, isConnected, images }) => {
    const photoLimitReached = images.length >= PHOTO_LIMIT;

    const getCanvasImage = () => {
        // getting the canvas from the dom is easier here than juggeling refs around...
        const canvas = document.querySelector('.paintCanvas__canvas');
        // canvas image needs to be png to get transparent background;
        const canvasImage = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
        return canvasImage;
    };

    const takeScreenshot = useCallback(async () => {
        const canvasImage = getCanvasImage();

        const mergedImage = await overlayTwoImages(backgroundImage, canvasImage);
        addSessionImageDispatch({...mergedImage, quality: 'standard'});

        const fileName =
            'image' +
            '_' +
            store.callerId +
            '_' +
            store.bystanderToken +
            '_' +
            reduxStore.getState().session.images[reduxStore.getState().session.images.length - 1].time +
            '.png';

        const additionalStates = {
            0: fileName,
        };

        createKpiLog('infoDrawScreenshotTaken', '', additionalStates);
    }, [backgroundImage]);

    return (
        <>
            <div className={`paint-toolbarBG ${!isConnected ? 'paint-toolbar--is-hidden' : ''}`}></div>
            <div className={`paint-toolbar ${isPaintingAllowed ? 'paint-toolbar--isPaintingAllowed' : ''} ${!isConnected ? 'paint-toolbar--is-hidden' : ''}`}>
                <div className="paint-toolbar__colors">
                    <button
                        onClick={() => {
                            if (!isPaintingAllowed) return;
                            dispatchChangeColor(PAINT_COLORS.COLOR1);
                        }}
                        className={`paint-toolbar__color1 ${color === PAINT_COLORS.COLOR1 ? 'is-active' : ''}`}></button>
                    <button
                        onClick={() => {
                            if (!isPaintingAllowed) return;
                            dispatchChangeColor(PAINT_COLORS.COLOR2);
                        }}
                        className={`paint-toolbar__color2 ${color === PAINT_COLORS.COLOR2 ? 'is-active' : ''}`}></button>
                    <button
                        onClick={() => {
                            if (!isPaintingAllowed) return;
                            dispatchChangeColor(PAINT_COLORS.COLOR3);
                        }}
                        className={`paint-toolbar__color3 ${color === PAINT_COLORS.COLOR3 ? 'is-active' : ''}`}></button>
                    <button
                        onClick={() => {
                            if (!isPaintingAllowed) return;
                            dispatchChangeColor(PAINT_COLORS.WHITE);
                        }}
                        className={`paint-toolbar__white ${color === PAINT_COLORS.WHITE ? 'is-active' : ''}`}></button>
                    <button
                        onClick={() => {
                            if (!isPaintingAllowed) return;
                            dispatchChangeColor(PAINT_COLORS.BLACK);
                        }}
                        className={`paint-toolbar__black ${color === PAINT_COLORS.BLACK ? 'is-active' : ''}`}></button>
                </div>
                <div className="paint-toolbar__shapes">
                    <button
                        onClick={() => {
                            if (!isPaintingAllowed) return;
                            dispatchChangeShape(PAINT_SHAPES.FREE);
                        }}
                        className={`paint-toolbar__freehand ${shape === PAINT_SHAPES.FREE ? 'is-active' : ''}`}>
                        <Pencil />
                    </button>
                </div>
                <div className="paint-toolbar__tools">
                    <button
                        onClick={() => {
                            if (!isPaintingAllowed) return;
                            dispatchUndoLastPaint();
                        }}
                        className="paint-toolbar__undo">
                        <Undo />
                    </button>
                    <button
                        onClick={() => {
                            if (!isPaintingAllowed) return;
                            dispatchDeletePaint();
                        }}
                        className="paint-toolbar__delete">
                        <Trashcan />
                    </button>
                    {
                        <button
                            disabled={!isPaintingAllowed}
                            className={`paint-toolbar__screenshot ${
                                photoPermission && snapshotFeature && !photoLimitReached ? 'paint-toolbar__screenshot--is-visible' : ''
                            }`}
                            onClick={() => {
                                if (!isPaintingAllowed) return;
                                takeScreenshot();
                            }}>
                            <Screenshot />
                        </button>
                    }
                </div>
            </div>
        </>
    );
};

// PropTypes for this Component
PaintToolbarComponent.propTypes = {
    isPaintingAllowed: PropTypes.bool,
    color: PropTypes.string,
    shape: PropTypes.string,
    photoPermission: PropTypes.bool,
    snapshotFeature: PropTypes.bool,
    dispatcherPaint: PropTypes.array,
    callerPaint: PropTypes.array,
    backgroundImage: PropTypes.any,
    isConnected: PropTypes.bool,
    images: PropTypes.arrayOf(PropTypes.object),
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        isPaintingAllowed: state.paint.isPaintingAllowed,
        color: state.paint.color,
        shape: state.paint.shape,
        photoPermission: state.session.photoPermission,
        snapshotFeature: state.features.snapshotFeature,
        dispatcherPaint: state.paint.dispatcherPaint,
        callerPaint: state.paint.callerPaint,
        backgroundImage: state.paint.backgroundImage,
        isConnected: state.connection.isConnected,
        images: state.session.images,
    };
};

// Connect Props and Dispatch to Component
export const PaintToolbar = connect(mapStateToProps)(PaintToolbarComponent);
