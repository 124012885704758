import { DEBUG } from '../config';
import { addLogDispatch } from '../redux/actions/logs';
import { createKpiLog } from './helper';
import { errorLog } from './logging';

/**
 * setup eventlisteners for conversation errors
 * @param {object} call
 * @param {object} store - conversation or dispatcher store
 */
export const conversationErrorHandling = (conversation, store) => {
    conversation
        .on('error', error => {
            if (DEBUG) addLogDispatch(['conversation error', error]);
        })
        .on('hangup', object => {
            if (DEBUG) addLogDispatch(['conversation hangup', object]);
            if (store && store.bystanderToken !== null) {
                const additionalStates = {
                    0: store.type,
                    1: object.from,
                    2: object.reason,
                };

                createKpiLog('warningHangup', '', additionalStates);
            } else {
                errorLog({
                    message: `conversation hangup - FROM: ${object.from} - REASON: ${object.reason}`,
                    eventId: 'CONVERSATION_HANGUP',
                    sessionId: `error|error`,
                    error: {
                        stack: 'no store available, error getting session',
                    },
                });
            }
        })
        .on('disconnectionWarning', streamInfo => {
            if (DEBUG) addLogDispatch(['conversation disconnectionWarning', streamInfo]);

            const additionalStates = {
                0: store.type,
            };

            createKpiLog('warningDisconnect', '', additionalStates);
        });
};

/**
 * setup eventlisteners for session errors
 * @param {object} session
 * @param {object} store - caller or dispatcher store
 */
export const sessionErrorHandling = (session, store) => {
    session
        .on('disconnect', () => {
            if (DEBUG) addLogDispatch(['disconnect']);
        })
        .on('reconnecting', () => {
            if (DEBUG) addLogDispatch(['reconnecting']);
        })
        .on('reconnection', () => {
            if (DEBUG) addLogDispatch(['reconnection']);
        })
        .on('error', e => {
            if (DEBUG) addLogDispatch(['session error', e]);
        });
};
