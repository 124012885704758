import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { replaceText } from '../../helper/helper';
import './TimeoutOverlay.scss';
import { connect } from 'react-redux';
import { TIME_UNTIL_INACTIVITY_ALERT } from '../../config';
import CloseSessionButton from './CloseSessionButton';

const TimeoutOverlay = ({ isConnected, texts }) => {
    const TIME_UNTIL_SESSION_ENDS = 30;
    const [secondsUntilAlertIsShown, setSecondsUntilAlertIsShown] = useState(
        TIME_UNTIL_INACTIVITY_ALERT
    );
    const [expirationAlertIsShown, setExpirationAlertIsShown] = useState(false);
    const [secondsUntilSessionEnd, setSecondsUntilSessionEnd] = useState(
        TIME_UNTIL_SESSION_ENDS
    );
    const [sessionWillEnd, setSessionWillEnd] = useState(false);

    let sessionInactivityTimer = useRef();
    let sessionExpirationTimer = useRef();
    let classes = `timeoutOverlay ${
        !isConnected && expirationAlertIsShown && 'timeoutOverlay--shown'
    }`;

    const runInactivityTimer = useCallback(() => {
        if (secondsUntilAlertIsShown > 0) {
            sessionInactivityTimer.current = setTimeout(
                () => setSecondsUntilAlertIsShown(secondsUntilAlertIsShown - 1),
                1000
            );
        }
    }, [secondsUntilAlertIsShown]);

    const runSessionExpirationTimer = useCallback(() => {
        if (secondsUntilSessionEnd > 0) {
            sessionExpirationTimer.current = setTimeout(
                () => setSecondsUntilSessionEnd(secondsUntilSessionEnd - 1),
                1000
            );
        }
    }, [secondsUntilSessionEnd]);

    const resetTimeout = () => {
        setExpirationAlertIsShown(false);
        setSecondsUntilAlertIsShown(TIME_UNTIL_INACTIVITY_ALERT);
        setSecondsUntilSessionEnd(TIME_UNTIL_SESSION_ENDS);
        clearTimeout(sessionInactivityTimer.current);
        clearTimeout(sessionExpirationTimer.current);
    };

    useEffect(() => {
        if (isConnected) {
            resetTimeout();
        }
    }, [isConnected]);

    useEffect(() => {
        if (!isConnected && !expirationAlertIsShown) {
            runInactivityTimer();
        }
    }, [isConnected, expirationAlertIsShown, runInactivityTimer]);

    useEffect(() => {
        if (secondsUntilAlertIsShown === 0) {
            setExpirationAlertIsShown(true);
        }
    }, [secondsUntilAlertIsShown]);

    useEffect(() => {
        if (!isConnected && expirationAlertIsShown && !sessionWillEnd) {
            runSessionExpirationTimer();
        }
    }, [
        expirationAlertIsShown,
        isConnected,
        runSessionExpirationTimer,
        sessionWillEnd,
    ]);

    useEffect(() => {
        if (secondsUntilSessionEnd === 0) {
            setSessionWillEnd(true);
        }
    }, [secondsUntilSessionEnd]);

    useEffect(() => {
        return () => {
            resetTimeout();
        };
    }, []);

    return (
        <div className={classes}>
            <div className="timeoutOverlay__content">
                <span>{replaceText(texts, 'sessionExpiring.header')}</span>
                <span>{replaceText(texts, 'sessionExpiring.dialogue')}</span>

                {expirationAlertIsShown && (
                    <span>{secondsUntilSessionEnd}</span>
                )}

                <div className="timeoutOverlay__buttons">
                    <button className="btn btn--primary" onClick={resetTimeout}>
                        {replaceText(texts, 'sessionExpiring.button')}
                    </button>
                </div>
                <div className="timeoutOverlay__hiddenButton">
                    {sessionWillEnd && <CloseSessionButton closeSessionClick />}
                </div>
            </div>
        </div>
    );
};

// PropTypes for this Component
TimeoutOverlay.propTypes = {
    isConnected: PropTypes.bool,
    texts: PropTypes.object,
};

const mapStateToProps = state => {
    return {
        isConnected: state.connection.isConnected,
        texts: state.texts.texts,
    };
};

export default connect(mapStateToProps)(TimeoutOverlay);
