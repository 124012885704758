import { cssVariables } from '../../themes/default';

export default function CircleWithCross() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
            <path
                fill={cssVariables.secondary}
                d="M15 0a15 15 0 1 0 15 15A14.986 14.986 0 0 0 15 0Zm6.45 21.45a1.494 1.494 0 0 1-2.115 0L15 17.115l-4.335 4.335a1.5 1.5 0 0 1-2.115-2.115L12.885 15 8.55 10.665a1.5 1.5 0 0 1 2.115-2.115L15 12.885l4.335-4.335a1.5 1.5 0 0 1 2.115 2.115L17.115 15l4.335 4.335a1.522 1.522 0 0 1 0 2.115Z"
                data-name="Path 3086"
            />
        </svg>
    );
}
