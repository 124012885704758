import store from '../store/index';
import { store as dispatcherStore } from '../../store/DispatcherStore';
import { callerStore } from '../../store/CallerStore';

import {
    ADD_POINTS_CALLER,
    ADD_POINTS_DISPATCHER,
    ALLOW_PAINTING,
    CHANGE_COLOR,
    CHANGE_SHAPE,
    DELETE_PAINT_CALLER,
    DELETE_PAINT,
    DISALLOW_PAINTING,
    RESET_PAINT,
    UNDO_LAST_PAINT,
    SET_BACKGROUND_IMAGE,
    REMOVE_BACKGROUND_IMAGE,
    SHOW_SCREENSHOT_DIALOGUE,
    HIDE_SCREENSHOT_DIALOGUE,
    SET_SCREENSHOT_DIALOGUE_CALLBACK,
    RESET_SCREENSHOT_DIALOGUE_CALLBACK,
} from './actionTypes';

const changeColor = color => {
    return { type: CHANGE_COLOR, color };
};

export const dispatchChangeColor = color => {
    store.dispatch(changeColor(color));
};

const changeShape = shape => {
    return { type: CHANGE_SHAPE, shape };
};

export const dispatchChangeShape = shape => {
    store.dispatch(changeShape(shape));
};

const addPointsDispatcher = points => {
    return { type: ADD_POINTS_DISPATCHER, points };
};

export const dispatchAddPointsDispatcher = (points) => {
    store.dispatch(addPointsDispatcher(points));
};

export const dispatchAddPointsDispatcherAndSend = (points) => {
    dispatcherStore.sendDispatcherPaintPoints(points);
    dispatchAddPointsDispatcher(points);
};

const addPointsCaller = points => {
    return { type: ADD_POINTS_CALLER, points };
};

export const dispatchAddPointsCaller = (points) => {
    store.dispatch(addPointsCaller(points));
};

export const dispatchAddPointsCallerAndSend = (points) => {
    callerStore.sendCallerPaintPoints(points);
    dispatchAddPointsCaller(points);
}

const undoLastPaint = () => {
    return { type: UNDO_LAST_PAINT };
};

export const dispatchUndoLastPaint = () => {
    dispatcherStore.sendUndoPaintPoints();
    store.dispatch(undoLastPaint());
};

const deletePaint = () => {
    return { type: DELETE_PAINT };
};

export const dispatchDeletePaint = () => {
    dispatcherStore.sendDeletePaintPoints();
    store.dispatch(deletePaint());
};

const deletePaintCaller = () => {
    return { type: DELETE_PAINT_CALLER };
};

export const dispatchDeletePaintCallerAndSend = () => {
    callerStore.sendDeletePaintPointsCaller();
    store.dispatch(deletePaintCaller());
};

export const dispatchDeletePaintCaller = () => {
    store.dispatch(deletePaintCaller());
};

const allowPainting = () => {
    return { type: ALLOW_PAINTING };
};

export const dispatchAllowPaintingCaller = () => {
    callerStore.sendAllowPainting(true);

    dispatchAllowPainting();
}

export const dispatchAllowPaintingDispatcher = () => {
    dispatcherStore.sendAllowPainting(true);
    dispatcherStore.sendAllowPaintingToConferenceUsers(true);
    dispatchAllowPainting();
}

export const dispatchAllowPainting = () => {
    dispatchDeletePaint();
    store.dispatch(allowPainting());
};

const disallowPainting = () => {
    return { type: DISALLOW_PAINTING };
};

export const dispatchDisallowPaintingCaller = () => {
    dispatchDisallowPainting();
};

export const dispatchDisallowPaintingDispatcher = () => {
    dispatcherStore.sendAllowPainting(false);
    dispatcherStore.sendAllowPaintingToConferenceUsers(false);
    dispatchDisallowPainting();
};

export const dispatchDisallowPainting = () => {
    store.dispatch(disallowPainting());
}

const resetPaint = () => {
    return { type: RESET_PAINT }
}

export const dispatchResetPaint = () => {
    store.dispatch(resetPaint());
}

const setBackgroundImage = (backgroundImage) => {
    return { type: SET_BACKGROUND_IMAGE, backgroundImage }
}

export const dispatchSetBackgroundImage = (backgroundImage) => {
    store.dispatch(setBackgroundImage(backgroundImage));
}

const removeBackgroundImage = () => {
    return { type: REMOVE_BACKGROUND_IMAGE }
}

export const dispatchRemoveBackgroundImage = () => {
    store.dispatch(removeBackgroundImage());
}

const showScreenshotDialogue = () => {
    return { type: SHOW_SCREENSHOT_DIALOGUE }
}

export const dispatchShowScreenshotDialogue = () => {
    store.dispatch(showScreenshotDialogue());
}

const hideScreenshotDialogue = () => {
    return { type: HIDE_SCREENSHOT_DIALOGUE }
}

export const dispatchHideScreenshotDialogue = () => {
    store.dispatch(hideScreenshotDialogue());
}

const setScreenshotDialogueCallback = callback => {
    return { type: SET_SCREENSHOT_DIALOGUE_CALLBACK, callback }
}

export const dispatchShowScreenshotDialogueThen = (callback) => {
    store.dispatch(showScreenshotDialogue());
    store.dispatch(setScreenshotDialogueCallback(callback));
}

const resetScreenshotDialogueCallback = () => {
    return { type: RESET_SCREENSHOT_DIALOGUE_CALLBACK }
}

export const dispatchResetDialogueCallback = () => {
    store.dispatch(resetScreenshotDialogueCallback());
    
}
