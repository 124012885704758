import { errorLog } from "../helper/logging";
import { oauthStore } from "../store/OAuthStore";
import { getData, postData } from "./common";

const getPasswordExpirationUrl = process.env.REACT_APP_PASSWORD_EXPIRATION_GET;
const sendPasswordChangeRequestUrl = process.env.REACT_APP_SEND_PASSWORD_CHANGE_REQUEST;

export const getPasswordExpiration = async () => {
    const result = await getData(getPasswordExpirationUrl, oauthStore.authToken).catch(e => {
           errorLog({
               message: 'getPasswordExpiration() error getting data from backend. - code: GT PWEXP',
               error: e,
               eventId: 'PASSWORD_EXPIRATION',
           });
    });

    return result;
}

export const sendChangePasswordRequest = async () => {
    const clientId = process.env.REACT_APP_APP_CLIENT_ID;
    const result = await postData(sendPasswordChangeRequestUrl, { clientId }, oauthStore.authToken).catch(e => {
        errorLog({
            message: 'postSendChange() error posting to backend. - code: PT PWCHG',
            error: e,
            eventId: 'PASSWORD_SEND_CHANGE',
        });
    });

    return result;
};