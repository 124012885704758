import React from 'react';

export const pointer = `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 589 416">
        <path d="M439.82 60.38C359.44-20 229.13-20 148.74 60.38L.29 210 142 352.82c84.13 84.12 220.52 84.12 304.64 0L588.71 210zM294.51 313.12a104.66 104.66 0 11104.64-104.64 104.66 104.66 0 01-104.64 104.64z" />
    </svg>
`;

function Pointer() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 589 416">
            <path d="M439.82 60.38C359.44-20 229.13-20 148.74 60.38L.29 210 142 352.82c84.13 84.12 220.52 84.12 304.64 0L588.71 210zM294.51 313.12a104.66 104.66 0 11104.64-104.64 104.66 104.66 0 01-104.64 104.64z" />
        </svg>
    );
}

export default Pointer;
