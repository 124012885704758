import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { oauthStore } from '../../store/OAuthStore';
import { getTitle, getLogo, replaceText } from '../../helper/helper';

import './LoginContainer.scss';

/**
 * Login Redirect Component
 * Redirect page requested from backend
 */

class LoginContainer extends Component {
    constructor(props) {
        super(props);
        if (!oauthStore.userName) {
            oauthStore.login();
        } else {
            this.props.history.push('/new'); // eslint-disable-line
        }
    }

    render() {
        return (
            <div className="loginContainer">
                <div className="navbar">
                    <div className="logo">
                        <img src={getLogo()} alt="logo" />
                        <span
                            dangerouslySetInnerHTML={{
                                __html: getTitle(),
                            }}></span>
                    </div>
                </div>
                <div className="Login">
                    <form>
                        <span>
                            {replaceText(this.props.texts, 'system.login')}
                        </span>
                    </form>
                </div>
            </div>
        );
    }
}
// PropTypes for this Component
LoginContainer.propTypes = {
    texts: PropTypes.any,
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        texts: state.texts.texts,
    };
};

// Connect Props and Dispatch to Component
export default connect(mapStateToProps)(LoginContainer);
