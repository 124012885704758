import { createStore } from 'redux';
import rootReducer from '../reducers/index';

const store = createStore(
    rootReducer /* preloadedState, */,
    (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__()
);

export type RootState = ReturnType<typeof rootReducer>;

export default store;
