import { cssVariables } from '../../themes/default';

export const ExclamationMark = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="23.599" viewBox="0 0 24 23.599">
            <g fill={cssVariables.secondary}>
                <path
                    d="M12 1.967A9.935 9.935 0 0 1 22.033 11.8 9.935 9.935 0 0 1 12 21.632 9.935 9.935 0 0 1 1.967 11.8 9.935 9.935 0 0 1 12 1.967M12 0A11.915 11.915 0 0 0 0 11.8a11.915 11.915 0 0 0 12 11.8 11.915 11.915 0 0 0 12-11.8A11.915 11.915 0 0 0 12 0"
                    data-name="Path 3165"
                />
                <path
                    d="M13.335 17.432a1.31 1.31 0 0 1-.069.222 1.038 1.038 0 0 1-.108.2.989.989 0 0 1-.147.177.989.989 0 0 1-.178.147.975.975 0 0 1-.2.108 1.2 1.2 0 0 1-.219.069 1.19 1.19 0 0 1-1.062-.324.989.989 0 0 1-.152-.174 1.018 1.018 0 0 1-.108-.2 1.15 1.15 0 0 1 0-.9 1.02 1.02 0 0 1 .108-.2 1 1 0 0 1 .147-.177.994.994 0 0 1 .177-.147 1.03 1.03 0 0 1 .2-.108 1.286 1.286 0 0 1 .222-.069 1.279 1.279 0 0 1 .23-.022 1.282 1.282 0 0 1 .23.022 1.2 1.2 0 0 1 .219.069.975.975 0 0 1 .2.108 1 1 0 0 1 .178.147 1 1 0 0 1 .147.177 1.039 1.039 0 0 1 .108.2 1.31 1.31 0 0 1 .069.222 1.209 1.209 0 0 1 0 .46m-1.156-3a1.109 1.109 0 0 1-1.102-1.119V6.109a1.11 1.11 0 1 1 2.219 0v7.21a1.109 1.109 0 0 1-1.109 1.11"
                    data-name="Path 3166"
                />
            </g>
        </svg>
    );
};
