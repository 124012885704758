import React from 'react'

export default function BackgroundIcon() {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" width="23.539" height="18" viewBox="0 0 23.539 18">
          <defs>
              <clipPath id="a">
                  <path fill="none" d="M0 0h23.539v18H0z" data-name="Rectangle 979" />
              </clipPath>
          </defs>
          <g fill="#fff" clipPath="url(#a)" data-name="Group 5138">
              <path d="M18.378 7.083a2.077 2.077 0 1 0-2.077-2.079 2.077 2.077 0 0 0 2.077 2.077" data-name="Path 3103" />
              <path
                  d="M21.25 0H2.289A2.273 2.273 0 0 0 0 2.25v13.5A2.272 2.272 0 0 0 2.289 18H21.25a2.272 2.272 0 0 0 2.288-2.25V2.25A2.272 2.272 0 0 0 21.25 0M1.308 2.25a.974.974 0 0 1 .981-.964H21.25a.974.974 0 0 1 .981.964v13.5a.944.944 0 0 1-.134.477l-5-5.754a.684.684 0 0 0-.994 0L13.033 14 8.462 8.159a.684.684 0 0 0-1.037 0L1.33 15.95a.919.919 0 0 1-.022-.2Z"
                  data-name="Path 3104"
              />
          </g>
      </svg>
  );
}
