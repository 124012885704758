import { logo } from 'theme';
import {
    MUTE_MIC,
    UNMUTE_MIC,
    ADD_CONVERSATION_NAME,
    ADD_STREAM_INFO,
    REMOVE_STREAM_INFO,
    SET_LEAVE_CONFERENCE,
    ADD_GOODBYE_TEXT,
    ADD_GOODBYE_LOGO,
    RESET_CONFERENCING,
} from '../actions/conferencing';

export const initialState = {
    conversationName: '',
    goodbyeText: '',
    goodbyeLogo: logo,
    isLeavingConference: false,
    micIsMuted: false,
    streamInfo: [],
};

const conferencingReducer = (state = initialState, action) => {

    if (action.type === MUTE_MIC) {
        return { ...state, micIsMuted: true };
    }

    if (action.type === UNMUTE_MIC) {
        return { ...state, micIsMuted: false };
    }

    if (action.type === ADD_CONVERSATION_NAME) {
        return {
            ...state,
            conversationName: action.conversationName,
        };
    }

    if (action.type === ADD_STREAM_INFO) {
        return {
            ...state,
            streamInfo: [...state.streamInfo, action.streamInfo],
        };
    }

    if (action.type === REMOVE_STREAM_INFO) {
        const remainingInfo = state.streamInfo.filter(stream => stream.streamId !== action.streamId);
        return { ...state, streamInfo: [...remainingInfo] };
    }

    if (action.type === SET_LEAVE_CONFERENCE) {
        return { ...state, isLeavingConference: true };
    }

    if (action.type === ADD_GOODBYE_TEXT) {
        return {
            ...state,
            goodbyeText: action.goodbyeText,
        };
    }

    if (action.type === ADD_GOODBYE_LOGO) {
        return {
            ...state,
            goodbyeLogo: {
                ...state.logo,
                logo: action.logo,
            },
        };
    }

    if (action.type === RESET_CONFERENCING) {
        return { ...initialState };
    }

    return state;
};

export default conferencingReducer;
