import { ADD_FILE_CALLER, ADD_FILE_DISPATCHER, ADD_FILE_UPLOAD_QUEUE, ADD_PREVIEW_FILE, REMOVE_FILE_CALLER, REMOVE_FILE_DISPATCHER, REMOVE_FILE_UPLOAD_QUEUE, REMOVE_PREVIEW_FILE, RESET_FILES } from "../actions/files";

export const initialState = {
    previewFile: {},
    uploadQueue: [],
    dispatcherFiles: [],
    callerFiles: [],
}

type FilesAction = {
    type: string,
    payload: any,
}

const filesReducer = (state = initialState, action: FilesAction) => {
    if (action.type === ADD_FILE_DISPATCHER) {
        state = { ...state, dispatcherFiles: [...state.dispatcherFiles, 
            action.payload]}
        return state;
    }

    if (action.type === REMOVE_FILE_DISPATCHER) {
        state = { ...state, dispatcherFiles: [] };
    }

    if (action.type === ADD_FILE_CALLER) {
        state = { ...state, callerFiles: [...state.callerFiles, 
            action.payload]}
        return state;
    }

    if (action.type === REMOVE_FILE_CALLER) {
        state = { ...state, callerFiles: [] };
    }

    if (action.type === ADD_PREVIEW_FILE) {
        state = { ...state, previewFile: action.payload }
    }

    if (action.type === REMOVE_PREVIEW_FILE) {
        state = { ...state, previewFile: {} };
    }

    if (action.type === ADD_FILE_UPLOAD_QUEUE) {
        state = { ...state, uploadQueue: [...state.uploadQueue,
            action.payload]}
            return state;
    }

    if (action.type === REMOVE_FILE_UPLOAD_QUEUE) {
        state = { ...state, uploadQueue: state.uploadQueue.filter(file => file.fileUrl !== action.payload.fileUrl)}
        return state;
    }

    if (action.type === RESET_FILES) {
        return initialState;
    }

    return state;
}

export default filesReducer;