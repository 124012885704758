import React, { useRef } from 'react'
import { ConnectedProps, connect } from 'react-redux';

import './ScreensharePanel.scss';
import NoScreenshare from '../Icons/NoScreenshare';

type ScreensharePanelReduxProps = {
    application: {
        screenshareIsActive: boolean,
    },
};

const ScreensharePanel: React.FC<ConnectedProps<typeof connector>> = ({ screenshareIsActive }) => {
    let containerRef = useRef();

    let classes = `screenshare-panel ${screenshareIsActive ? 'screenshare-panel--active' : ''}`;

    return (
        <div className={classes}>
            <div className="screenshare-panel__placeholder">
                <NoScreenshare />
            </div>
            <div className="screenshare-container__wrapper">
                <div ref={containerRef} id="screenshare-container"></div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: ScreensharePanelReduxProps) => {
    return {
        screenshareIsActive: state.application.screenshareIsActive,
    };
};

const connector = connect(mapStateToProps);
export default connector(ScreensharePanel);