import React from 'react';
import './AudioPanel.scss';

import Speaker from '../Icons/Speaker';
import { muteMicDispatcherDispatch, unmuteMicDispatcherDispatch } from '../../redux/actions/conferencing';
import { muteAudioDispatch, muteCallerMicrophoneDispatcherDispatch, unmuteAudioDispatch } from '../../redux/actions/application';
import SpeakerMuted from '../Icons/SpeakerMuted';
import Microphone from '../Icons/Microphone';
import MicrophoneMuted from '../Icons/MicrophoneMuted';
import { store as dispatcherStore } from '../../store/DispatcherStore';
import { replaceText } from '../../helper/helper';
import KickUser from '../Icons/KickUser';
import ConnectionQuality from '../Icons/ConnectionQuality';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { dispatchSetIsLeaving } from '../../redux/actions/invitedUsers';
import { MAX_CHAR_INVITED_USERNAME } from '../../config';
import clsx from 'clsx';

interface AudioPanelProps {}

export const AudioPanel: React.FC<AudioPanelProps> = () => {
    const micIsMuted = useSelector((state: RootState) => state.conferencing.micIsMuted);
    const audioStreamIsActive = useSelector((state: RootState) => state.application.audioStreamIsActive);
    const audioIsMuted = useSelector((state: RootState) => state.application.audioIsMuted);
    const callerMicIsMuted = useSelector((state: RootState) => state.application.callerMicIsMuted);
    const texts = useSelector((state: RootState) => state.texts.texts);
    const invitedUsers = useSelector((state: RootState) => state.invitedUsers);
    const connectionQuality = useSelector((state: RootState) => state.session.connectionQuality);

    const classes = `audio-panel ${!audioStreamIsActive ? 'audio-panel--isDisabled' : ''}`;

    const toggleMicrophone = () => {
        if (audioStreamIsActive) {
            if (!micIsMuted) {
                muteMicDispatcherDispatch();
            } else if (micIsMuted) {
                unmuteMicDispatcherDispatch();
            }
        }
    };

    const toggleAudio = () => {
        if (audioStreamIsActive) {
            if (audioIsMuted) {
                unmuteAudioDispatch();
            } else {
                muteAudioDispatch();
            }
        }
    };

    const toggleCallerMicrophone = () => {
        if (audioStreamIsActive) {
            if (!callerMicIsMuted) {
                muteCallerMicrophoneDispatcherDispatch();
                dispatcherStore.toggleMicrophone(true);
            }
        }
    };

    const muteUserMicrophone = conferenceUsername => {
        var contacts = dispatcherStore.connectedConversation.getContacts();
        var keys = Object.keys(contacts);

        for (const element of keys) {
            if (contacts[element].getUsername() === conferenceUsername) {
                dispatcherStore.sendMuteMicrophone(contacts[element]);
            }
        }
    };

    const kickUser = conferenceUsername => {
        var contacts = dispatcherStore.connectedConversation.getContacts();
        var keys = Object.keys(contacts);

        for (const element of keys) {
            if (contacts[element].getUsername() === conferenceUsername) {
                dispatchSetIsLeaving({ userId: contacts[element].getId() });
                
                const message = {
                    data: 'dispatcherLeft',
                };
        
                dispatcherStore.sendMessageToConferenceUser(message, contacts[element]);
            }
        }

    };

    const getConnectionQualityClass = rating => {
        let connectionQuality = '';

        if (rating.length === 0) {
            connectionQuality = '0';
        } else {
            connectionQuality = rating.substring(0, 1);
        }

        let className = 'connection-quality--' + connectionQuality;
        return className;
    };

    const getAudioIcon = user => {
        switch (true) {
            case user.isAudioMuted:
            case user.hasAudioError:
                return <MicrophoneMuted />;
            default:
                return <Microphone />;
        }
    };

    return (
        <div className={classes}>
            <div className="dispatcher-controls">
                <span>{replaceText(texts, 'audio.dispatcher.label')}</span>
                <div className="dispatcher-controls__container">
                    <div className="controls controls--audio" onClick={toggleAudio}>
                        {!audioIsMuted ? <Speaker /> : <SpeakerMuted />}
                    </div>
                    <div className="controls controls--mic" onClick={toggleMicrophone}>
                        {!micIsMuted ? <Microphone /> : <MicrophoneMuted />}
                    </div>
                </div>
            </div>
            <div className="caller-controls">
                <span>{replaceText(texts, 'audio.caller.label')}</span>
                <div className="caller-controls__container">
                    <div className="controls controls--mic" onClick={toggleCallerMicrophone}>
                        {!callerMicIsMuted ? <Microphone /> : <MicrophoneMuted />}
                    </div>
                    <div className={clsx('controls controls--connection', getConnectionQualityClass(connectionQuality))}>
                        <ConnectionQuality />
                    </div>
                </div>
            </div>
            <ul className="invited-user-controls">
                {invitedUsers.map(user => {
                    return (
                        <li key={user.userData.id} className={user.isLeaving ? 'is-leaving' : ''}>
                            <span>
                                {user.userData.username.length > MAX_CHAR_INVITED_USERNAME
                                    ? `${user.userData.username.substring(0, MAX_CHAR_INVITED_USERNAME)}...`
                                    : user.userData.username}
                            </span>
                            <div className="invited-user-controls__container">
                                <div
                                    className="controls controls--kick-user"
                                    onClick={() => {
                                        kickUser(user.userData.username);
                                    }}>
                                    <KickUser />
                                </div>
                                <div
                                    className={clsx('controls controls--mic', { 'controls--disabled': user.hasAudioError })}
                                    onClick={() => muteUserMicrophone(user.userData.username)}>
                                    {getAudioIcon(user)}
                                </div>
                                <div
                                    className={clsx(
                                        'controls controls--connection',
                                        user && user.connectionQuality && getConnectionQualityClass(user.connectionQuality)
                                    )}>
                                    <ConnectionQuality />
                                </div>
                            </div>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default AudioPanel;
