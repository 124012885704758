import React from 'react'

export default function NoMedia() {
    return (
        <svg id="noMedia" xmlns="http://www.w3.org/2000/svg" fill="#E6E7E8" viewBox="0 0 200 200">
            <path
                id="noMedia-icon"
                d="M139.85 153.47c0 2.21-1.79 4-4 4H46.71c-10.4 0-18.86-8.46-18.86-18.86V78.92c.09-2.13 1.84-3.84 4-3.84s4 1.79 4 4v59.53c0 5.99 4.87 10.86 10.86 10.86h89.14c2.21 0 4 1.79 4 4Zm42.98 29.36c-.78.78-1.8 1.17-2.83 1.17s-2.05-.39-2.83-1.17l-43.16-43.16H64.14c-10.4 0-18.86-8.46-18.86-18.86V61.38c0-3.03.74-5.89 2.01-8.43L17.16 22.82a4.008 4.008 0 0 1 0-5.66 4.008 4.008 0 0 1 5.66 0l29.52 29.52a18.75 18.75 0 0 1 11.79-4.17h20.09c.86 0 1.7.28 2.4.8l17.65 13.2h49.01c1.02 0 2.02-.02 2.98-.04 5.04-.11 9.8-.21 13.02 2.94 1.9 1.86 2.86 4.53 2.86 7.96v53.43c0 10.4-8.46 18.86-18.86 18.86h-7.96l37.5 37.5a4.008 4.008 0 0 1 0 5.66ZM58.06 52.4l33.02 33.02 1.1-1.58c.59-.86 1.57-1.37 2.61-1.37s2.02.51 2.61 1.37l12.63 18.18 9.31-13.41c.59-.86 1.57-1.37 2.61-1.37s2.02.51 2.61 1.37l16.57 23.86c.68.97.76 2.24.21 3.29a3.19 3.19 0 0 1-2.82 1.71h-15.41l14.2 14.2h15.96c5.99 0 10.86-4.87 10.86-10.86V67.38c0-1.32-.24-2.02-.46-2.24-.82-.8-4.53-.72-7.25-.66-1.02.02-2.07.04-3.15.04h-50.34c-.86 0-1.7-.28-2.4-.8l-17.65-13.2H64.12c-2.25 0-4.35.69-6.09 1.87Zm67.96 79.27-14.2-14.2H74.91a3.19 3.19 0 0 1-2.82-1.71 3.17 3.17 0 0 1 .21-3.29l14.15-20.38-32.92-32.92c-.15.71-.23 1.45-.23 2.21v59.43c0 5.99 4.87 10.86 10.86 10.86h61.87Z"

            />
        </svg>
    );
}
