import React from 'react';

export default function InactiveVideoNoEffect() {

    return (
        <svg id="BIDI" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120" fill="#dbdbdb">
            <path d="m87.5,32.73h-55c-5.5,0-10,4.5-10,10v34.54c0,5.5,4.5,10,10,10h55c5.5,0,10-4.5,10-10v-34.54c0-5.5-4.5-10-10-10Zm6,44.54c0,3.31-2.69,6-6,6h-15.5v-12.01c0-3.86-3.23-6.99-7.2-6.99h0s-9.6,0-9.6,0c-3.97,0-7.19,3.13-7.2,6.98v12.01h-15.5c-3.31,0-6-2.69-6-6v-34.54c0-3.31,2.69-6,6-6h55c3.31,0,6,2.69,6,6v34.54Z" />
            <path d="m59.92,46.21c-3.52,0-6.38,2.86-6.38,6.39h0c0,3.53,2.86,6.39,6.38,6.39h0c3.52,0,6.38-2.86,6.38-6.39s-2.86-6.39-6.38-6.39Z" />
        </svg>
    );
}
