import React from 'react';

function NoMap() {
    return (
        <svg id="noMap" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" fill="#e6e7e8">
            <path 
                id="noMap-2" 
                data-name="noMap" 
                d="m170.81,161.56l-27.94-27.94c19.84-22.8,19.01-57.69-2.53-79.46-21.63-21.86-56.61-22.68-79.24-2.31l-26.8-26.8c-1.41-1.41-3.7-1.41-5.12,0-1.41,1.41-1.41,3.7,0,5.12l26.86,26.86c-19.74,22.81-18.87,57.6,2.63,79.34l38.26,38.55c.68.68,1.6,1.07,2.57,1.07s1.89-.39,2.57-1.07l35.79-36.07,27.82,27.82c.71.71,1.63,1.06,2.56,1.06s1.85-.35,2.56-1.06c1.41-1.41,1.41-3.7,0-5.12ZM99.51,44.39c12.95,0,25.89,4.96,35.69,14.87,18.75,18.96,19.59,49.25,2.54,69.23l-15.24-15.24c4.1-5.1,6.55-11.57,6.52-18.6.06-16.33-13.17-29.66-29.53-29.73-7.06.03-13.54,2.54-18.62,6.7l-14.64-14.64c9.47-8.4,21.37-12.6,33.28-12.6Zm-18.45,37.66l31.08,31.08c-3.59,2.49-7.94,3.96-12.63,3.98-12.33-.05-22.32-10.13-22.27-22.49-.02-4.65,1.39-8.98,3.82-12.58Zm4.96-5.27c3.74-2.87,8.41-4.59,13.47-4.61,12.34.05,22.33,10.12,22.29,22.49.02,5.03-1.64,9.69-4.44,13.45l-31.32-31.32Zm13.49,90.47l-35.69-35.96c-18.71-18.92-19.59-49.13-2.64-69.11l14.69,14.69c-3.71,4.96-5.89,11.12-5.87,17.77-.06,16.33,13.17,29.66,29.5,29.72h.03c6.68-.03,12.84-2.28,17.79-6.04l15.44,15.44-33.24,33.5Z"
            />
        </svg>
    );
}

export default NoMap;
