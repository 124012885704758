import React from 'react';

export default function InactiveVideoWithBlur() {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="#dbdbdb" viewBox="0 0 120 120">
            <ellipse cx="59.92" cy="52.61" rx="6.41" ry="6.48" />
            <path d="M88.18 32.73H31.82c-5.13 0-9.32 4.23-9.32 9.43v35.67c0 5.19 4.18 9.43 9.32 9.43h56.36c5.13 0 9.32-4.23 9.32-9.43V42.16c0-5.19-4.18-9.43-9.32-9.43Zm-61.13 9.44c0-2.68 2.14-4.83 4.77-4.83h5.61L27.04 47.85v-5.68Zm-.01 12.17h.01l16.81-17.01h7.49L27.04 61.92v-7.59Zm65.91 23.5c0 2.66-2.14 4.82-4.77 4.82h-5.16l9.93-10.04v5.22Zm0-25.79L76.33 68.86c.09.56.15 1.13.15 1.71v4.65l16.47-16.67v7.59L76.6 82.69h-4.61V70.98c0-3.81-3.05-6.9-6.82-6.9H54.6c-3.77 0-6.82 3.09-6.82 6.9v11.71h-6.52l2.08-2.11v-6.51l-8.5 8.6h-3.02a4.75 4.75 0 0 1-3.45-1.5l22.34-22.6c-.32-.51-.58-1.07-.82-1.62-.09-.21-.17-.42-.26-.63-.04-.1-.05-.2-.09-.29-.25-.73-.4-1.5-.48-2.29L27.05 76.01v-7.59l30.73-31.1h7.49l-4.25 4.3c.81.09 1.57.25 2.32.5.09.02.19.05.27.09.33.12.66.25.98.41.42.2.83.42 1.21.67l5.89-5.96h7.94L69.57 47.51c.29.56.52 1.13.71 1.74.09.26.17.53.25.81.05.24.1.47.14.72.11.59.19 1.21.19 1.85v.12l15.22-15.39h2.11c1.47 0 2.78.69 3.66 1.76L70.39 60.84c1.4.69 2.64 1.65 3.62 2.84l18.96-19.19v7.59Z" />
        </svg>
    );
}
