import React from 'react';

export const StatusInterrupted = () => {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M10 20A10 10 0 1 0 0 10a10 10 0 0 0 10 10Zm0-15a1 1 0 0 1 1 1v4a1 1 0 0 1-2 0V6a1 1 0 0 1 1-1Zm-1 8h2v2H9Z" />
            </svg>
        </div>
    );
};
