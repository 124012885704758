import store from '../store/index';
import { SET_PHONENUMBERS } from './actionTypes';

/**
 * All application actions
 *
 * Actions always return the type
 * Dispatch always dispatches the action
 */

const setPhonenumbers = phonenumbers => {
    return {
        type: SET_PHONENUMBERS,
        phonenumbers,
    };
};

export const setPhonenumbersDispatch = phonenumbers => {
    store.dispatch(setPhonenumbers(phonenumbers));
};
