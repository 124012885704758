import React from 'react';

export default function Speaker() {
    return (
        <div>
            <svg id="nf-bubble" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                <g id="b1">
                    <circle id="c1" cx="8" cy="8" r="8"/>
                    <path id="d1" d="m7.13,12.29c.01.06.03.11.05.17.02.05.05.1.08.15.06.1.15.18.24.24.05.03.1.06.15.08.33.14.71.07.96-.19.04-.04.08-.08.11-.13.03-.05.06-.1.08-.15.02-.05.04-.11.05-.17.02-.11.02-.23,0-.34-.01-.06-.03-.11-.05-.17-.02-.05-.05-.1-.08-.15-.07-.1-.15-.18-.24-.24-.05-.03-.1-.06-.15-.08-.16-.07-.34-.09-.51-.05-.06.01-.11.03-.16.05-.05.02-.11.05-.15.08-.1.06-.18.15-.24.24-.03.05-.06.1-.08.15-.02.05-.04.11-.05.17-.02.11-.02.23,0,.34m.86-2.25c.46,0,.83-.37.83-.83V3.83c0-.46-.37-.83-.83-.83s-.83.37-.83.83v5.39c0,.46.37.83.83.83"/>
                </g>
            </svg>            
            <svg id="ls" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24">
            <path id="b2" d="m0,9.22v5.56c0,.77.62,1.39,1.39,1.39h4.17l4.57,4.57c.55.54,1.43.54,1.97,0,.26-.26.4-.61.4-.98V4.24c0-.77-.63-1.39-1.39-1.39-.37,0-.72.15-.98.41l-4.57,4.59H1.39c-.77,0-1.39.62-1.39,1.39Zm18.75,2.78c0-2.38-1.34-4.55-3.47-5.6v11.19c2.13-1.05,3.47-3.21,3.47-5.59ZM15.28,1.51v.28c.01.53.34.99.83,1.18,4.99,2,7.41,7.66,5.41,12.65-.99,2.47-2.95,4.43-5.41,5.42-.5.18-.83.65-.83,1.18v.28c0,.69.56,1.25,1.25,1.26.15,0,.29-.03.43-.08,6.44-2.46,9.68-9.67,7.22-16.12-1.27-3.33-3.9-5.96-7.22-7.23-.64-.24-1.36.09-1.6.73-.05.14-.08.29-.08.45Z"/>
            </svg>
            <svg id="ls-dots" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 4">
                <circle id="dot5" cx="30" cy="2" r="2"/>
                <circle id="dot4" cx="23" cy="2" r="2"/>
                <circle id="dot3" cx="16" cy="2" r="2"/>
                <circle id="dot2" cx="9" cy="2" r="2"/>
                <circle id="dot1" cx="2" cy="2" r="2"/>
            </svg>            
        </div>
    );
}