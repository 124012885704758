import React from 'react';

export default function ConnectionQuality() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13" viewBox="0 0 14 13">
            <g data-name="Icon Verbindung Experte 1" transform="translate(-300 -641)">
                <rect width="2" height="5" data-name="Rectangle 1019" rx="1" transform="translate(300 649)" />
                <rect width="2" height="7" data-name="Rectangle 1020" rx="1" transform="translate(303 647)" />
                <rect width="2" height="9" data-name="Rectangle 1021" rx="1" transform="translate(306 645)" />
                <rect width="2" height="11" data-name="Rectangle 1022" rx="1" transform="translate(309 643)" />
                <rect width="2" height="13" data-name="Rectangle 1023" rx="1" transform="translate(312 641)" />
            </g>
        </svg>
    );
}
