import React from 'react';
import { cssVariables } from '../../themes/default';

export default function Audio() {
    return (
        <svg id="audio_recording" fill={cssVariables.primary} data-name="audio_recording" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 160 120">
            <path
                id="mic_black_24dp"
                fill={cssVariables.primary}
                data-name="mic black 24dp"
                d="m80.18,69.04c6.07,0,10.99-4.83,11-10.82,0-.01,0-.02,0-.03v-21.68c0-5.99-4.92-10.84-11-10.84s-11,4.86-11,10.84v21.68c-.01,5.98,4.9,10.84,10.97,10.85.01,0,.02,0,.03,0Zm21.36-10.85c-1.78.01-3.28,1.32-3.54,3.07-1.71,9.84-11.07,16.43-20.91,14.73-7.53-1.31-13.42-7.2-14.73-14.73-.39-1.96-2.29-3.23-4.25-2.84-1.82.36-3.07,2.04-2.89,3.89,1.8,10.88,10.44,19.34,21.35,20.9v7.51c0,2,1.62,3.62,3.62,3.62s3.62-1.62,3.62-3.62v-7.51c10.92-1.56,19.56-10.01,21.36-20.89.29-1.97-1.08-3.8-3.05-4.09-.19-.03-.37-.04-.56-.04Z"
            />
            <g id="sound-links" fill={cssVariables.primary}>
                <path d="m24.63,52.38c-1.1,0-2-.9-2-2v-6c0-1.1.9-2,2-2s2,.9,2,2v6c0,1.1-.9,2-2,2Z" />
                <path d="m31.62,56.26c-1.1,0-2-.9-2-2v-15c0-1.1.9-2,2-2s2,.9,2,2v15c0,1.1-.9,2-2,2Z" />
                <path d="m38.64,54.38c-1.1,0-2-.9-2-2v-10c0-1.1.9-2,2-2s2,.9,2,2v10c0,1.1-.9,2-2,2Z" />
                <path d="m45.66,61.21c-1.1,0-2-.9-2-2v-25c0-1.1.9-2,2-2s2,.9,2,2v25c0,1.1-.9,2-2,2Z" />
                <path d="m52.69,56.26c-1.1,0-2-.9-2-2v-15c0-1.1.9-2,2-2s2,.9,2,2v15c0,1.1-.9,2-2,2Z" />
                <path d="m58.98,51.81c-1.1,0-2-.9-2-2v-6c0-1.1.9-2,2-2s2,.9,2,2v6c0,1.1-.9,2-2,2Z" />
            </g>
            <g id="sound-rechts" fill={cssVariables.primary}>
                <path d="m128.38,56.26c1.1,0,2-.9,2-2v-15c0-1.1-.9-2-2-2s-2,.9-2,2v15c0,1.1.9,2,2,2Z" />
                <path d="m121.36,54.38c1.1,0,2-.9,2-2v-10c0-1.1-.9-2-2-2s-2,.9-2,2v10c0,1.1.9,2,2,2Z" />
                <path d="m114.34,61.21c1.1,0,2-.9,2-2v-25c0-1.1-.9-2-2-2s-2,.9-2,2v25c0,1.1.9,2,2,2Z" />
                <path d="m107.31,56.26c1.1,0,2-.9,2-2v-15c0-1.1-.9-2-2-2s-2,.9-2,2v15c0,1.1.9,2,2,2Z" />
                <path d="m135.37,52.38c1.1,0,2-.9,2-2v-6c0-1.1-.9-2-2-2-1.1,0-2,.9-2,2v6c0,1.1.9,2,2,2Z" />
                <path d="m101.02,51.81c1.1,0,2-.9,2-2v-6c0-1.1-.9-2-2-2-1.1,0-2,.9-2,2v6c0,1.1.9,2,2,2Z" />
            </g>
        </svg>
    );
}
