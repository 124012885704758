import React from 'react';

export const SoundFile = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" id="Ebene_1" data-name="Ebene 1" viewBox="0 0 80 80">
            <defs>
                <style>.cls-1</style>
            </defs>
            <path
                d="M67.39 66H12.61C8.96 66 6 63.08 6 59.5v-39c0-3.58 2.97-6.5 6.61-6.5h54.78c3.65 0 6.61 2.92 6.61 6.5v39c0 3.58-2.97 6.5-6.61 6.5ZM12.61 17.71c-1.56 0-2.83 1.25-2.83 2.79v39c0 1.54 1.27 2.79 2.83 2.79h54.78c1.56 0 2.83-1.25 2.83-2.79v-39c0-1.54-1.27-2.79-2.83-2.79H12.61Z"
                className="cls-1"
            />
            <g id="sound-rechts">
                <path
                    d="M49.65 49.54c1.1 0 2-.9 2-2v-15c0-1.1-.9-2-2-2s-2 .9-2 2v15c0 1.1.9 2 2 2ZM43 47.63c1.1 0 2-.9 2-2v-10c0-1.1-.9-2-2-2s-2 .9-2 2v10c0 1.1.9 2 2 2ZM36.34 54.5c1.1 0 2-.9 2-2v-25c0-1.1-.9-2-2-2s-2 .9-2 2v25c0 1.1.9 2 2 2ZM29.69 49.54c1.1 0 2-.9 2-2v-15c0-1.1-.9-2-2-2s-2 .9-2 2v15c0 1.1.9 2 2 2ZM56.27 45.63c1.1 0 2-.9 2-2v-6c0-1.1-.9-2-2-2s-2 .9-2 2v6c0 1.1.9 2 2 2ZM23.73 45.09c1.1 0 2-.9 2-2v-6c0-1.1-.9-2-2-2s-2 .9-2 2v6c0 1.1.9 2 2 2Z"
                    className="cls-1"
                />
            </g>
        </svg>
    );
};
