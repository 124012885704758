import React from 'react';

interface MapIconProps {}

const MapIcon: React.FC<MapIconProps> = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 150">
            <path d="M0 0h200v150H0z" style={{ fill: '#f2f2f2', strokeWidth: 0 }} />
            <path
                d="M200 84.74v52.46l-5.99-8.56-2.87-4.11-4.83-6.91-20.19-9.69-28.83 8.44-1.36.39-1.35.4-9.73 2.84-42.88 4.44-28.35 6.11-5.49 1.18-16.82 3.63-28.81-3.02-2.5.15v-14.15l13.2-1.94 26.34 4.7 8.59 1.85 5.49 1.18 4.81 1.04 36.25-8.77 34.56-8.64 2.76-.69 25.07-6.27L200 84.74z"
                style={{ fill: '#8e8e8e', opacity: 0.3, strokeWidth: 0 }}
            />
            <path
                d="m126.44 6.76 6.93 18.7L74.58 40.3l-7.16-26.41 59.01-7.12Zm16.89 123.01.57 1.76.07.21 5.11 15.67.14.44-.18.98-.21 1.17H200v-12.8l-5.99-8.56-2.88-4.11-4.83-6.91-20.18-9.69-28.83 8.44-1.35.4 6.33 11.13 1.07 1.88Z"
                style={{ fill: '#bcbcbc', strokeWidth: 0 }}
            />
            <path
                d="M75.57 40.55 79.55 58 34.97 69.43l-3.29-17.74 43.89-11.14ZM187.2 0l-31.99 3.56-2.79.31-24.01 2.67-1.98.22.69 1.99 5.83 16.81 8.23 23.72.22.65 5.37 15.47.22.65 2.83 8.17.9 2.6h.02l24.04-5.75 1.34-.32-.88-2.61-2.96-8.77-.22-.65-13.43-39.82 41.36-4.95V8.29L189.7-.03h-2.51Z"
                style={{ fill: '#dbdbdb', strokeWidth: 0 }}
            />
            <path d="m110.98 85.96-66.54 16.97-.37-1.46 67.96-17.33 5.91 20.75-1.45.41-5.51-19.34z" style={{ fill: '#b3b3b3', strokeWidth: 0 }} />
            <path d="m182.44 16.27 9.32 28.45-8.29 24.11.64.22 8.34-24.22.04-.11-9.39-28.67-.66.22z" style={{ fill: '#ccc', strokeWidth: 0 }} />
            <path d="M146.04 62.11h26.63v.69h-26.63z" style={{ fill: '#ccc', strokeWidth: 0 }} transform="rotate(-14.76 159.396 62.448)" />
            <path d="m95.19 126.24.07.69 26.61-3 6.6 8.62.54-.41-6.84-8.94-26.98 3.04z" style={{ fill: '#ccc', strokeWidth: 0 }} />
            <path d="M82.3 64.33h62.16v.69H82.3z" style={{ fill: '#ccc', strokeWidth: 0 }} transform="rotate(-15.52 113.396 64.673)" />
            <path d="M80.41 57.35h62.18v.69H80.41z" style={{ fill: '#ccc', strokeWidth: 0 }} transform="rotate(-15.18 111.537 57.711)" />
            <path d="M43.23 86.97h43.32v.69H43.23z" style={{ fill: '#ccc', strokeWidth: 0 }} transform="rotate(-13.86 64.866 87.283)" />
            <path d="M29.22 36.19h43.57v.69H29.22z" style={{ fill: '#ccc', strokeWidth: 0 }} transform="rotate(-13.78 51.004 36.517)" />
            <path d="m34.84 0 8.7 19.21.63-.28L35.59 0h-.75z" style={{ fill: '#b3b3b3', strokeWidth: 0 }} />
            <path
                d="m127.62 104.92-1.22-2.14 2.39-1.36 1.23 2.17 1.98 3.48 5.29 9.3 6.38 11.22 1.24 2.18-1.01 1.75-.14.26L133.31 150h-3.16l10.02-17.46 1.59-2.77-.9-1.58-6.27-11.03-5.34-9.4-1.61-2.84Zm-74 31.3 40.3-7.98.4 9.76.17 4.17.32 7.83h2.75l-.34-8.41-.17-4.17-.43-10.65-.03-.69-.05-1.16-42.91 8.49-3.01.6.53 2.69 2.48-.49ZM8.1 10.64l3.24-1.54L30.53 0h-6.4L9.09 7.14 6.93 8.17l.64 1.34.54 1.14Zm147.74-5.19-.63-1.89-.7-2.1-.49-1.46H123.3l1.64 4.86.67 2 .67 1.99 5.29 15.65-55.29 14.3-1.99.51-1.99.52-38.33 9.91-1.69.44-1.99.52-.81.21.69 2.66.86-.22.89-.23 1.1-.29 1.68-.43 38.32-9.91 2.8-.72 1.18-.31 55.45-14.34 4.78 14.14L80.94 56l-1.77.47-2.21.58-38.21 10.01-1.66.43-.98.26-.26.07-.67.5-.4.3-1.08.8-18.68 13.83-3.84-19.82L0 45.04v5.27l8.61 14.1 4.08 21.08-3.38 8.78 2.56.99 3.38-8.77 19.26-14.26 1.48-1.1 1.09-.81.77-.2 1.65-.43 38.2-10 3.98-1.04 56.45-14.79 1.94 5.73.22.65 2.04 6.04.22.65 6.12 18.1.76 2.25 1.3-.44h.02l1.28-.44-.84-2.49-2.78-8.22-.22-.65-7.4-21.88 22.72-5.95 7.28 21.88.22.65 2.91 8.75.87 2.61.04.11 1.3-.43 1.3-.43-.83-2.5-5.76-17.31 20.38-4.22.78-.16 8-1.66v-2.8l-7.97 1.65-.67.14-21.39 4.43-9.27-27.87 22.16-2.51.69-.08L200 15.6v-2.76l-40.19 4.55-3.97-11.93Z"
                style={{ fill: '#fff', strokeWidth: 0 }}
            />
            <path
                d="M174.8 0 69.98 11l2.5-11h-3.27l-3.5 11.83-22.37 5.25-.68.16-17.32 4.07-14-12.2-1.05-.91-1.21-1.06L.9 0H0v6.5l18.76 16.35L0 27.26v4.23l21.83-5.13 11.9 43.03.79 2.83 4.99 18.03 2.48 8.97 6.15 22.24v25.63L0 145.28v4.12l9.39.35 2.74.1 3.75.14h41.47l37.14-7.83 2.72-.58 42.95-9.06 3.6-.76.21-.04 14.66-3.09H200v-4.11h-41.8l-.21.04-14.32 3.02-1.41.3-1.41.29-12.79 2.7-.74.16-30.27 6.38-2.72.58-40.7 8.58v-23.34l74.01-18.32 1.53-.38.87-.95 22.17-24.28 47.81-11.42v-5.64l-14.76 3.52-.79.19-7.85 1.88-1.35.32-1.33.32-22.73 5.43-1.36.33-.55.13-.6.66-22.45 24.59-9.14 2.26-1.47.36-20.73 5.13-4.39-16.19-.39-1.45-4.6-16.96-.18-.66-1.75-6.47-.18-.66-1.72-6.33-.72-2.65-3.94-14.54-.45-1.66-.27-.99-6.36-23.48 56.36-6.48.83-.09h.05l1.92-.22 23.97-2.75 2.78-.32 32.71-3.76 11.45 8.75V5.27L193.15.01h-18.31ZM90.98 109l-38 9-26-93 39-9 25 93Z"
                style={{ fill: '#b2b2b2', strokeWidth: 0 }}
            />
            <circle cx="100" cy="75" r="2.5" style={{ fill: 'gray;stroke', width: 0 }} />
            <circle cx="100" cy="75" r="20" style={{ fill: '#1a1a1a', opacity: 0.3, strokeWidth: 0 }} />
        </svg>
    );
};

export default MapIcon;
