import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './redux/store';
import App from './DispatcherApp';
import NotificationDisplay from './components/Globals/NotificationDisplay';

import './index.scss';
import LogView from './components/Globals/LogView';
import { DEBUG } from './config';

/**
 * Provides the redux wrapper for the whole application.
 *
 * @component NotificationDisplay - shows errors
 * @component App - application entry
 * @component LogView - shows the logs in debug mode
 */

const root = createRoot(document.getElementById('root'));

root.render(
    <Provider store={store}>
        <NotificationDisplay />
        <App />
        {DEBUG && <LogView />}
    </Provider>
);

document.getElementById('root').classList.add(process.env.REACT_APP_THEME);
