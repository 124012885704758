import {
    SET_TEXTS,
    SET_LANGUAGE,
    SET_CHAT_LABELS,
    SET_CHAT_LANGUAGE,
    SET_CALLER_LANGUAGE,
    SET_CONFERENCE_LANGUAGE,
} from '../actions/actionTypes';
import { DEFAULT_LANGUAGE } from '../../config';

export const initialState = {
    language: DEFAULT_LANGUAGE,
    texts: {},
    chatLanguage: DEFAULT_LANGUAGE,
    chatLabels: {},
    callerLanguage: DEFAULT_LANGUAGE,
    conferenceLanguage: DEFAULT_LANGUAGE,
};

const textsReducer = (state = initialState, action) => {
    if (action.type === SET_TEXTS) {
        return {
            ...state,
            texts: action.texts,
        };
    }

    if (action.type === SET_CHAT_LABELS) {
        return {
            ...state,
            chatLabels: action.chatLabels,
        };
    }

    if (action.type === SET_LANGUAGE) {
        return {
            ...state,
            language: action.language,
        };
    }

    if (action.type === SET_CHAT_LANGUAGE) {
        return {
            ...state,
            chatLanguage: action.chatLanguage,
        };
    }

    if (action.type === SET_CALLER_LANGUAGE) {
        return {
            ...state,
            callerLanguage: action.callerLanguage,
        };
    }

    if (action.type === SET_CONFERENCE_LANGUAGE) {
        return {
            ...state,
            conferenceLanguage: action.conferenceLanguage,
        };
    }

    return state;
};

export default textsReducer;
