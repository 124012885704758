import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import clsx from 'clsx';

import './SessionStatus.scss';
import { C_INIT, C_LOST, C_WAIT } from '../../config';
import { replaceText } from '../../helper/helper';
import { StatusConnected } from '../Icons/StatusConnected';
import { StatusInterrupted } from '../Icons/StatusInterrupted';
import Loading from '../Icons/LoadingSpinner';

/**
 * SessionStatus
 * Shows the current session state. init -- connected -- disconnected
 */

const SessionStatus = ({ connectionStatus, texts, connectionIsUnstable }) => {
    const [connectionInterrupted, setConnectionInterrupted] = useState(false);

    const classes = clsx('sessionStatus', {
        'sessionStatus--init': connectionStatus === C_WAIT,
        'sessionStatus--connected': connectionStatus === C_INIT && !connectionIsUnstable,
        'sessionStatus--disconnected': connectionStatus === C_LOST || (connectionStatus === C_INIT && connectionIsUnstable),
    });

    useEffect(() => {
        if (connectionStatus === C_LOST || connectionIsUnstable) {
            setConnectionInterrupted(true);
        } else {
            setConnectionInterrupted(false);
        }
    }, [connectionStatus, connectionIsUnstable]);

    return (
        <div className={classes}>
            <span>{replaceText(texts, 'session.status')}</span>
            {connectionStatus === C_WAIT && (
                <span>
                    <div title={replaceText(texts, 'session.init')}>
                        <Loading />
                    </div>
                </span>
            )}
            {connectionStatus === C_INIT && !connectionIsUnstable && (
                <span>
                    <div title={replaceText(texts, 'session.connected')}>
                        <StatusConnected />
                    </div>
                </span>
            )}
            {connectionInterrupted && (
                <span>
                    <div title={replaceText(texts, 'session.disconnected')}>
                        <StatusInterrupted />
                    </div>
                </span>
            )}
        </div>
    );
};

// PropTypes for this Component
SessionStatus.propTypes = {
    isConnected: PropTypes.bool,
    connectionStatus: PropTypes.string,
    texts: PropTypes.any,
    connectionIsUnstable: PropTypes.bool,
};

const mapStateToProps = state => {
    return {
        isConnected: state.connection.isConnected,
        connectionStatus: state.connection.status,
        texts: state.texts.texts,
        connectionIsUnstable: state.connection.connectionIsUnstable,
    };
};

export default connect(mapStateToProps)(SessionStatus);
