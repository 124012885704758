import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { store } from '../../store/DispatcherStore';
import { createKpiLog, createUUID, dragAndDropFile, replaceText } from '../../helper/helper';

import ChatMessage from './ChatMessage';
import ChatBlocks from './ChatBlocks';
import ChatLanguages from './ChatLanguages';
import NoChat from '../Icons/NoChat';

import ConnectionOverlay from './../Globals/ConnectionOverlay';
import Dropdown from './../Globals/Dropdown';
import Loading from '../Globals/Loading';
import FileInput from '../Globals/FileInput';

import './ChatView.scss';
import { translateAllChatTexts, translateMessage } from '../../helper/translationHandler';
import { errorLog } from '../../helper/logging';
import { addLogDispatch } from '../../redux/actions/logs';
import {
    DEBUG,
    POLLING_CHAT_INTERVAL,
    POLLING_CHAT_RETRY,
    DEFAULT_LANGUAGE,
    C_LOST,
    CHAT_LANGUAGES,
    FILE_UPLOAD_LIMIT,
    CHAT_HAS_STARTED_TIMEOUT,
} from '../../config';
import { getChatMessage, chatMessageConsumed, publishChatMessage } from '../../api/backendApi';
import { activateChatDispatcherDispatch } from '../../redux/actions/application';
import { addSessionChatMessageDispatch } from '../../redux/actions/session';
import Send from '../Icons/Send';
import ChatFile from './ChatFile';
import { dispatchSetFocusWindowChat } from '../../redux/actions/focus';
import { FOCUS_FEATURE_TYPE } from '../../types';

/**
 * ChatViewDispatcher
 * Shows the chat for the dispatcher.
 * Allows the change of languages, quick access to chat blocks and simple messaging.
 * Texts get translated automatically using a backend service.
 * Messages get transmitted via an established webRTC connection.
 *
 * @component Dropdown - Simple custom dropdown
 * @component ChatLanguages - Languages for the dropdown
 * @component ChatMessage - One chat message
 * @component ChatBlocks - Quick access chat blocks
 * @component Connection Overlay - shows waiting for connection until webRTC connection is established
 */

class ChatViewDispatcher extends PureComponent {
    _isMounted = false;
    _chatInterval = 0;
    _chatTimeout = 0;

    constructor(props) {
        super(props);
        this.state = {
            chatMessagesDispatcher: [],
            chatMessagesCaller: [],
            callerLanguage: DEFAULT_LANGUAGE,
            languageName: `lang.${DEFAULT_LANGUAGE}`,
            loadingTranslation: false,
            chatMessageIsInit: false,
        };

        // rtc event
        store.connectedSession.removeListener('contactMessage', this.handleContactMessage);
        store.connectedSession.on('contactMessage', this.handleContactMessage);

        this.textRef = React.createRef();
        this.messageDivRef = React.createRef();
    }

    handleContactMessage = e => {
        const message = JSON.parse(e.content);
        if (message && message.data === 'chatMessage') {
            if (this._isMounted) {
                this.addIncomingMessage(message);
            }
        }
        if (message && message.data === 'updateCallerLanguage') {
            if (this._isMounted) {
                if (CHAT_LANGUAGES[this.props.currentLanguage].indexOf(message.language) !== -1) {
                    this.updateCallerLanguage(message.language);
                } else {
                    this.updateCallerLanguage(this.props.currentLanguage);
                }
            }
        }
    };

    translateMessage = async (message, to, from) => {
        let translatedMessage = message;
        if (this.props.currentLanguage !== this.state.callerLanguage) {
            this.setState({ loadingTranslation: true });
            translatedMessage = await translateMessage(message, to, from);
            this.setState({ loadingTranslation: false });
        }

        return translatedMessage;
    };

    addIncomingMessage = async message => {
        const translatedMessage = await this.translateMessage(message, this.props.currentLanguage, this.state.callerLanguage);
        // update incoming timestamp to received time
        message.timestamp = new Date().getTime();
        this.updateMessages({ message, translatedMessage });
    };

    addNewMessage = async messageText => {
        // message on dispatcher chat
        const message = {
            chatMessage: messageText,
            originalChatMessage: messageText,
            timestamp: new Date().getTime(),
            id: `dispatcher_${createUUID()}`,
            data: 'chatMessage',
        };

        const translatedMessage = await this.translateMessage(message, this.state.callerLanguage, this.props.currentLanguage);
        this.updateMessages({ message, translatedMessage, outgoing: true });

        // send via rtc
        store.sendMessage(translatedMessage);
        // set focus
        dispatchSetFocusWindowChat();
        // activate toggle
        if (!this.props.chatIsActive) {
            activateChatDispatcherDispatch();
        }
    };

    updateMessages = async ({ message, translatedMessage, outgoing }) => {
        let callerMessage = message;
        let dispatcherMessage = translatedMessage;

        if (outgoing) {
            callerMessage = translatedMessage;
            dispatcherMessage = message;
        }

        const chatMessagesCaller = [...this.state.chatMessagesCaller, callerMessage];
        const chatMessagesDispatcher = [...this.state.chatMessagesDispatcher, dispatcherMessage];

        const chatMessage = {
            message: message.chatMessage,
            translation: translatedMessage.chatMessage,
            timestamp: message.timestamp,
            from: outgoing ? 'DISPATCHER' : 'CALLER',
        };

        let additionalStates = {};

        if (outgoing) {
            additionalStates = {
                0: this.props.currentLanguage + ' > ' + this.state.callerLanguage,
            };
        } else {
            additionalStates = {
                0: this.state.callerLanguage + ' > ' + this.props.currentLanguage,
            };
        }

        additionalStates = { ...additionalStates, 1: message.chatMessage, 2: translatedMessage.chatMessage };

        createKpiLog(outgoing ? 'msgChatSent' : 'msgChatReceived', '', additionalStates);
        if (this.state.callerLanguage !== this.props.currentLanguage) {
            createKpiLog(outgoing ? 'msgSentTranslated' : 'msgReceivedTranslated', '', additionalStates);
        }

        publishChatMessage({ ...chatMessage });

        addSessionChatMessageDispatch({ ...chatMessage });

        this.setState({
            chatMessagesCaller,
            chatMessagesDispatcher,
        });
    };

    updateCallerLanguage = async callerLanguage => {
        this.setState({ loadingTranslation: true });
        const translations = await translateAllChatTexts(
            {
                chatMessages: this.state.chatMessagesDispatcher,
            },
            callerLanguage,
            this.props.currentLanguage
        );
        this.setState({ loadingTranslation: false });

        const updatedChatTexts = {
            data: 'updateChatTexts',
            callerLanguage,
            ...translations,
        };

        if (store.translationError) {
            this.setState({
                languageName: `lang.${DEFAULT_LANGUAGE}`,
            });
        } else {
            store.sendMessage(updatedChatTexts);

            this.setState({
                callerLanguage,
                languageName: `lang.${callerLanguage}`,
            });
        }
    };

    selectLanguage = (key, label) => {
        try {
            this.setState(
                {
                    languageName: label,
                },
                () => this.sendLanguageMessage(key)
            );

            const additionalStates = {
                0: key + ' - ' + label,
            };

            createKpiLog('infoChatLanguage', '', additionalStates);
        } catch (e) {
            if (DEBUG) addLogDispatch(['selectLanguage Error', e]);
            errorLog({
                message: 'Error selecting a language.',
                error: e,
                eventId: 'LANGUAGE_SELECT',
            });
        }
    };

    sendLanguageMessage = key => {
        this.updateCallerLanguage(key);
    };

    submitChat = event => {
        event.preventDefault();
        try {
            if (JSON.stringify(this.props.previewFile) !== '{}') {
                if (this.textRef.current.value.length !== 0) {
                    store.pushFileToChat(this.textRef.current.value);
                    this.textRef.current.value = '';
                    // prevent further execution to avoid duplicate messages
                    return;
                } else {
                    store.pushFileToChat();
                }
            }
            if (this.textRef.current.value.length !== 0) {
                const messageText = this.textRef.current.value;
                this.addNewMessage(messageText);
                this.textRef.current.value = '';

                if (this.props.currentFocusFeature !== FOCUS_FEATURE_TYPE.CHAT) {
                    store.sendSetFeatureFocus(FOCUS_FEATURE_TYPE.CHAT);
                    dispatchSetFocusWindowChat();
                }
            }
        } catch (e) {
            if (DEBUG) addLogDispatch(['submitChat Error', e]);
            errorLog({
                message: 'Error submitting the chat message.',
                error: e,
                eventId: 'CHAT_ERROR',
            });
        }
    };

    componentDidMount() {
        this._isMounted = true;
        clearInterval(this._chatInterval);
        store.addNewCallCallback(this.pollChatAPI);
        store.addCloseCallCallback(() => {
            clearInterval(this._chatInterval);
        });
    }

    componentDidUpdate(prevProps) {
        this.messageDivRef.current.scrollTop = this.messageDivRef.current.scrollHeight;
        if (prevProps.uploadQueue.length !== 0 && this.props.uploadQueue !== prevProps.uploadQueue) {
            // message formatted for proper display in chat history and pdf session report
            this.addNewMessage(
                this.props.dispatcherFiles[this.props.dispatcherFiles.length - 1].url +
                    '\n' +
                    this.props.dispatcherFiles[this.props.dispatcherFiles.length - 1].description
            );
        }
        // only load chat message initialization animation when chatIsActive becomes true
        if (!this.state.chatMessageIsInit) {
            if (this.props.chatIsActive && this.props.chatIsActive !== prevProps.chatIsActive && this.props.focusChangedByCaller) {
                this.setState({
                    chatMessageIsInit: true,
                });
                // reset initializer to false after 4 second animation finishes
                setTimeout(() => {
                    this.setState({
                        chatMessageIsInit: false,
                    });
                }, CHAT_HAS_STARTED_TIMEOUT);
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        clearInterval(this._chatInterval);
        clearTimeout(this._chatTimeout);
        if (DEBUG) addLogDispatch(['polling chat api ended']);
    }

    pollChatAPI = () => {
        if (DEBUG) addLogDispatch(['polling chat api started']);
        if (this._isMounted) {
            clearInterval(this._chatInterval);
            this._chatInterval = setInterval(async () => {
                try {
                    if (store && store.phone) {
                        const response = await getChatMessage(store.phone);
                        if (response && response.length > 0) {
                            await chatMessageConsumed(store.phone);
                            if (DEBUG) addLogDispatch(['chat message received via API', response]);
                            response.forEach(chatMessage => {
                                this.addNewMessage(chatMessage);
                            });
                        }
                    }
                } catch (e) {
                    clearInterval(this._chatInterval);
                    this._chatTimeout = setTimeout(this.pollChatAPI, POLLING_CHAT_RETRY);
                }
            }, POLLING_CHAT_INTERVAL);
        }
    };

    render() {
        const messages = this.state.chatMessagesDispatcher;
        const sortedMessages = messages.sort((a, b) => {
            if (a.timestamp > b.timestamp) return 1;
            if (a.timestamp < b.timestamp) return -1;
            return 0;
        });

        const languageLabel = replaceText(this.props.texts, this.state.languageName);
        const isDisabled = !this.props.isConnected || this.props.connectionStatus === C_LOST || this.props.chatIsDisabled || !this.props.callerPageLoaded;
        const isFilePreviewModeActive = JSON.stringify(this.props.previewFile) !== '{}';
        const isFileUploadLimitExceeded = this.props.dispatcherFiles.length + this.props.callerFiles.length >= FILE_UPLOAD_LIMIT;
        const texts = this.props.texts;

        const chatViewClasses = `chatView__form ${isFilePreviewModeActive ? 'chatView__form--is-file-preview-mode-active' : ''}`;
        const initMessage = {
            timestamp: new Date().getTime(),
            id: `dispatcher_${createUUID()}`,
            chatMessage: replaceText(this.props.texts, 'chat.started'),
            originalChatMessage: replaceText(this.props.texts, 'chat.started'),
        };

        return (
            <div
                className="chatView"
                onDrop={
                    this.props.fileShareFeature && !isFilePreviewModeActive && this.props.uploadQueue.length === 0 && !isFileUploadLimitExceeded
                        ? dragAndDropFile
                        : undefined
                }>
                <ConnectionOverlay />

                <div className="chatView__languageSelect">
                    <span>{replaceText(texts, 'chat.language.caller')}</span>
                    <span>
                        <Dropdown
                            disabled={isDisabled}
                            minHeight={100}
                            maxHeight={300}
                            label={languageLabel}
                            clickHandler={this.selectLanguage}
                            id="chatViewDropDown">
                            <ChatLanguages />
                        </Dropdown>
                    </span>
                </div>

                <div className="chatView__users">
                    <div>{replaceText(texts, 'chat.caller')}</div>
                    <div>{replaceText(texts, 'chat.dispatcher')}</div>
                </div>

                {this.props.chatIsActive ? (
                    ''
                ) : (
                    <div className="chatView__noChat">
                        <NoChat />
                    </div>
                )}
                <div className="chatView__messages-wrapper">
                    <div className="chatView__messages" ref={this.messageDivRef}>
                        <div>
                            <ChatMessage
                                isInit={this.state.chatMessageIsInit}
                                currentUser="dispatcher"
                                {...initMessage}
                                showOriginal={replaceText(this.props.texts, 'showOriginal')}
                            />
                            {sortedMessages.map(message => {
                                return (
                                    <ChatMessage
                                        key={message.id}
                                        currentUser="dispatcher"
                                        {...message}
                                        showOriginal={replaceText(texts, 'chat.showOriginal')}
                                    />
                                );
                            })}
                            {this.state.loadingTranslation && <Loading text={replaceText(texts, 'chat.loadingTranslation')} />}
                        </div>
                    </div>
                </div>

                <div className="chatView__blocks">
                    <Dropdown disabled={isDisabled} minHeight={40} label={replaceText(texts, 'dropdown.chat.blocks')} clickHandler={this.addNewMessage}>
                        <ChatBlocks />
                    </Dropdown>
                </div>

                <form className={chatViewClasses} onSubmit={this.submitChat}>
                    <div className="chatViewInputWrapper">
                        {isFilePreviewModeActive && <ChatFile file={this.props.previewFile} preview />}
                        <div className="chatView__input">
                            <input
                                disabled={isDisabled}
                                type="text"
                                required={!isFilePreviewModeActive}
                                placeholder={
                                    isFilePreviewModeActive
                                        ? replaceText(texts, 'chat.fileUploaded.placeholder')
                                        : replaceText(texts, 'chat.messagePlaceholder')
                                }
                                ref={this.textRef}
                            />
                        </div>
                        {this.props.fileShareFeature && !isFilePreviewModeActive && <FileInput userType="dispatcher" />}
                        <button disabled={isDisabled} className="btn btn--primary chatView__submit" type="submit">
                            {isFilePreviewModeActive ? <Send /> : replaceText(texts, 'chat.button')}
                        </button>
                    </div>
                    <div className="chatView__label">
                        <span>{replaceText(texts, 'chat.labelHint')}</span>
                    </div>
                </form>
            </div>
        );
    }
}

// PropTypes for this Component
ChatViewDispatcher.propTypes = {
    handleLanguageChange: PropTypes.func,
    chatIsActive: PropTypes.bool,
    chatIsDisabled: PropTypes.bool,
    isConnected: PropTypes.bool,
    connectionStatus: PropTypes.string,
    texts: PropTypes.any,
    currentLanguage: PropTypes.any,
    callerPageLoaded: PropTypes.bool,
    fileShareFeature: PropTypes.bool,
    previewFile: PropTypes.object,
    dispatcherFiles: PropTypes.array,
    callerFiles: PropTypes.array,
    uploadQueue: PropTypes.array,
    currentFocusFeature: PropTypes.string,
    focusChangedByCaller: PropTypes.bool,
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        chatIsActive: state.application.chatIsActive,
        chatIsDisabled: state.application.chatIsDisabled,
        isConnected: state.connection.isConnected,
        connectionStatus: state.connection.status,
        texts: state.texts.texts,
        currentLanguage: state.texts.language,
        callerPageLoaded: state.application.callerPageLoaded,
        fileShareFeature: state.features.fileShareFeature,
        previewFile: state.files.previewFile,
        dispatcherFiles: state.files.dispatcherFiles,
        callerFiles: state.files.callerFiles,
        uploadQueue: state.files.uploadQueue,
        currentFocusFeature: state.focus.currentFocusFeature,
        focusChangedByCaller: state.focus.focusChangedByCaller,
    };
};

// Connect Props and Dispatch to Component
export default connect(mapStateToProps)(ChatViewDispatcher);
