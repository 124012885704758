import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { CHAT_TEXT_BLOCKS_DEFAULTS } from '../../config';

import './ChatBlocks.scss';
import { getChatBlocks } from '../../api/backendApi';
import { connect } from 'react-redux';

/**
 * ChatBlocks
 * Quick access chat blocks. Received from the backend and can be configurated in the admin ui.
 */
class ChatBlocks extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            blocks: CHAT_TEXT_BLOCKS_DEFAULTS[this.props.language],
        };
    }

    componentDidMount() {
        this.setBlocks();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.language !== this.props.language) {
            this.setBlocks();
        }
    }

    setBlocks = async () => {
        const blocks = await getChatBlocks();
        if (blocks.length > 0) {
            this.setState({ blocks });
        } else {
            this.setState({
                blocks: CHAT_TEXT_BLOCKS_DEFAULTS[this.props.language],
            });
        }
    };

    render() {
        return (
            <div>
                {this.state.blocks.map(block => (
                    <div
                        className="chatBlocks__block"
                        key={block + Math.random()}
                        onClick={() => this.props.clickElement(block, block)}>
                        <span>{block}</span>
                    </div>
                ))}
            </div>
        );
    }
}

// PropTypes for this Component
ChatBlocks.propTypes = {
    clickElement: PropTypes.func,
    language: PropTypes.string,
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        language: state.texts.language,
    };
};

// Connect Props and Dispatch to Component
export default connect(mapStateToProps)(ChatBlocks);
