import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import DispatcherDashboard from './components/Dispatcher/DispatcherDashboard';
import LoginContainer from './components/Login/LoginContainer';
import StartDashboard from './components/New/StartDashboard';
import { changeCSSVariablesTo, getBrowserTitle } from './helper/helper';
import OAuthLogin from './components/OAuthLogin';

import './helper/polyfills.js';
import './App.scss';
import PrivateRoute from './components/Globals/PrivateRoute';

/**
 * application entry
 * takes care of routing
 * loads external resources from apiRTC
 *
 * @component PrivateRoute - Private Route guard, checking for authentification
 * @component DispatcherDashboard - Dashboard for dispatcher
 * @component StartDashboard - Dashboard to enter the Phonenumber and download session file
 * @component LoginContainer - Redirect component before login
 * @component OAuthLogin - Redirect component after login
 */

class App extends React.Component {
    constructor() {
        super();

        document.title = getBrowserTitle();
        changeCSSVariablesTo();
    }

    render() {
        return (
            <Router>
                <PrivateRoute exact path="/" />
                <PrivateRoute path="/disptchr/:id/:session" component={DispatcherDashboard} />
                <PrivateRoute exact path="/new" component={StartDashboard} />
                <PrivateRoute exact path="/new/:phone" component={StartDashboard} />
                <Route path="/login" render={() => <LoginContainer />} />
                <Route path="/oauth" component={OAuthLogin} />
            </Router>
        );
    }
}

export default App;
