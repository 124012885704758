import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { connect } from 'react-redux';
import { hideAndRemoveNotificationsDispatch } from '../../redux/actions/notifications';

import { ERRORS_VISIBILITY, INFO_VISIBILITY, TIMESTAMP_OPTIONS, WARNING_VISIBILITY } from '../../config';
import { replaceText } from '../../helper/helper';

import './NotificationDisplay.scss';
import { setHdPhotoDisclaimerClickedDispatch } from '../../redux/actions/disclaimers';
import Loading from '../Icons/LoadingSpinner';
import { ResendSMS } from '../Dispatcher/ResendSMS';
import SendPasswordResetButton from '../Dispatcher/SendPasswordResetButton';
import { activateAudioStreamDispatcherDispatch, dispatchOpenInvitationPanel } from '../../redux/actions/application';

/**
 * Shows all error messages passed to the redux error store
 */

class NotificationDisplay extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            currentNotification: null,
        };
    }
    _timer = { info: null, error: null };

    handleNotificationUpdates(type, previousNotifications, currentNotifications, duration) {
        const previous = previousNotifications.filter(notification => notification.type === type);
        const current = currentNotifications.filter(notification => notification.type === type);

        if (JSON.stringify(previous) !== JSON.stringify(current) && current.length > 0) {
            clearTimeout(this._timer[type]);
            if (duration > 0) {
                this._timer[type] = setTimeout(() => {
                    hideAndRemoveNotificationsDispatch(type);
                }, duration);
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.currentNotifications) !== JSON.stringify(this.props.currentNotifications)) {
            this.handleNotificationUpdates('info', prevProps.currentNotifications, this.props.currentNotifications, INFO_VISIBILITY);
            this.handleNotificationUpdates('pending', prevProps.currentNotifications, this.props.currentNotifications, 0);
            this.handleNotificationUpdates('warning', prevProps.currentNotifications, this.props.currentNotifications, WARNING_VISIBILITY);
            this.handleNotificationUpdates('error', prevProps.currentNotifications, this.props.currentNotifications, ERRORS_VISIBILITY);
        }
    }

    render() {
        const infos = this.props.currentNotifications.filter(notification => notification.type === 'info');
        const warnings = this.props.currentNotifications.filter(notification => notification.type === 'warning');
        const errors = this.props.currentNotifications.filter(notification => notification.type === 'error');
        const pending = this.props.currentNotifications.filter(notification => notification.type === 'pending');

        const classes = clsx('notificationDisplay', {
            'notificationDisplay--active': this.props.showInfos || this.props.showErrors || this.props.showWarnings || this.props.showPending,
        });

        return (
            <div className={classes}>
                <Notifications texts={this.props.texts} notifications={errors} type="error" />
                <Notifications texts={this.props.texts} notifications={infos} type="hint" />
                <Notifications texts={this.props.texts} notifications={warnings} type="warning" />
                <Notifications texts={this.props.texts} notifications={pending} type="pending" />
            </div>
        );
    }
}

// PropTypes for this Component
NotificationDisplay.propTypes = {
    currentNotifications: PropTypes.arrayOf(PropTypes.any),
    showErrors: PropTypes.bool,
    showInfos: PropTypes.bool,
    showWarnings: PropTypes.bool,
    showPending: PropTypes.bool,
    texts: PropTypes.any,
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        currentNotifications: state.notifications.currentNotifications,
        showErrors: state.notifications.showErrors,
        showInfos: state.notifications.showInfos,
        showWarnings: state.notifications.showWarnings,
        showPending: state.notifications.showPending,
        texts: state.texts.texts,
    };
};

// Connect Props and Dispatch to Component
export default connect(mapStateToProps)(NotificationDisplay);

const Notifications = ({ notifications, type, texts }) => {
    const classes = clsx(`notificationDisplay__wrapper alert alert--${type}`, {
        'notificationDisplay__wrapper--inactive': notifications.length === 0,
    });

    const isLinkFoundInMessage = message => {
        if (message.indexOf('***') !== -1) {
            return true;
        }
        return false;
    };
    return (
        <div className={classes}>
            <div className="notificationDisplay__messages">
                {notifications.map(notification => {
                    return (
                        <div key={notification.id}>
                            <div className="flex__container">
                                {type !== 'warning' && type !== 'pending' && (
                                    <span>{new Date(notification.timestamp).toLocaleDateString(navigator.language, TIMESTAMP_OPTIONS)}</span>
                                )}
                                <span>{replaceText(texts, notification.message)}</span>
                            </div>
                            {notification.message === 'disclaimer.declined.caller' && <ResendSMS type="notification" />}
                            {type === 'warning' && (
                                <>
                                    {notification.message === 'warning.hdphoto.notice' && (
                                        <button
                                            key={notification.message}
                                            className="btn btn--primary btn--ok"
                                            onClick={() => {
                                                hideAndRemoveNotificationsDispatch(type);
                                                setHdPhotoDisclaimerClickedDispatch();
                                            }}>
                                            OK
                                        </button>
                                    )}
                                    {notification.message === 'warning.invite.audioDependency' && (
                                        <div key={notification.message} className="button__container">
                                            <button
                                                className="btn btn--primary"
                                                onClick={() => {
                                                    hideAndRemoveNotificationsDispatch(type);
                                                    activateAudioStreamDispatcherDispatch();
                                                    dispatchOpenInvitationPanel();
                                                }}>
                                                {replaceText(texts, 'button.accept')}
                                            </button>
                                            <button
                                                className="btn btn--primary"
                                                onClick={() => {
                                                    hideAndRemoveNotificationsDispatch(type);
                                                    dispatchOpenInvitationPanel();
                                                }}>
                                                {replaceText(texts, 'button.decline')}
                                            </button>
                                        </div>
                                    )}
                                </>
                            )}
                            {isLinkFoundInMessage(notification.message) && (
                                <div className="send-password-button">
                                    <SendPasswordResetButton />
                                </div>
                            )}
                        </div>
                    );
                })}
                {type === 'pending' && (
                    <div className="notificationDisplay__pending">
                        <Loading />
                    </div>
                )}
            </div>
            <div
                className="notificationDisplay__close"
                onClick={() => {
                    hideAndRemoveNotificationsDispatch(type);
                    notifications.map(notification => {
                        if (notification.message === 'warning.hdphoto.notice') {
                            setHdPhotoDisclaimerClickedDispatch();
                        }
                        return null;
                    });
                }}></div>
        </div>
    );
};

Notifications.propTypes = {
    notifications: PropTypes.arrayOf(PropTypes.any),
    type: PropTypes.string,
    texts: PropTypes.any,
};
