import React from 'react';

export default function KickUser() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <path
                d="M8 0a8 8 0 1 0 8 8 7.993 7.993 0 0 0-8-8Zm3.44 11.44a.8.8 0 0 1-1.128 0L8 9.128 5.688 11.44a.8.8 0 1 1-1.128-1.128L6.872 8 4.56 5.688A.8.8 0 0 1 5.688 4.56L8 6.872l2.312-2.312a.8.8 0 0 1 1.128 1.128L9.128 8l2.312 2.312a.812.812 0 0 1 0 1.128Z"
                data-name="Path 3086"
            />
        </svg>
    );
}
