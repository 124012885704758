import React from 'react'

export default function PlayIcon() {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" width="23.539" height="18" viewBox="0 0 23.539 18">
          <defs>
              <clipPath id="a">
                  <path fill="none" d="M0 0h23.539v18H0z" data-name="Rectangle 980" />
              </clipPath>
          </defs>
          <g data-name="Group 5142">
              <g fill="#fff" clipPath="url(#a)" data-name="Group 5141">
                  <path
                      d="M21.25 18H2.288A2.272 2.272 0 0 1 0 15.751V2.25A2.272 2.272 0 0 1 2.288 0H21.25a2.272 2.272 0 0 1 2.288 2.25v13.5A2.272 2.272 0 0 1 21.25 18M2.288 1.286a.974.974 0 0 0-.981.964v13.5a.974.974 0 0 0 .981.964H21.25a.974.974 0 0 0 .98-.964V2.25a.974.974 0 0 0-.98-.964Z"
                      data-name="Path 3105"
                  />
                  <path
                      d="M15.561 9.733c.744-.4.744-1.063 0-1.466l-5.4-2.927c-.745-.4-1.353.028-1.353.957v5.406c0 .929.609 1.36 1.353.956Z"
                      data-name="Path 3106"
                  />
                  <path
                      d="M9.622 12.981a.852.852 0 0 1-.5-.157 1.317 1.317 0 0 1-.468-1.121V6.297a1.318 1.318 0 0 1 .468-1.121 1.008 1.008 0 0 1 1.1.012l5.4 2.927a1.048 1.048 0 0 1 .643.885 1.048 1.048 0 0 1-.643.885l-5.4 2.925a1.271 1.271 0 0 1-.6.169m0-7.623a.579.579 0 0 0-.341.1.985.985 0 0 0-.327.835v5.406a.984.984 0 0 0 .327.835.751.751 0 0 0 .811-.031l5.4-2.926c.3-.163.473-.375.473-.581s-.172-.418-.473-.581l-5.4-2.926a1 1 0 0 0-.47-.135"
                      data-name="Path 3107"
                  />
              </g>
          </g>
      </svg>
  );
}
