import store from '../store/index';

import {
    SHOW_ERRORS,
    SHOW_INFOS,
    ADD_NOTIFICATION,
    REMOVE_NOTIFICATIONS,
    HIDE_ERRORS,
    HIDE_INFOS,
    REMOVE_ERRORS,
    REMOVE_INFOS,
    SHOW_WARNINGS,
    HIDE_WARNINGS,
    REMOVE_WARNINGS,
    SHOW_PENDING,
    HIDE_PENDING,
    REMOVE_PENDING,
} from './actionTypes.js';
import { createUUID } from '../../helper/helper';

/**
 * All notification actions
 *
 * Actions always return the type
 * Dispatch always dispatches the action
 */

const showErrorsAction = () => {
    return { type: SHOW_ERRORS };
};

const showInfosAction = () => {
    return { type: SHOW_INFOS };
};

const showWarningsAction = () => {
    return { type: SHOW_WARNINGS };
};
const showPendingAction = () => {
    return { type: SHOW_PENDING };
};

export const showNotificationDispatch = type => {
    switch (type) {
        case 'error':
            store.dispatch(showErrorsAction());
            break;
        case 'info':
            store.dispatch(showInfosAction());
            break;
        case 'warning':
            store.dispatch(showWarningsAction());
            break;
        case 'pending':
            store.dispatch(showPendingAction());
            break;
        default:
            console.log('no valid notification type');
            break;
    }
};

const addNotificationAction = notification => {
    return { type: ADD_NOTIFICATION, notification };
};

export const addNotificationDispatch = (notificationMessage, type) => {
    const notification = {
        message: notificationMessage,
        timestamp: new Date().getTime(),
        id: createUUID(),
        type,
    };
    store.dispatch(addNotificationAction(notification));
};

export const addNotificationAndShowDispatch = (notification, type, showOnlyInSession = false) => {
    if (showOnlyInSession && store.getState().conferencing.conversationName === null) {
        return;
    }
    addNotificationDispatch(notification, type);
    showNotificationDispatch(type);
};

const hideErrorsAction = () => {
    return { type: HIDE_ERRORS };
};

export const hideErrorsDispatch = () => {
    store.dispatch(hideErrorsAction());
};

const hideInfosAction = () => {
    return { type: HIDE_INFOS };
};

export const hideInfosDispatch = () => {
    store.dispatch(hideInfosAction());
};

const hideWarningsAction = () => {
    return { type: HIDE_WARNINGS };
};

export const hideWarningsDispatch = () => {
    store.dispatch(hideWarningsAction());
};

const hidePendingAction = () => {
    return { type: HIDE_PENDING };
};

export const hidePendingDispatch = () => {
    store.dispatch(hidePendingAction());
};

export const hideNotificationDispatch = type => {
    switch (type) {
        case 'error':
            store.dispatch(hideErrorsAction());
            break;
        case 'info':
            store.dispatch(hideInfosAction());
            break;
        case 'warning':
            store.dispatch(hideWarningsAction());
            break;
        case 'pending':
            store.dispatch(hidePendingAction());
            break;
        default:
            store.dispatch(hideErrorsAction());
            store.dispatch(hideInfosAction());
            store.dispatch(hideWarningsAction());
            store.dispatch(hidePendingAction());
            break;
    }
};

const removeNotificationsAction = () => {
    return { type: REMOVE_NOTIFICATIONS };
};

const removeErrorsAction = () => {
    return { type: REMOVE_ERRORS };
};

const removeInfosAction = () => {
    return { type: REMOVE_INFOS };
};

const removeWarningsAction = () => {
    return { type: REMOVE_WARNINGS };
};

const removePendingAction = () => {
    return { type: REMOVE_PENDING };
};

export const removeNotificationsDispatch = type => {
    switch (type) {
        case 'error':
            store.dispatch(removeErrorsAction());
            break;
        case 'info':
            store.dispatch(removeInfosAction());
            break;
        case 'warning':
            store.dispatch(removeWarningsAction());
            break;
        case 'pending':
            store.dispatch(removePendingAction());
            break;
        default:
            store.dispatch(removeNotificationsAction());
            break;
    }
};

export const hideAndRemoveNotificationsDispatch = type => {
    removeNotificationsDispatch(type);
    hideNotificationDispatch(type);
};
