import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import './LogView.scss';
import { TIMESTAMP_OPTIONS } from '../../config';

/**
 * Shows all logs passed to the log redux store.
 * Only shown if Application is in Debug Mode
 */
class LogView extends PureComponent {
    render() {
        const logs = this.props.logs;

        return (
            <div className="logView">
                <div className="logView__show">APPLIKATIONS LOG</div>
                <div className="logView__list">
                    {logs.map((log, i) => (
                        <LogEntry
                            key={log.timestamp + '' + i}
                            timestamp={log.timestamp}
                            message={log.logMessage}
                        />
                    ))}
                </div>
            </div>
        );
    }
}

// PropTypes for this Component
LogView.propTypes = {
    logs: PropTypes.arrayOf(PropTypes.any),
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        logs: state.logs,
    };
};

/**
 * Single Log Entry
 */

class LogEntry extends PureComponent {
    state = {
        objectOpen: false,
    };

    toggle = () => {
        this.setState({ objectOpen: !this.state.objectOpen });
    };

    render() {
        const message = this.props.message;
        const timestamp = this.props.timestamp;

        return (
            <div className="logView__entry">
                <span>
                    {new Date(timestamp).toLocaleDateString(
                        navigator.language,
                        TIMESTAMP_OPTIONS
                    )}
                </span>
                {message.map((entry, i) => {
                    let classes = 'logView__object';
                    switch (typeof entry) {
                        case 'object':
                            return (
                                <span
                                    key={i}
                                    className={classes}
                                    onClick={this.toggle}>
                                    {this.state.objectOpen ? (
                                        <pre>
                                            {JSON.stringify(entry, null, 2)}
                                        </pre>
                                    ) : (
                                        '{...}'
                                    )}
                                </span>
                            );
                        case 'string':
                            if (entry.toLowerCase().indexOf('error') !== -1)
                                classes += ' logView__object--error';
                            return (
                                <span className={classes} key={i}>
                                    {entry}
                                </span>
                            );
                        default:
                            return (
                                <span className={classes} key={i}>
                                    {entry}
                                </span>
                            );
                    }
                })}
            </div>
        );
    }
}

LogEntry.propTypes = {
    message: PropTypes.arrayOf(PropTypes.any),
    timestamp: PropTypes.number,
};

// Connect Props and Dispatch to Component
export default connect(mapStateToProps)(LogView);
