import React from 'react';

function NoChat() {
    return (             
        <svg id="noChat" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" fill="#e6e7e8">
                <path 
                    id="noChat-2" 
                    data-name="noChat" 
                    d="m190.74,52.35c-2.21-.92-4.73-.42-6.41,1.27l-18.69,18.64h-30.28v-7.28c-.01-10.71-8.76-19.44-19.5-19.45H48.98l-20.47-20.47c-1.41-1.41-3.71-1.41-5.12,0-1.41,1.41-1.41,3.7,0,5.12l15.35,15.35h-13.62c-10.74.01-19.48,8.74-19.49,19.45v74.65c0,2.38,1.43,4.51,3.63,5.43h0c.73.3,1.5.45,2.26.45,1.53,0,3.03-.6,4.15-1.72l18.69-18.64h30.28v7.28c.01,10.71,8.76,19.44,19.5,19.45h60.94l23.07,23.07c.71.71,1.63,1.06,2.56,1.06s1.85-.35,2.56-1.06c1.41-1.41,1.41-3.7,0-5.12l-17.95-17.95h19.56c10.74-.01,19.48-8.74,19.49-19.45V57.77c0-2.38-1.43-4.51-3.63-5.43Zm-74.89.42c6.75,0,12.26,5.49,12.26,12.21v7.27h-43.98c-2.5,0-4.88.49-7.08,1.36l-20.84-20.84h59.64Zm4.6,64.24l-37.45-37.45c.38-.04.76-.06,1.14-.06h43.98v26.2c0,5.11-3.18,9.49-7.66,11.31Zm-44.55-34.31l35.21,35.21h-39.24v-26.2c0-3.57,1.56-6.78,4.03-9.01Zm-44.54,35.21l-18.51,18.45v-71.38c0-6.73,5.51-12.21,12.26-12.22h20.86l24.81,24.81c-3.77,3.55-6.14,8.56-6.15,14.12v26.2H31.37Zm52.78,26.72c-6.75,0-12.26-5.49-12.26-12.22v-7.27h43.98c.79,0,1.57-.06,2.34-.16l19.64,19.64h-53.7Zm102.99-12.22c0,6.73-5.51,12.21-12.26,12.22h-26.79l-22.26-22.26c5.69-3.4,9.53-9.6,9.53-16.68v-26.2h33.27l18.51-18.46v71.38Z"
                />
        </svg>
    );
}

export default NoChat;
