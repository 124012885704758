import React, { PureComponent } from 'react';

class Contacs extends PureComponent {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120">
                <path d="M81 24H38c-3 0-6 3-6 6v8h-5a3 3 0 0 0 0 6h5v9h-5a3 3 0 0 0 0 6h5v9h-5a3 3 0 0 0 0 6h5v8h-5a3 3 0 0 0 0 6h5v8c0 4 3 6 6 6h43c4 0 7-2 7-6V30c0-3-3-6-7-6ZM60 41a8 8 0 1 1-9 8 8 8 0 0 1 9-8Zm15 43a1 1 0 0 1-1 1H46a1 1 0 0 1-1-1V70a9 9 0 0 1 9-9h12a9 9 0 0 1 9 9Z" />
            </svg>
        );
    }
}

// PropTypes for this Component
Contacs.propTypes = {};

export default Contacs;
