import React, { Children, isValidElement, cloneElement, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import FocusInactive from '../Icons/FocusInactive';
import FocusActive from '../Icons/FocusActive';
import { connect } from 'react-redux';

/**
 * GridBox
 * Container for multiple components.
 * Allows the container to open and close.
 */
const GridBox = ({
    alwaysOpened,
    hideCloseIcon = false,
    openOnFeatureActivation,
    isClosed,
    children,
    addClass,
    isContainer,
    headline,
    isFocusable = false,
    isInFocus = false,
    changeFocus = () => {},
    toggleHandler = () => {},
    isConnected,
    connectionStatus,
    focusControlsAreActive,
    focusChangedByCaller,
    isRemovable = false,
    removeHandler = () => {},
}) => {
    const [gridboxIsClosed, setGridboxIsClosed] = useState(isClosed);
    const [openedWhileFeatureActivated, setOpenedWhileFeatureActivated] = useState(false);
    const [isConnectionEstablished, setIsConnectionEstablished] = useState(false);

    const classes = clsx(addClass, {
        gridContainer: isContainer,
        'gridContainer--closed': gridboxIsClosed,
        'gridContainer--alwaysOpened': alwaysOpened,
        'gridContainer--isFocusable': isFocusable,
        'gridContainer--isRemovable': isRemovable,
        'gridContainer--hideCloseIcon': hideCloseIcon,
    });

    const focusWrapperClasses = `focus-wrapper ${isConnectionEstablished && focusControlsAreActive ? '' : 'focus-wrapper--isDisabled'}`;

    const focusActiveClasses = `focus-active__container ${focusChangedByCaller ? 'focus-active__container--changed-by-caller' : ''}`;

    const childrenWithProps = Children.map(children, child => {
        if (isValidElement(child)) {
            return cloneElement(child);
        }

        return child;
    });

    const toggle = useCallback(() => {
        if (isRemovable) {
            removeHandler();
            return;
        }
        if (!alwaysOpened) {
            setGridboxIsClosed(!gridboxIsClosed);
            toggleHandler(gridboxIsClosed);
        }
    }, [gridboxIsClosed, toggleHandler, alwaysOpened, removeHandler, isRemovable]);

    useEffect(() => {
        const openGridboxIfClosed = () => {
            if (openOnFeatureActivation && !openedWhileFeatureActivated) {
                if (gridboxIsClosed) {
                    toggle();
                }
                setOpenedWhileFeatureActivated(true);
            } else if (!openOnFeatureActivation && openedWhileFeatureActivated) {
                setOpenedWhileFeatureActivated(false);
            }
        };

        const keepGridBoxOpened = () => {
            if (alwaysOpened && gridboxIsClosed) {
                setGridboxIsClosed(false);
            }
        };

        if (openOnFeatureActivation !== undefined) {
            openGridboxIfClosed();
        }

        if (alwaysOpened !== undefined) {
            keepGridBoxOpened();
        }
    }, [openOnFeatureActivation, gridboxIsClosed, openedWhileFeatureActivated, toggle, alwaysOpened]);

    useEffect(() => {
        if (isConnected && connectionStatus === 'connection_established') {
            setIsConnectionEstablished(true);
        } else {
            setIsConnectionEstablished(false);
        }
    }, [isConnected, connectionStatus]);

    return (
        <div className={classes}>
            {isContainer && headline ? (
                <>
                    <header onClick={toggle}>
                        {headline.toUpperCase()}
                        <div className={focusWrapperClasses}>
                            {isFocusable && !isInFocus ? (
                                <div
                                    className="focus-inactive__container"
                                    onClick={e => {
                                        // Prevent toggling window
                                        e.stopPropagation();
                                        changeFocus();
                                    }}>
                                    <FocusInactive />
                                </div>
                            ) : (
                                ''
                            )}
                            {isFocusable && isInFocus ? (
                                <div
                                    className={focusActiveClasses}
                                    onClick={e => {
                                        // Prevent toggling window
                                        e.stopPropagation();
                                    }}>
                                    <FocusActive />
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    </header>
                </>
            ) : (
                ''
            )}
            <main>{childrenWithProps}</main>
        </div>
    );
};

// PropTypes for this Component
GridBox.propTypes = {
    toggleHandler: PropTypes.func,
    headline: PropTypes.string,
    isContainer: PropTypes.bool,
    addClass: PropTypes.string,
    children: PropTypes.any,
    isClosed: PropTypes.bool,
    openOnFeatureActivation: PropTypes.bool,
    alwaysOpened: PropTypes.bool,
    isFocusable: PropTypes.bool,
    isInFocus: PropTypes.bool,
    changeFocus: PropTypes.func,
    isConnected: PropTypes.bool,
    connectionStatus: PropTypes.string,
    focusControlsAreActive: PropTypes.bool,
    focusChangedByCaller: PropTypes.bool,
    isRemovable: PropTypes.bool,
    removeHandler: PropTypes.func,
    hideCloseIcon: PropTypes.bool,
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        isConnected: state.connection.isConnected,
        connectionStatus: state.connection.status,
        focusControlsAreActive: state.focus.focusControlsAreActive,
        focusChangedByCaller: state.focus.focusChangedByCaller,
    };
};

// Connect Props and Dispatch to Component
export default connect(mapStateToProps)(GridBox);
