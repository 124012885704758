import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';

import { isValidPhone, replaceText } from '../../helper/helper';

import { sendGEOSMSAPI } from '../../api/backendApi';
import { GEOSMS_SUCCESS_TIMEOUT, PLACEHOLDER_COUNTY_CODE } from '../../config';

import './GeoSMS.scss';
import { Phonebook } from './Phonebook';
import { PhoneCountry } from '../Globals/PhoneCountry';

/**
 * GeoSMS
 * GeoSMS overlay to send a geosms containg the caller coordinations.
 * Phone number input with sms sending handling.
 */
class GeoSMS extends PureComponent {
    inputRef = React.createRef();

    initialState = {
        hasError: false,
        isValidPhone: false,
        isSending: false,
        sent: false,
        phone: '',
    };

    state = {
        ...this.initialState,
    };

    resetState = () => {
        this.setState({ ...this.initialState });
    };

    checkInput = e => {
        const input = this.inputRef.current;

        this.setState({ isValidPhone: isValidPhone(input.value) });
    };

    sendSMS = async e => {
        try {
            e.preventDefault();
            const input = this.inputRef.current;

            this.setState({
                isSending: true,
            });

            const sms = await sendGEOSMSAPI({
                phone: input.value,
                lat: this.props.lat,
                long: this.props.long,
            });

            if (!sms) {
                this.setState({
                    isSending: false,
                    hasError: true,
                });

                return;
            } else {
                this.setState({
                    isSending: false,
                    sent: true,
                    hasError: false,
                });

                setTimeout(() => {
                    input.value = '';
                    this.props.closeHandler();
                    this.resetState();
                }, GEOSMS_SUCCESS_TIMEOUT);
            }
        } catch (error) {
            this.setState(this.setState({ isSending: false, hasError: true }));
        }
    };

    selectAddress = phone => {
        this.inputRef.current.value = phone;
        this.setState({ phone: phone });
        this.checkInput();
    };

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value,
        });
        this.checkInput();
    };

    render() {
        const classes = clsx('geoSMS', {
            'geoSMS--open': this.props.open,
            'geoSMS--error': this.state.hasError,
            'geoSMS--isSending': this.state.isSending,
        });

        const getButtonText = () => {
            switch (true) {
                case this.state.isSending:
                    return replaceText(this.props.texts, 'geoSMS.sending');
                case this.state.sent:
                    return replaceText(this.props.texts, 'geoSMS.sent');
                default:
                    return replaceText(this.props.texts, 'geoSMS.send');
            }
        };

        return (
            <div className={classes}>
                <div className="geoSMS__wrapper">
                    <div
                        className="geoSMS__close"
                        onClick={this.props.closeHandler}>
                        {replaceText(this.props.texts, 'geoSMS.close')}
                    </div>
                    <div>
                        <span>
                            {replaceText(this.props.texts, 'info.lat')}:
                        </span>{' '}
                        {this.props.lat}
                    </div>
                    <div>
                        <span>
                            {replaceText(this.props.texts, 'info.long')}:
                        </span>{' '}
                        {this.props.long}
                    </div>
                    <form>
                        <div>
                            <label className="flex">
                                {replaceText(this.props.texts, 'new.phone')}
                                <PhoneCountry phone={this.state.phone} />
                            </label>
                            <div className="geoSMS__input-wrapper">
                                {this.props.phonebookFeature && (
                                    <Phonebook
                                        clickHandler={this.selectAddress}
                                    />
                                )}
                                <input
                                    id="phone"
                                    type="text"
                                    ref={this.inputRef}
                                    placeholder={`+${PLACEHOLDER_COUNTY_CODE}... ${replaceText(this.props.texts, 'new.phone.seperator')} 00${PLACEHOLDER_COUNTY_CODE}...`}
                                    value={this.state.phone}
                                    onChange={this.handleChange}
                                    onKeyUp={this.checkInput}
                                />
                            </div>
                            <p className="geoSMS__errorMsg alert alert--error">
                                {replaceText(
                                    this.props.texts,
                                    'error.snd_geo_sms'
                                )}
                            </p>
                            {this.state.sent && (
                                <p className="geoSMS__success alert alert--success">
                                    {replaceText(
                                        this.props.texts,
                                        'geoSMS.success'
                                    )}
                                </p>
                            )}
                        </div>

                        <div>
                            <button
                                disabled={
                                    !this.state.isValidPhone ||
                                    this.state.isSending ||
                                    this.state.sent
                                }
                                className="btn btn--primary btn--full"
                                onClick={this.sendSMS}>
                                {getButtonText()}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

GeoSMS.propTypes = {
    lat: PropTypes.string,
    long: PropTypes.string,
    open: PropTypes.bool,
    closeHandler: PropTypes.func,
    texts: PropTypes.any,
    phonebookFeature: PropTypes.bool,
};

const mapStateToProps = state => {
    return {
        texts: state.texts.texts,
        phonebookFeature: state.features.phonebookFeature,
    };
};

export default connect(mapStateToProps)(GeoSMS);
