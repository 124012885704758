import React from 'react';
import PropTypes from 'prop-types';

function ArrowDown(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={props.addClass}
            viewBox="0 0 24 24">
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
        </svg>
    );
}

ArrowDown.propTypes = {
    addClass: PropTypes.string,
};

export default ArrowDown;
