import React from 'react';

export default function ActiveVideoWithImage() {
    const theme = require('../../themes/' + process.env.REACT_APP_THEME);

    return (
        <svg id="BIDI" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120" fill={theme.cssVariables.primary}>
            <path d="m90.56,39.35h-16.11c-.46,0-.83.37-.83.82v11.47c0,.06,0,.12.02.17l5.18-6.62c.21-.27.67-.27.88,0l3.88,4.96,2.61-3c.21-.24.63-.24.84,0l4.25,4.89c.07-.12.11-.26.11-.41v-11.47c0-.45-.37-.82-.83-.82Zm-2.44,4.93c-.97,0-1.76-.79-1.76-1.76s.79-1.76,1.76-1.76,1.76.79,1.76,1.76-.79,1.76-1.76,1.76Z" />
            <path d="m88.57,32.73H31.43c-4.91,0-8.93,4.02-8.93,8.94v36.66c0,4.92,4.02,8.94,8.93,8.94h57.14c4.91,0,8.93-4.02,8.93-8.94v-36.66c0-4.92-4.02-8.94-8.93-8.94Zm-35.03,19.78c0-3.53,2.86-6.39,6.38-6.39s6.38,2.86,6.38,6.39-2.86,6.39-6.38,6.39h0c-3.52,0-6.38-2.86-6.38-6.39h0Zm18.46,28.79v1.4h-24v-11.53c0-3.86,3.23-6.98,7.2-6.98h9.6c3.97,0,7.2,3.12,7.2,6.98v10.13Zm20.5-29.66c0,1.05-.87,1.91-1.94,1.91h-16.11c-1.07,0-1.94-.86-1.94-1.91v-11.47c0-1.05.87-1.91,1.94-1.91h16.11c1.07,0,1.94.86,1.94,1.91v11.47Z" />
        </svg>
    );
}
