import { SET_IS_IN_LANDSCAPE_MODE, SET_IS_IN_PORTRAIT_MODE, SET_MOBILE_BREAKPOINT_REACHED, UNSET_MOBILE_BREAKPOINT_REACHED } from '../actions/deviceScreen';

export const initialState = {
    isInLandscapeMode: false,
    isMobileBreakpointReached: false,
};

type DeviceScreenAction = {
    type: string;
};

const deviceScreenReducer = (state = initialState, action: DeviceScreenAction) => {
    switch (action.type) {
        case SET_IS_IN_PORTRAIT_MODE:
            return {
                ...state,
                isInLandscapeMode: false,
            };
        case SET_IS_IN_LANDSCAPE_MODE:
            return {
                ...state,
                isInLandscapeMode: true,
            };
        case SET_MOBILE_BREAKPOINT_REACHED:
            return {
                ...state,
                isMobileBreakpointReached: true,
            };
        case UNSET_MOBILE_BREAKPOINT_REACHED:
            return {
                ...state,
                isMobileBreakpointReached: false,
            };
        default:
            return state;
    }
};

export default deviceScreenReducer;
