import React from 'react';

function Screenshot() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120">
            <defs />
            <circle
                cx="60"
                cy="62.8"
                r="11.2"
                transform="translate(-40 -39.568) scale(1.66667)"
            />
            <path d="M108 20.433H89l-7.5-7.5a12.488 12.488 0 00-8.833-3.667H47.334c-3.334 0-6.5 1.333-8.834 3.667l-7.5 7.5H12C5.5 20.266.167 25.266 0 31.599v66.667c.167 6.333 5.5 11.333 12 11.167h96c6.5.166 11.834-4.834 12-11.167V31.6c-.166-6.333-5.5-11.333-12-11.166zM60 93.099c-15.333 0-28-12.5-28-28 0-15.333 12.5-28 28-28 15.334 0 28 12.5 28 28 0 15.334-12.666 28-28 28z" />
        </svg>
    );
}

export default Screenshot;
