import { b64DecodeUnicode, hashFnv32a } from '../helper/helper';
import { getLoginEndpoint, getTokenRefresh } from '../api/backendApi';
import {
    DEBUG,
    TOKEN_EXPIRE_INTERVAL,
    TOKEN_EXPIRE_REFRESH_IN_S,
} from '../config';

/**
 * OAuthStore
 * handle the authentication of the application
 */

class OAuthStore {
    userName;
    userId;
    name;
    authToken;
    organizationName;
    loginCallback = [];

    /**
     * check if user is logged in correctly
     * @returns {boolean}
     */
    loggedIn = () => {
        const authentification = JSON.parse(
            sessionStorage.getItem('authentification')
        );

        const now = Math.round(new Date().getTime() / 1000);

        if (authentification) {
            this.userName = authentification.userName;
            this.name = authentification.name;
            this.userId = authentification.userId;
            this.authToken = authentification.authToken;
            this.organizationName = authentification.organizationName;

            if (DEBUG) console.log('authentification', authentification);
        }

        if (this.userName && this.authToken) {
            if (DEBUG) console.log('authToken', this.authToken);
            const expire = JSON.parse(atob(this.authToken.split('.')[1]))[
                'exp'
            ];

            this.expireDateInterval();

            if (now > expire) {
                this.logout();
            } else return true;
        }
        return false;
    };

    /**
     * setup interval to check if token is expired
     */
    expireDateInterval() {
        clearInterval(this.expireInterval);
        this.checkExpireDate();
        this.expireInterval = setInterval(() => {
            this.checkExpireDate();
        }, TOKEN_EXPIRE_INTERVAL);
    }

    /**
     * check if the token is past the expired date
     */
    async checkExpireDate() {
        const now = Math.round(new Date().getTime() / 1000);
        const expire = JSON.parse(atob(this.authToken.split('.')[1]))['exp'];

        if (expire - now <= TOKEN_EXPIRE_REFRESH_IN_S) {
            let tokenData = await getTokenRefresh();
            this.authToken = tokenData.token;
            this.saveAuthentication();

            if (DEBUG)
                console.log(
                    `Token refreshed - valid until ${
                        JSON.parse(atob(this.authToken.split('.')[1]))['exp']
                    }`
                );
        } else {
            if (DEBUG)
                console.log(
                    `Token longer than ${TOKEN_EXPIRE_REFRESH_IN_S} seconds valid, no need to refresh`
                );
        }
    }

    /**
     * redirect to login
     */
    async login() {
        window.location = await getLoginEndpoint();
    }

    /**
     * save the current authentification in session storage
     */
    saveAuthentication() {
        this.authSession = hashFnv32a(this.authToken, true);

        const authentification = {
            userName: this.userName,
            name: this.name,
            userId: this.userId,
            authToken: this.authToken,
            authSession: this.authSession,
            organizationName: this.organizationName,
        };

        sessionStorage.setItem(
            'authentification',
            JSON.stringify(authentification)
        );
    }

    /**
     * authenticate the current user
     */
    authenticate() {
        this.userName = this.getQueryVariable('email');
        this.name = this.getQueryVariable('name');
        this.userId = this.getQueryVariable('id');
        this.authToken = this.getQueryVariable('token');
        this.organizationName = JSON.parse(
            b64DecodeUnicode(this.authToken.split('.')[1])
        )['organization-name'];

        this.saveAuthentication();

        const data = { userName: this.userName, token: this.authToken };
        const parent = this;
        for (let i = 0; i < parent.loginCallback.length; i++) {
            if (typeof parent.loginCallback[i] == 'function') {
                parent.loginCallback[i](data);
            }
        }
    }

    /**
     * get a variable from the url
     * TODO UNIFY WITH HELPER
     * @param {string} variable key
     */
    getQueryVariable(variable) {
        var query = window.location.search.substring(1);
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            if (pair[0] === variable) {
                return pair[1];
            }
        }
        return false;
    }

    /**
     * logout from the application
     */
    logout() {
        this.userName = null;
        this.name = null;
        this.userId = null;
        this.authToken = null;
        this.organizationName = null;

        sessionStorage.removeItem('authentification');
    }

    /**
     * add a callback to the login event
     * @param {function} callback
     */
    addLoginCallback(callback) {
        this.loginCallback.push(callback);
    }
}

export let oauthStore = new OAuthStore();
