import React from 'react';

function FocusInactive() {
    return (
        <svg className="focus-inactive" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
            <g data-name="Fokus Icon">
                <g fill="none" stroke="#fff" strokeWidth="1.5px" data-name="Ellipse 304">
                    {/* <circle cx="13" cy="13" r="13" stroke="none" /> */}
                    {/* <circle cx="13" cy="13" r="12.5" /> */}
                </g>
                <path
                    fill="#fff"
                    d="M13 16.818a3.55 3.55 0 0 0 2.608-1.074 3.55 3.55 0 0 0 1.074-2.608 3.55 3.55 0 0 0-1.074-2.608A3.551 3.551 0 0 0 13 9.455a3.551 3.551 0 0 0-2.608 1.074 3.55 3.55 0 0 0-1.074 2.608 3.55 3.55 0 0 0 1.074 2.608A3.55 3.55 0 0 0 13 16.818Zm0-1.473a2.13 2.13 0 0 1-1.565-.644 2.13 2.13 0 0 1-.644-1.565 2.13 2.13 0 0 1 .644-1.565A2.13 2.13 0 0 1 13 10.927a2.13 2.13 0 0 1 1.565.644 2.131 2.131 0 0 1 .644 1.565 2.13 2.13 0 0 1-.644 1.565 2.13 2.13 0 0 1-1.565.644Zm0 3.927a9.473 9.473 0 0 1-5.441-1.667A9.476 9.476 0 0 1 4 13.136a9.477 9.477 0 0 1 3.559-4.469A9.472 9.472 0 0 1 13 7a9.472 9.472 0 0 1 5.441 1.667A9.476 9.476 0 0 1 22 13.136a9.476 9.476 0 0 1-3.559 4.469A9.473 9.473 0 0 1 13 19.273Zm0-6.136Zm0 4.5a7.809 7.809 0 0 0 4.244-1.217 8 8 0 0 0 2.956-3.283 8 8 0 0 0-2.956-3.283A7.808 7.808 0 0 0 13 8.636a7.808 7.808 0 0 0-4.244 1.217A8 8 0 0 0 5.8 13.136a8 8 0 0 0 2.956 3.283A7.809 7.809 0 0 0 13 17.636Z"
                    data-name="Icon 6"
                />
            </g>
        </svg>
    );
}

export default FocusInactive;
