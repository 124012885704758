import { errorLog } from '../helper/logging';

export const forceLogout = () => {
    window.location.href = '/login';
};

export const HTTP_ERRORS = {
    HTTP_406: 'API ERROR POST_DATA NOT_ACCEPTABLE',
}

/**
 * POST request to the given url
 * @param {string} url
 * @param {object} data
 * @param {string} bearerToken
 * @param {booelan} json - is json or text
 * @param {boolean} force - force logout on 401
 * @returns {Promise<any | never>}
 */
export const postData = function postData(url = '', data = {}, bearerToken = null, json = true, force = true) {
    const headers = {
        'Content-Type': 'application/json',
    };
    if (bearerToken) {
        headers['Authorization'] = 'Bearer ' + bearerToken;
    }
    // Default options are marked with *
    return fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers,
        redirect: 'follow',
        referrer: 'no-referrer',
        body: JSON.stringify(data),
    }).then(response => {
        if (!response.ok) {
            let error = new Error('API ERROR POST_DATA');
            if (response.status === 401) {
                errorLog({ message: 'HTTP STATUS 401 - unauthorized', error });
                if (force) forceLogout();
            }
            if (response.status === 404) {
                error = new Error('API ERROR POST_DATA NOT_FOUND');
            }
            if (response.status === 406) {
                error = new Error(HTTP_ERRORS.HTTP_406);
            }
            throw error;
        }
        if (json) return response.json();
        return response;
    });
};

/**
 * GET request to the given url
 * @param {string} url
 * @param {string} bearerToken
 * @param {string} type - json or text
 * @param {boolean} force - force logout on 401
 * @returns {Promise<any | never>}
 */
export const getData = (url = '', bearerToken = null, type = 'json', force = true) => {
    let headers = {};

    if (type === 'json') {
        headers = {
            'Content-Type': 'application/json',
        };
    }

    if (type === 'text') {
        headers = {
            'Content-Type': 'text/plain',
        };
    }

    const params = {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers,
        redirect: 'follow',
        referrer: 'no-referrer',
    };
    if (bearerToken) params.headers.Authorization = `Bearer ${bearerToken}`;
    return fetch(url, params).then(response => {
        if (!response.ok) {
            let error = new Error('API ERROR GET_DATA');
            if (response.status === 401) {
                errorLog({ message: 'HTTP STATUS 401 - unauthorized', error });
                if (force) forceLogout();
            }
            if (response.status === 404) {
                error = new Error('API ERROR GET_DATA NOT_FOUND');
            }
            if (response.status === 406) {
                error = new Error(HTTP_ERRORS.HTTP_406);
            }
            throw error;
        }

        if (type === 'text') {
            return response.text();
        }

        if (type === 'blob') {
            return response.blob();
        }

        return response.json();
    });
};

/**
 * DELETE request to url
 * @param {string} url
 * @param {string} bearerToken
 * @param {boolean} force - force logout on 401
 * @param {object} data
 */

export const deleteData = (url = '', bearerToken = null, force = true, data = {}) => {
    const params = {
        method: 'DELETE',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
        },
        redirect: 'follow',
        referrer: 'no-referrer',
        body: JSON.stringify(data),
    };
    if (bearerToken) params.headers.Authorization = `Bearer ${bearerToken}`;
    return fetch(url, params).then(response => {
        if (!response.ok) {
            let error = new Error('API ERROR DELETE_DATA');
            if (response.status === 401) {
                if (force) forceLogout();
            }
            if (response.status === 404) {
                error = new Error('API ERROR DELETE_DATA NOT_FOUND');
            }
            errorLog({ message: response.StatusText, error });
            throw error;
        }
        return response;
    });
};

/**
 * handles the rest call and returns the response
 * @param {string} url
 * @param {object} params
 * @param {boolean} isJson json request data
 *
 * @returns {object} response data - json or text
 */
export const restCall = async (url, params, isJson = true) => {
    try {
        const response = await fetchErrorHandling(url, params);
        if (isJson) {
            const json = await response.json();
            return json;
        } else {
            return response;
        }
    } catch (e) {
        if (e.message !== '401' && e.message !== '404') {
            console.log(e);
        }
    }
};

/**
 * fetch wrapper for error handling
 * throws errors for HTTP 500 and 403
 * @param {string} url
 * @param {object} params
 * @returns {object} response data
 */
const fetchErrorHandling = async (url, params = {}) => {
    return await fetch(url, params).then(response => {
        if (!response.ok) {
            let error = new Error('API ERROR');

            if (response.status === 401) {
                error = new Error('401');
                console.log(error);
            }

            if (response.status === 404) {
                error = new Error('404');
            }

            if (response.status === 403) {
                error = new Error('403');
            }
        }
        return response;
    });
};
