import React from 'react';

function Microphone() {
    return (
        <svg id="mic" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.5 29">
            <path id="a1" d="m13.25,18.32c2.51,0,4.55-2.04,4.55-4.57,0,0,0,0,0-.01V4.58c0-2.53-2.04-4.58-4.55-4.58s-4.55,2.05-4.55,4.58v9.16c0,2.53,2.03,4.58,4.54,4.58,0,0,0,0,.01,0Zm8.97-4.58c-.74,0-1.38.55-1.49,1.3-.71,4.16-4.63,6.95-8.76,6.24-3.17-.55-5.66-3.05-6.2-6.24-.12-.83-.89-1.41-1.72-1.29-.83.12-1.4.9-1.28,1.73.76,4.59,4.39,8.16,8.97,8.82v3.18c0,.84.68,1.53,1.52,1.53s1.52-.68,1.52-1.53v-3.17c4.58-.66,8.21-4.23,8.97-8.82.12-.83-.46-1.61-1.29-1.72-.07-.01-.15-.02-.23-.02Z"/>
        </svg>
    );
}

export default Microphone;