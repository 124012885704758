import { cssVariables } from '../../themes/default';

export const Bin = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
            <g transform="translate(-282 -3)">
                <circle cx="15" cy="15" r="15" fill={cssVariables.secondary} data-name="Kreis Trash" transform="translate(282 3)" />
                <path
                    fill="#fff"
                    d="M291.889 24.222A1.783 1.783 0 0 0 293.667 26h7.111a1.783 1.783 0 0 0 1.778-1.778V13.556h-10.667Zm2.818-5.7a.886.886 0 0 1 1.253-1.251l1.262 1.253 1.254-1.253a.886.886 0 0 1 1.253 1.253l-1.253 1.254 1.253 1.253a.886.886 0 1 1-1.253 1.253l-1.254-1.253-1.253 1.253a.886.886 0 0 1-1.253-1.253l1.253-1.253Zm7.849-7.633h-2.223l-.633-.631a.9.9 0 0 0-.62-.258h-3.716a.9.9 0 0 0-.622.258l-.631.631h-2.222a.889.889 0 1 0 0 1.778h10.667a.889.889 0 1 0 0-1.778Z"
                    data-name="Path 3168"
                />
            </g>
        </svg>
    );
};
