/**
 * Creates the center button for the open street map
 */
export class CenterButton {
    constructor(onClickHandler) {
        this.onClickHandler = onClickHandler;
    }

    changeTextTo(text) {
        this._btn.innerHTML = text;
        this._btn['aria-label'] = text;
    }

    onAdd(map) {
        this._map = map;

        this._btn = document.createElement('button');
        this._btn.className = 'mapboxgl-ctrl-icon mapboxgl-ctrl-center';
        this._btn.type = 'button';
        this._btn.innerHTML = '';
        this._btn['aria-label'] = '';
        this._btn.onclick = () => {
            this.onClickHandler();
        };

        this._container = document.createElement('div');
        this._container.className = 'mapboxgl-ctrl-group mapboxgl-ctrl';
        this._container.appendChild(this._btn);

        return this._container;
    }

    onRemove() {
        this._container.parentNode.removeChild(this._container);
        this._map = undefined;
    }
}

/**
 * Create a circle arround the current location
 * @param {object} center
 * @param {number} radiusInM
 * @returns {object} osm object
 */
export const createGeoJSONCircle = function(center, radiusInM) {
    let points = 64;

    var coords = {
        latitude: center[1],
        longitude: center[0],
    };

    var km = radiusInM / 1000;

    var ret = [];
    var distanceX = km / (111.32 * Math.cos((coords.latitude * Math.PI) / 180));
    var distanceY = km / 110.574;

    var theta, x, y;
    for (var i = 0; i < points; i++) {
        theta = (i / points) * (2 * Math.PI);
        x = distanceX * Math.cos(theta);
        y = distanceY * Math.sin(theta);

        ret.push([coords.longitude + x, coords.latitude + y]);
    }
    ret.push(ret[0]);

    return {
        type: 'geojson',
        data: {
            type: 'FeatureCollection',
            features: [
                {
                    type: 'Feature',
                    geometry: {
                        type: 'Polygon',
                        coordinates: [ret],
                    },
                },
            ],
        },
    };
};
