import React, { PureComponent } from 'react';
import './Help.scss';

class Help extends PureComponent {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120">
                <path d="M60 20a40 40 0 1 0 40 40 40 40 0 0 0-40-40Zm17 56a8 8 0 0 1-8 7H44a8 8 0 0 1-7-7V51a8 8 0 0 1 7-7h14a3 3 0 0 1 0 6H44a2 2 0 0 0-1 1v25a2 2 0 0 0 1 1h25a2 2 0 0 0 1-1V62a3 3 0 0 1 3-3 3 3 0 0 1 4 3Zm6-36v13a3 3 0 0 1-3 3 3 3 0 0 1-3-3v-6L58 67a3 3 0 0 1-3 1 3 3 0 0 1-2-1 3 3 0 0 1-1-2 3 3 0 0 1 1-3l20-19h-6a3 3 0 0 1 0-6h14a5 5 0 0 1 1 1 3 3 0 0 1 1 2Z" />
            </svg>
        );
    }
}

// PropTypes for this Component
Help.propTypes = {};

export default Help;
