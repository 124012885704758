import store from '../store/index';
import { CONNECTION_ENDED, CONNECTION_ESTABLISHED, CONNECTION_LOST, SET_CONNECTION_STABLE, SET_CONNECTION_UNSTABLE } from './actionTypes';

/**
 * All connection actions
 *
 * Actions always return the type
 * Dispatch always dispatches the action
 */

const connectionEstablished = () => {
    return {
        type: CONNECTION_ESTABLISHED,
    };
};

export const connectionEstablishedDispatch = () => {
    store.dispatch(connectionEstablished());
};

const connectionLost = () => {
    return {
        type: CONNECTION_LOST,
    };
};

export const connectionLostDispatch = () => {
    store.dispatch(connectionLost());
};

const connectionEnded = () => {
    return {
        type: CONNECTION_ENDED,
    };
};

export const connectionEndedDispatch = () => {
    store.dispatch(connectionEnded());
};

export const connectionStable = () => {
    return {
        type: SET_CONNECTION_STABLE,
    };
};

export const connectionStableDispatch = () => {
    store.dispatch(connectionStable());
};

export const connectionUnstable = () => {
    return {
        type: SET_CONNECTION_UNSTABLE,
    };
};

export const connectionUnstableDispatch = () => {
    store.dispatch(connectionUnstable());
};
