import React, { useEffect, useState } from 'react';

import { ConnectedProps, connect } from 'react-redux';
import './SendPasswordResetButton.scss';
import { replaceText } from '../../helper/helper';
import { SEND_PASSWORD_RESET_TIMEOUT } from '../../config';
import { addNotificationAndShowDispatch, hideAndRemoveNotificationsDispatch } from '../../redux/actions/notifications';
import { dispatchPasswordChangeSent } from '../../redux/actions/application';
import { sendChangePasswordRequest } from '../../api/passwordApi';

export const SendPasswordResetButton: React.FC<ConnectedProps<typeof connector> & { isHidden: boolean }> = ({ texts, isHidden }) => {
    const [sendPasswordResetButtonIsDisabled, setSendPasswordResetButtonIsDisabled] = useState(false);
    let classes = `send-password-button ${isHidden && 'send-password-button__isHidden'}`;

    const sendPasswordReset = () => {
        hideAndRemoveNotificationsDispatch('warning');
        setSendPasswordResetButtonIsDisabled(true);
        sendChangePasswordRequest();
        setTimeout(() => {
            dispatchPasswordChangeSent();
            setSendPasswordResetButtonIsDisabled(false);
            addNotificationAndShowDispatch('password.change.sent', 'info');
        }, SEND_PASSWORD_RESET_TIMEOUT);
    };

    useEffect(() => {
        const okButtons = Array.from(document.getElementsByClassName('btn--ok') as HTMLCollectionOf<HTMLElement>)                
        okButtons.forEach(okButton => {
            okButton.style.display = 'none';
        });
    }, [])

    useEffect(() => {
        return () => {
            const okButtons = Array.from(document.getElementsByClassName('btn--ok') as HTMLCollectionOf<HTMLElement>)        
            okButtons.forEach(okButton => {
                okButton.style.display = 'block';
            });
        }
    }, []);

    return (
        <div className={classes}>
            {!sendPasswordResetButtonIsDisabled ? (
                <button className="btn btn--primary" onClick={sendPasswordReset}>
                    <span>{replaceText(texts, 'password.reset.button')}</span>
                </button>
            ) : (
                <div className="send-password-button__text-wrapper"></div>
            )}
        </div>
    );
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        texts: state.texts.texts,
    };
};

const connector = connect(mapStateToProps);
export default connector(SendPasswordResetButton);
