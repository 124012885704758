import store from "../store";

export const SET_IS_IN_PORTRAIT_MODE = 'SET_IS_IN_PORTRAIT_MODE';
export const SET_IS_IN_LANDSCAPE_MODE = 'SET_IS_IN_LANDSCAPE_MODE';
export const SET_MOBILE_BREAKPOINT_REACHED = 'SET_MOBILE_BREAKPOINT_REACHED';
export const UNSET_MOBILE_BREAKPOINT_REACHED = 'UNSET_MOBILE_BREAKPOINT_REACHED';

// SCREEN ORIENTATION

const setIsInPortraitMode = () => {
    return {
        type: SET_IS_IN_PORTRAIT_MODE,
    };
};

export const dispatchSetIsInPortraitMode = () => {
    store.dispatch(setIsInPortraitMode());
};

const setIsInLandscapeMode = () => {
    return {
        type: SET_IS_IN_LANDSCAPE_MODE,
    };
};

export const dispatchSetIsInLandscapeMode = () => {
    store.dispatch(setIsInLandscapeMode());
};

// MOBILE BREAKPOINT

const setMobileBreakpointReached = () => {
    return {
        type: SET_MOBILE_BREAKPOINT_REACHED,
    };
};

export const dispatchSetMobileBreakpointReached = () => {
    store.dispatch(setMobileBreakpointReached());
};

const unsetMobileBreakpointReached = () => {
    return {
        type: UNSET_MOBILE_BREAKPOINT_REACHED,
    };
};

export const dispatchUnsetMobileBreakpointReached = () => {
    store.dispatch(unsetMobileBreakpointReached());
};
