import React from 'react';

export default function SpeakerMuted() {
    return (
        <svg id="ls-off" xmlns="http://www.w3.org/2000/svg" width="30" height="34" viewBox="0 0 25 24">
            <path
                id="b"
                d="m.87.41c-.54.54-.54,1.41,0,1.95,0,0,0,0,0,0l5.08,5.08-.4.42H1.39c-.77,0-1.39.62-1.39,1.39v5.55c0,.77.62,1.39,1.39,1.39h4.17l4.57,4.56c.55.54,1.43.54,1.97,0,.26-.26.4-.61.4-.98v-5.78l5.81,5.8c-.68.52-1.43.95-2.22,1.27-.7.32-1.01,1.14-.69,1.84.31.69,1.12,1,1.81.7,1.11-.46,2.15-1.07,3.08-1.82l1.86,1.86c.55.53,1.43.5,1.96-.05.51-.53.51-1.37,0-1.9L2.85.41C2.3-.14,1.42-.14.87.41Zm21.34,11.61c0,1.11-.19,2.2-.57,3.25l2.12,2.12c2.97-6.21.35-13.65-5.86-16.62-.31-.15-.62-.28-.94-.4-.65-.24-1.38.1-1.62.75-.05.14-.08.3-.08.45v.26c.01.53.35,1,.85,1.18,3.68,1.49,6.09,5.05,6.1,9.02ZM10.12,3.29l-.24.24,2.61,2.61v-1.88c0-.77-.63-1.38-1.4-1.38-.37,0-.72.15-.97.41h0Zm8.62,8.73c0-2.37-1.34-4.54-3.47-5.59v2.48l3.45,3.44c.02-.11.03-.22.03-.33h0Z"
            />
        </svg>
    );
}
