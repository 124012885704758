import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import OutsideClickHandler from 'react-outside-click-handler';

import ArrowDown from './../Icons/ArrowDown';

import './Dropdown.scss';
import { connect } from 'react-redux';
import { replaceText } from '../../helper/helper';
import Loading from './Loading';

/**
 * Dropdown
 * Simple custom dropdown. Calculates the space and position for the dropdown list.
 */
class Dropdown extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            position: 'bottom',
            minHeight: this.props.minHeight,
            maxHeight: this.props.maxHeight,
        };

        this.dropdownRef = React.createRef();
        this.dropdownContentRef = React.createRef();
    }

    toggleDropdown = () => {
        if (!this.props.disabled) {
            this.setState(
                {
                    open: !this.state.open,
                },
                this.handlePositioning
            );
        }
    };

    closeDropdown = () => {
        this.setState({
            open: false,
        });
    };

    clickChild = (value, label) => {
        this.props.clickHandler(value, label);

        this.setState({
            open: false,
        });
    };

    handlePositioning = () => {
        if (!this.state.open) return;
        const contentRect = this.dropdownContentRef.current.getBoundingClientRect();
        const dropdownRect = this.dropdownRef.current.getBoundingClientRect();
        const h = window.innerHeight;

        if (dropdownRect.top + dropdownRect.height + contentRect.height > h) {
            if (dropdownRect.top < contentRect.height) {
                if (dropdownRect.top > h / 2) {
                    this.setState({
                        position: 'top',
                        maxHeight: dropdownRect.top,
                    });
                } else {
                    this.setState({
                        position: 'bottom',
                        maxHeight: h - (dropdownRect.top + dropdownRect.height),
                    });
                }
            } else {
                this.setState({
                    position: 'top',
                    maxHeight: dropdownRect.top,
                });
            }
        } else {
            this.setState({
                position: 'bottom',
                maxHeight: h - (dropdownRect.top + dropdownRect.height),
            });
        }
    };

    render() {
        const classes = clsx('dropdown', `dropdown--${this.state.position}`, {
            'dropdown--open': this.state.open,
            'dropdown--disabled': this.props.disabled,
            'dropdown--flags': this.props.hasFlags,
        });
        const styles = {
            maxHeight: Math.min(this.state.maxHeight, this.props.maxHeight),
            minHeight: this.state.open ? this.state.minHeight : 0,
        };
        return (
            <OutsideClickHandler onOutsideClick={this.closeDropdown}>
                <div className={classes} ref={this.dropdownRef} id={this.props.id}>
                    <div
                        className="dropdown__label"
                        onClick={this.toggleDropdown}>
                        <span>
                            {this.props.label ||
                                replaceText(
                                    this.props.texts,
                                    'dropdown.default.label'
                                )}
                        </span>
                        {this.props.isLoading ? <Loading /> : ''}
                        <ArrowDown addClass="dropdown__toggleIcon" />
                    </div>
                    <div
                        className="dropdown__content"
                        ref={this.dropdownContentRef}
                        style={styles}>
                        {React.cloneElement(this.props.children, {
                            clickElement: this.clickChild,
                        })}
                    </div>
                </div>
            </OutsideClickHandler>
        );
    }
}
// Default Props
Dropdown.defaultProps = {
    disabled: false,
    minHeight: 300,
    maxHeight: 1000,
    hasFlags: false,
};

// PropTypes for this Component
Dropdown.propTypes = {
    children: PropTypes.any,
    clickHandler: PropTypes.func,
    label: PropTypes.any,
    minHeight: PropTypes.number,
    maxHeight: PropTypes.number,
    disabled: PropTypes.bool,
    texts: PropTypes.any,
    hasFlags: PropTypes.bool,
    id: PropTypes.string,
    isLoading: PropTypes.bool
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        texts: state.texts.texts,
    };
};

// Connect Props and Dispatch to Component
export default connect(mapStateToProps)(Dropdown);
