import React, { useCallback, useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import {
    dispatchAddPointsCallerAndSend,
    dispatchAddPointsDispatcherAndSend,
    dispatchAllowPaintingCaller,
    dispatchAllowPaintingDispatcher,
    dispatchDeletePaintCallerAndSend,
    dispatchDisallowPaintingDispatcher,
    dispatchRemoveBackgroundImage,
    dispatchSetBackgroundImage,
    dispatchHideScreenshotDialogue,
    dispatchShowScreenshotDialogue,
    dispatchResetDialogueCallback,
    dispatchChangeShape,
} from '../../redux/actions/paint';
import { usePrevious } from '../../helper/hooks';
import { createKpiLog, getColorFromChar, getShapeFromChar } from '../../helper/helper';
import { PAINT_COLORS, PAINT_SHAPES } from '../../redux/reducers/paint';
import { replaceText } from '../../helper/helper';

import './PaintCanvas.scss';
import { Pencil } from '../Icons/Pencil';
import { Trashcan } from '../Icons/Trashcan';
import { Play } from '../Icons/Play';
import { overlayTwoImages } from '../../api/backendApi';
import { addSessionImageDispatch } from '../../redux/actions/session';
import { DrawArrow } from '../Icons/DrawArrow';
import reduxStore from '../../redux/store';
import { store } from '../../store/DispatcherStore';

export const PaintCanvasComponent = ({
    currentUser,
    paintColor,
    dispatcherPaint,
    paintShape,
    callerPaint,
    width,
    height,
    isPaintingAllowed,
    isConnected,
    backgroundImage,
    texts,
    photoPermission,
    snapshotFeature,
    isScreenshotDialogueShown,
    screenshotDialogueCallback,
    images,
}) => {
    const [canvas, setCanvas] = useState();
    const [context, setContext] = useState();
    const [isDrawing, setIsDrawing] = useState(false);
    const [from, setFrom] = useState();
    const [to, setTo] = useState();
    const [points, setPoints] = useState('');
    const [hasDrawEnded, setHasDrawEnded] = useState(false);

    const canvasRef = useRef();
    const prevDispatcherPaint = usePrevious(dispatcherPaint);
    const prevCallerPaint = usePrevious(callerPaint);
    const prevWidth = usePrevious(width);
    const prevHeight = usePrevious(height);
    const rootElement = document.querySelector('#root');

    const setVideoScreenshotAsBG = useCallback(() => {
        const video = document.getElementById('stream');
        const canvas = document.createElement('canvas');
        if (canvas && video) {
            const videoRect = video.getBoundingClientRect();
            canvas.width = videoRect.width;
            canvas.height = videoRect.height;

            const canvasContext = canvas.getContext('2d');
            canvasContext.drawImage(video, 0, 0, canvas.width, canvas.height);
            dispatchSetBackgroundImage(canvas.toDataURL('image/jpeg'));
            canvas.remove();
        }
    }, []);

    const clearVideoScreenshot = useCallback(() => {
        dispatchRemoveBackgroundImage();
    }, []);

    // save screenshot to redux store
    useEffect(() => {
        if (isPaintingAllowed) {
            setVideoScreenshotAsBG();
        } else {
            clearVideoScreenshot();
        }
    }, [isPaintingAllowed, setVideoScreenshotAsBG, clearVideoScreenshot]);

    // restrict scrolling for caller
    useEffect(() => {
        if (currentUser === 'caller') {
            if (isPaintingAllowed) {
                disableBodyScroll(rootElement);
            } else {
                enableBodyScroll(rootElement);
            }
        }
    }, [isPaintingAllowed, rootElement, currentUser]);

    // runs once
    useEffect(() => {
        const canvas = canvasRef.current;
        const canvasContext = canvas.getContext('2d');

        setCanvas(canvas);
        setContext(canvasContext);
    }, []);

    // cleanup scrolling restriction for caller after component unmounts
    useEffect(() => {
        if (currentUser === 'caller') {
            dispatchChangeShape(PAINT_SHAPES.ARROW);
        }
        return () => {
            if (currentUser === 'caller') {
                clearAllBodyScrollLocks();
            }
        };
    }, [currentUser]);

    /** SCREENSHOT */

    const getCanvasImage = useCallback(() => {
        // canvas image needs to be png to get transparent background;
        const canvasImage = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
        return canvasImage;
    }, [canvas]);

    const takeScreenshot = useCallback(async () => {
        const canvasImage = getCanvasImage();

        const mergedImage = await overlayTwoImages(backgroundImage, canvasImage);
        addSessionImageDispatch({ ...mergedImage, quality: 'standard' });

        const fileName =
            'image' +
            '_' +
            store.callerId +
            '_' +
            store.bystanderToken +
            '_' +
            reduxStore.getState().session.images[reduxStore.getState().session.images.length - 1].time +
            '.png';

        const additionalStates = {
            0: fileName,
        };

        createKpiLog('infoDrawScreenshotTaken', '', additionalStates);
    }, [backgroundImage, getCanvasImage]);

    const acceptScreenshot = () => {
        takeScreenshot();
        dispatchHideScreenshotDialogue();
        dispatchRemoveBackgroundImage();
        dispatchDisallowPaintingDispatcher();
        screenshotDialogueCallback();
        dispatchResetDialogueCallback();
    };

    const declineScreenshot = () => {
        dispatchHideScreenshotDialogue();
        dispatchRemoveBackgroundImage();
        dispatchDisallowPaintingDispatcher();
        screenshotDialogueCallback();
        dispatchResetDialogueCallback();
    };

    /* --- */

    useEffect(() => {
        if (hasDrawEnded && points && points.length > 0) {
            if (currentUser === 'dispatcher') {
                dispatchAddPointsDispatcherAndSend(points);
            } else {
                dispatchAddPointsCallerAndSend(points);
            }

            setPoints('');
            setHasDrawEnded(false);
        }
    }, [hasDrawEnded, currentUser, points]);

    const scalePoints = useCallback(
        ({ point, otherCanvasHeight, otherCanvasWidth }) => {
            const scaleX = point.x / otherCanvasWidth;
            const scaleY = point.y / otherCanvasHeight;

            return {
                x: scaleX * width,
                y: scaleY * height,
            };
        },
        [height, width]
    );

    /** draw the shapes from given data */
    const drawLinesFromData = useCallback(
        data => {
            data.forEach(dataElement => {
                const lines = dataElement.split('|');
                lines.forEach(line => {
                    const point = line.split(',');
                    const color = getColorFromChar(point[0]);
                    const computedColor = getComputedStyle(document.body).getPropertyValue(`--${color}`);
                    const shape = getShapeFromChar(point[1]);
                    const from = { x: point[2], y: point[3] };
                    const to = { x: point[4], y: point[5] };
                    // scale to fit currentCanvas
                    const otherCanvasWidth = Number(point[6]);
                    const otherCanvasHeight = Number(point[7]);

                    const newTo = scalePoints({ point: to, otherCanvasHeight, otherCanvasWidth });
                    const newFrom = scalePoints({ point: from, otherCanvasHeight, otherCanvasWidth });

                    if (newFrom && newTo && color && shape) {
                        switch (shape) {
                            case PAINT_SHAPES.ARROW:
                                arrowShape({ context, to: newTo, color: computedColor });
                                break;
                            case PAINT_SHAPES.FREE:
                            default:
                                lineShape({ context, to: newTo, from: newFrom, color: computedColor });
                                break;
                        }
                    }
                });
            });
        },
        [context, scalePoints]
    );

    /** Redraw canvas from redux store after one draw cycle is done */
    /** This allows for undo & delete actions */
    useEffect(() => {
        if (dispatcherPaint !== prevDispatcherPaint || callerPaint !== prevCallerPaint) {
            if (canvas && context) {
                context.clearRect(0, 0, canvas.width, canvas.height);
                drawLinesFromData(callerPaint);
                drawLinesFromData(dispatcherPaint);
            }
        }
    }, [canvas, context, dispatcherPaint, drawLinesFromData, prevDispatcherPaint, callerPaint, prevCallerPaint]);

    /** Redraw canvas from redux store when canvas size changed */
    useEffect(() => {
        if (width !== prevWidth || height !== prevHeight) {
            if (canvas && context) {
                context.clearRect(0, 0, canvas.width, canvas.height);
                drawLinesFromData(callerPaint);
                drawLinesFromData(dispatcherPaint);
            }
        }
    }, [canvas, context, width, height, drawLinesFromData, callerPaint, dispatcherPaint, prevWidth, prevHeight]);

    /** get the event for mouse or touch position */
    const getEventPosition = event => {
        var rect = canvas.getBoundingClientRect();
        if (event.type === 'touchstart' || event.type === 'touchmove' || event.type === 'touchend' || event.type === 'touchcancel') {
            var evt = typeof event.originalEvent === 'undefined' ? event : event.originalEvent;
            var touch = evt.touches[0] || evt.changedTouches[0];
            return {
                x: touch.pageX - rect.left,
                y: touch.pageY - rect.top,
            };
        } else if (
            event.type === 'mousedown' ||
            event.type === 'mouseup' ||
            event.type === 'mousemove' ||
            event.type === 'mouseover' ||
            event.type === 'mouseout' ||
            event.type === 'mouseenter' ||
            event.type === 'mouseleave'
        ) {
            return {
                x: event.clientX - rect.left,
                y: event.clientY - rect.top,
            };
        }
    };

    /**
     * sets the points from the canvas
     * @param {string} color
     */
    const setNewPoints = color => {
        // Optimized for minimal data |color,shape,from.x,from.y,to.x,to.y,canvas.width,canvas.height|
        setPoints(`${points}|${color.slice(-1)},${paintShape.substring(0, 1)},${from.x},${from.y},${to.x},${to.y},${width},${height}`);
    };

    /** draw a line */
    const drawLine = () => {
        const color = currentUser === 'dispatcher' ? PAINT_COLORS[paintColor] : PAINT_COLORS.COLORC;
        const computedColor = getComputedStyle(document.body).getPropertyValue(`--${color}`);
        if (from && to) {
            lineShape({ context, to, from, color: computedColor });
            setNewPoints(color);
        }
    };

    const lineShape = ({ context, to, from, color }) => {
        context.beginPath();
        context.lineWidth = 5;
        context.strokeStyle = color;
        context.moveTo(from.x, from.y);
        context.lineTo(to.x, to.y);
        context.stroke();
    };

    /* draw an arrow */
    const drawArrow = () => {
        const color = currentUser === 'dispatcher' ? PAINT_COLORS[paintColor] : PAINT_COLORS.COLORC;
        const computedColor = getComputedStyle(document.body).getPropertyValue(`--${color}`);
        if (from && to) {
            arrowShape({ context, to, color: computedColor });
            setNewPoints(color);
        }
    };

    const arrowShape = ({ context, to, color }) => {
        const headLength = 20;
        const arrowLength = 30;
        // needs to be dividable by 2
        const arrowThickness = 4;
        const roundedTo = { x: Math.round(to.x), y: Math.round(to.y) };

        const arrowHead = { x: roundedTo.x, y: roundedTo.y };
        const arrowEnd = { x: roundedTo.x + arrowLength, y: roundedTo.y + arrowLength };
        const arrowLineLeft = { x: roundedTo.x + headLength, y: roundedTo.y - arrowThickness / 2 };
        const arrowLineRight = { x: roundedTo.x - arrowThickness / 2, y: roundedTo.y + headLength };

        context.beginPath();
        context.lineWidth = arrowThickness;
        context.strokeStyle = color;

        context.moveTo(arrowEnd.x, arrowEnd.y);
        context.lineTo(arrowHead.x, arrowHead.y);

        context.moveTo(arrowLineLeft.x, arrowLineLeft.y);
        context.lineTo(arrowHead.x - arrowThickness, arrowHead.y - arrowThickness / 2);

        context.moveTo(arrowLineRight.x, arrowLineRight.y);
        context.lineTo(arrowHead.x - arrowThickness / 2, arrowHead.y - arrowThickness);

        context.stroke();
    };

    /**
     * Drawing Methods - start - draw - end
     * - free hand draws lines from each mouse movement event
     * - arrow is drawn from start to end point
     * - circle is drawn around the start point with a radius to the endpoint
     */
    const startDraw = event => {
        if (!isPaintingAllowed) return;
        setIsDrawing(true);

        if (paintShape !== PAINT_SHAPES.ARROW) {
            setTo(null);
            setFrom(getEventPosition(event));
        }
        if (paintShape === PAINT_SHAPES.ARROW) {
            setFrom(null);
            setTo(null);
            setFrom({ x: 0, y: 0 });
            setTo(getEventPosition(event));
        }
    };

    const draw = event => {
        if (!isDrawing) return;

        if (paintShape === PAINT_SHAPES.FREE) {
            setTo(getEventPosition(event));
            drawLine();
            setFrom(to);
        }
    };

    const stopDraw = () => {
        if (!isDrawing) return;

        if (paintShape === PAINT_SHAPES.ARROW) {
            drawArrow();
        }

        setIsDrawing(false);

        context.save();

        setHasDrawEnded(true);
    };

    const controlPaintingAllowance = () => {
        if (isPaintingAllowed && currentUser === 'dispatcher') {
            if (photoPermission && snapshotFeature) {
                dispatchShowScreenshotDialogue();
            } else {
                dispatchDisallowPaintingDispatcher();
            }
        }
        if (!isPaintingAllowed && currentUser === 'dispatcher') {
            dispatchAllowPaintingDispatcher();
        }
        if (!isPaintingAllowed && currentUser === 'caller') {
            dispatchAllowPaintingCaller();
        }
        if (isPaintingAllowed && currentUser === 'caller') {
            dispatchDeletePaintCallerAndSend();
        }
    };

    let IconComponent = <Pencil />;
    if (!isPaintingAllowed && currentUser === 'caller') {
        IconComponent = <DrawArrow />;
    }

    if (isPaintingAllowed && currentUser === 'dispatcher') {
        IconComponent = <Play />;
    }

    if (isPaintingAllowed && currentUser === 'caller') {
        IconComponent = <Trashcan />;
    }

    return (
        <div
            className={`paintCanvas paintCanvas--is-visible paintCanvas--${currentUser} ${isPaintingAllowed ? 'paintCanvas--isPaintingAllowed' : ''}`}>
            {backgroundImage && <img className="paintCanvas__background" src={backgroundImage} width={width} height={height} alt="background" />}
            <canvas
                className="paintCanvas__canvas"
                ref={canvasRef}
                width={width}
                height={height}
                onMouseDown={startDraw}
                onMouseUp={stopDraw}
                onMouseMove={draw}
                onMouseLeave={stopDraw}
                onTouchStart={startDraw}
                onTouchMove={draw}
                onTouchEnd={stopDraw}></canvas>
            <button id="toggle-painting-button" className="paintCanvas__control btn btn--primary" onClick={controlPaintingAllowance}>
                {IconComponent}
            </button>

            {isScreenshotDialogueShown && (
                <div className="paintCanvas__overlay">
                    <div className="paintCanvas__overlayContent">
                        <p>{replaceText(texts, 'paint.screenshot.dialogue')}</p>
                        <div className="paintCanvas__overlayButtons">
                            <button className="btn btn--decline" onClick={declineScreenshot}>
                                {replaceText(texts, 'button.decline')}
                            </button>
                            <button className="btn btn--accept" onClick={acceptScreenshot}>
                                {replaceText(texts, 'button.accept')}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

// PropTypes for this Component
PaintCanvasComponent.propTypes = {
    currentUser: PropTypes.string,
    paintColor: PropTypes.string,
    paintShape: PropTypes.string,
    dispatcherPaint: PropTypes.array,
    callerPaint: PropTypes.array,
    width: PropTypes.number,
    height: PropTypes.number,
    isPaintingAllowed: PropTypes.bool,
    isConnected: PropTypes.bool,
    backgroundImage: PropTypes.any,
    photoPermission: PropTypes.bool,
    snapshotFeature: PropTypes.bool,
    isScreenshotDialogueShown: PropTypes.bool,
    screenshotDialogueCallback: PropTypes.func,
    texts: PropTypes.object,
    images: PropTypes.array,
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        paintColor: state.paint.color,
        paintShape: state.paint.shape,
        dispatcherPaint: state.paint.dispatcherPaint,
        callerPaint: state.paint.callerPaint,
        isPaintingAllowed: state.paint.isPaintingAllowed,
        isConnected: state.connection.isConnected,
        backgroundImage: state.paint.backgroundImage,
        photoPermission: state.session.photoPermission,
        snapshotFeature: state.features.snapshotFeature,
        isScreenshotDialogueShown: state.paint.isScreenshotDialogueShown,
        screenshotDialogueCallback: state.paint.screenshotDialogueCallback,
        texts: state.texts.texts,
        images: state.session.images,
    };
};

// Connect Props and Dispatch to Component
export const PaintCanvas = connect(mapStateToProps)(PaintCanvasComponent);
